import { Card, CardContent, IconButton, Typography } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { EventFilterRow } from '../types/types';

interface CardStatsProps {
  label: string;
  value: React.ReactNode;
}

const CardStats: React.FC<CardStatsProps> = ({ label, value }) => {
  return (
    <div>
      <Typography variant="caption" color="textPrimary">
        {label}:{' '}
      </Typography>
      <Typography variant="caption" color="textSecondary" aria-label="number of events this group applies to">
        {value}
      </Typography>
    </div>
  );
};

const EventFiltersCard: React.FC<EventFilterRow> = ({ eventFilter, onEdit }) => {
  return (
    <Card>
      <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            color="textPrimary"
            variant="h6"
            noWrap
            aria-label="Event group filter"
            style={{
              flex: 1,
              minWidth: '0px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {eventFilter.name}
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <IconButton onClick={onEdit} aria-label="edit event filter" size="small">
              <EditIcon color="secondary" fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardStats label="Events" value={eventFilter.eventIds.length} />
          <CardStats label="Programs" value={eventFilter.studyProgramIds.length} />
          <CardStats label="" value="" />
        </div>
      </CardContent>
    </Card>
  );
};

export default EventFiltersCard;
