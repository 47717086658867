import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import { getAdminUserTicketsQuery } from 'queries/admin-users/admin-user-tickets';
import useBookingActions from '../../hooks/useBookingActions';
import useEventSelect from '../../hooks/useEventSelect';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import BookingsHeaderContainer from './BookingsHeaderContainer';
import BookingsList from './BookingsListContainer';
import TicketTransferContainer from './TicketTransfer/TicketTransferContainer';
import AddBookingContainer from './addBooking';

interface Props {
  userId: number;
}

const BookingsContainer: React.FC<Props> = ({ userId }) => {
  const projectId = useProjectId();
  const [transferOpen, setTransferOpen] = useState(false);
  const { data: events } = useQuery(getAdminUserTicketsQuery({ projectId, userId }));

  const searchFilter = useSearchFilter();
  const selectedEvents = useEventSelect(events, [userId]);
  const bookingActions = useBookingActions(userId, selectedEvents.handleClearSelect);

  if (!userId) {
    return <></>;
  }

  if (events === undefined) return <CircularProgress color="secondary" />;

  return (
    <>
      <BookingsHeaderContainer
        searchValue={searchFilter.eventNameSearch}
        setSearchValue={searchFilter.setEventNameSearch}
        selectedEvents={selectedEvents.selectedEvents}
        handleAllSelect={selectedEvents.handleAllSelect}
        deleteBookings={bookingActions.deleteBookings}
        payBookings={bookingActions.payBookings}
        control={searchFilter.control}
        handleReset={searchFilter.handleReset}
        openTransfer={() => setTransferOpen(true)}
        userId={userId}
      />
      <BookingsList
        searchValue={searchFilter.eventNameSearch}
        control={searchFilter.control}
        filter={searchFilter.searchFilter}
        selectedEvents={selectedEvents.selectedEvents}
        handleAllSelect={selectedEvents.handleAllSelect}
        handleSelectEvent={selectedEvents.handleSelectEvent}
        userId={userId}
        events={events}
      />
      <AddBookingContainer userId={userId} bookedEvents={events} />
      <TicketTransferContainer
        userId={userId}
        selectedEvents={selectedEvents.selectedEvents}
        isOpen={transferOpen}
        closeModal={() => setTransferOpen(false)}
      />
    </>
  );
};

export default BookingsContainer;
