import { useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useDeleteEventGroup from 'queries/admin-events/useDeleteEventGroup';
import { useState } from 'react';

interface Props {
  afterSuccess?: () => void;
}

const useDeleteGroup = ({ afterSuccess }: Props) => {
  const queryClient = useQueryClient();
  const feedback = useOpenFeedback();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteGroupId, setDeleteGroupId] = useState<number | null>(null);
  const [deleteGroupName, setDeleteGroupName] = useState<string | null>(null);
  const deleteGroupMutation = useDeleteEventGroup(async () => {
    await queryClient.invalidateQueries({ queryKey: ['getAdminEventGroup'] });
    feedback.openSuccess('Event group deleted successfully');
    setDeleteDialogOpen(false);
    if (afterSuccess) afterSuccess();
  });

  const handleDeleteGroup = (groupId: number, name: string) => {
    setDeleteGroupId(groupId);
    setDeleteGroupName(name);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDeleteGroup = () => {
    deleteGroupMutation.mutate(deleteGroupId);
  };

  const handleCancelDeleteGroup = () => {
    setDeleteDialogOpen(false);
  };

  return {
    deleteDialogOpen,
    deleteGroupName,
    handleDeleteGroup,
    handleConfirmDeleteGroup,
    handleCancelDeleteGroup,
    isLoading: deleteGroupMutation.isPending,
  };
};

export default useDeleteGroup;
