import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import useDynamicTheme from 'hooks/useDynamicTheme';
import React from 'react';

export interface CustomizationCardProps {
  title: string;
  description: string;
  isMobile: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const CustomizationCard: React.FC<CustomizationCardProps> = ({
  title,
  description,
  isMobile,
  onClick,
  disabled = true,
}) => {
  const t = useDynamicTheme();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
      <div style={{ flexBasis: '100%' }}>
        <Typography style={{ fontWeight: 400 }} variant="h6" noWrap>
          {title}
        </Typography>
        <Typography style={{ color: `${t.palette.surface.text}${t.palette.other.opacityMedium}` }} variant="body2">
          {description}
        </Typography>
      </div>
      <div style={{ marginTop: '8px' }}>
        <Button disabled={disabled} color="secondary" onClick={onClick} variant="contained">
          Manage
        </Button>
      </div>
    </div>
  );
};

export default CustomizationCard;
