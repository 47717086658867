import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  handleImport: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}

const ImportEvents: React.FC<Props> = ({ handleImport, loading }) => {
  const t = useDynamicTheme();
  const isMobile = useIsMobile();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
        <div style={{ flexBasis: '100%' }}>
          <Typography style={{ fontWeight: 400 }} variant="h6" noWrap>
            Events
          </Typography>
          <Typography style={{ color: `${t.palette.surface.text}${t.palette.other.opacityMedium}` }} variant="body2">
            Upload and create new events for your project.
          </Typography>
        </div>
        <div style={{ marginTop: '8px' }}>
          <LoadingButton component="label" variant="contained" color="secondary" loading={loading}>
            Import
            <VisuallyHiddenInput type="file" accept=".json" onChange={handleImport} />
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default ImportEvents;
