import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Fab, Theme, styled } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

const backgroundColorForEditing = (isEditing: boolean, theme: Theme): string => {
  if (isEditing) return theme.palette.success.main;
  return theme.palette.secondary.main;
};

const EditingFab = styled(Fab, { shouldForwardProp: (prop) => prop !== 'isEditing' })<{ isEditing: boolean }>(
  ({ theme, isEditing }) => ({
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    backgroundColor: backgroundColorForEditing(isEditing, theme),
    color: theme.palette.secondary.contrastText,
    zIndex: '1200',
  })
);

interface Props {
  isEditing: boolean; // means the user is editing the event group
  onClick: () => void;
  disabled?: boolean;
}

const MobileEditFab: React.FC<Props> = ({ isEditing, onClick, disabled }) => {
  const isMobile = useIsMobile();

  if (!isMobile) return <></>;

  return (
    <EditingFab isEditing={isEditing} onClick={onClick} disabled={disabled}>
      {isEditing ? <CheckIcon /> : <EditOutlinedIcon />}
    </EditingFab>
  );
};

export default MobileEditFab;
