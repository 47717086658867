import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import { getAdminEventQuery } from 'queries/admin-events/admin-event';

const useGetEvent = (eventId: string) => {
  const projectId = useProjectId();
  const eventData = useQuery(getAdminEventQuery({ projectId, eventId }));

  return {
    data: eventData.data,
    isLoading: eventData.isLoading,
    isError: eventData.isError,
  };
};

export default useGetEvent;
