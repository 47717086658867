import * as Backend from 'queries/BackendRequest';
import { getOptions } from 'queries/user/user-options';
import { StudyOption } from 'types/types';

interface GetUserStudyParams {
  projectId: string | number;
  userId: string | number;
}

interface UserStudyInfo {
  accountId: number;
  creationAccountId: number;
  creationDatetime: string;
  firstSemester: string;
  id: number;
  lastSemester: string;
  studyProgramId: number;
}

const fetchUserStudyInfo = async ({ projectId, userId }: GetUserStudyParams): Promise<UserStudyInfo> => {
  const result = await Backend.request<UserStudyInfo>('get', `/admin/${projectId}/study-info/${userId}`);
  return result.data.data;
};

const formatStudyProgram = (studyProgramId: number, studyInfos: StudyOption[]) => {
  return studyInfos.find((studyProgram) => studyProgram.studyProgramId === studyProgramId);
};

const getUserStudy = async ({ projectId, userId }: GetUserStudyParams) => {
  const studyInfo = await fetchUserStudyInfo({ projectId, userId });

  const options = await getOptions();

  const result = formatStudyProgram(studyInfo.studyProgramId, options.study);
  return result;
};

export const getUserStudyQuery = ({ projectId, userId }: GetUserStudyParams) => ({
  queryKey: ['getUserStudyQuery', projectId, userId],
  queryFn: () => getUserStudy({ projectId, userId }),
  enabled: !!projectId && !!userId,
});

export default getUserStudyQuery;
