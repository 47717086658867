import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useProjectId from 'hooks/useProjectId';
import { getBookingStatus, getTimeDuration, getTransferStatus } from 'pages/admin/events/utils/EventCardMapper';
import { BookingStats, EventBookingStats, EventFilter, EventGroup } from 'queries/admin-events/types';
import { UserEvent } from 'types/types';
import { AdminEventCard } from '/components/eventCard/AdminEventCard';
import AddUserModalContainer from 'components/eventAddUser/AddUserModalContainer';

interface Props {
  event: UserEvent;
  statsList: EventBookingStats[];
  eventFilters: EventFilter[];
  eventGroups: EventGroup[];
  isMobile: boolean;
}

const EventListItem: React.FC<Props> = ({ event, statsList, eventFilters, eventGroups, isMobile }) => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const isCancelled = React.useRef(false);
  const [stats, setStats] = React.useState<BookingStats>({
    countPaid: 0,
    countBooked: 0,
    countAttending: 0,
  });
  const [filters, setFilters] = React.useState<string[]>([]);
  const [groups, setGroups] = React.useState<string[]>([]);

  const handleEditClick = () => {
    navigate(`/${projectId}/admin/events/edit/${event.eventId}`);
  };

  const handleManageClick = () => {
    navigate(`/${projectId}/admin/events/manage/${event.eventId}`);
  };

  /**
   * To make it more efficient when mapping the stats by making it async.
   */
  useEffect(() => {
    if (!event || !eventFilters || !eventGroups || !statsList) return undefined;

    const filtered = eventFilters?.filter((filter) => filter?.eventIds?.includes(event.eventId)).map((f) => f?.name);
    const grouped = eventGroups?.filter((group) => group?.eventIDs?.includes(event.eventId)).map((g) => g?.name);
    const eventStats = statsList?.find((s) => s?.eventId === event.eventId);
    if (!isCancelled.current && eventStats) {
      setFilters(filtered);
      setGroups(grouped);
      setStats(eventStats?.stats);
    }

    return () => {
      isCancelled.current = true;
    };
  }, [event, eventFilters, eventGroups, statsList]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <AdminEventCard
        title={event.displayTitle}
        subtitle={getTimeDuration()}
        isMobile={isMobile}
        price={event.basePriceSek}
        currentEventBooked={stats?.countBooked || 0}
        currentEventAttended={stats?.countAttending || 0}
        maxEventAttended={event.attendanceLimit}
        paidPeople={stats?.countPaid || 0}
        description={event.description}
        filters={filters}
        categories={groups}
        checks={[
          {
            label: 'Location',
            value: event.location,
            showStatus: false,
            isOk: false,
          },
          {
            label: 'Price',
            value: `${event.basePriceSek} SEK`,
            showStatus: false,
            isOk: false,
          },
          {
            ...getBookingStatus(event),
          },
          {
            ...getTransferStatus(event),
          },
        ]}
        onEditClick={handleEditClick}
        onManageClick={handleManageClick}
        onTransferClick={() => null}
        onAddUserClick={() => setOpen(true)}
      />
      <AddUserModalContainer eventId={event.eventId} isOpen={open} closeModal={() => setOpen(false)} />
    </>
  );
};

export default EventListItem;
