import React from 'react';

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PaymentIcon from '@mui/icons-material/Payment';
import { Theme } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import IconText from '/components/iconText/IconText';

type AvailableStates = 'default' | 'warning' | 'error' | 'success';

export type EventContentData = {
  label: React.ReactNode | string;
  state: AvailableStates;
};

export interface EventContentPropsData {
  booked: EventContentData;
  attended: EventContentData;
  payment: EventContentData;
}

export interface EventContentProps {
  data: EventContentPropsData;
  isMobile?: boolean;
}

/**
 * transforms string state to a color hex
 * @param string <default, warning, error, success>
 * @returns color in hex or default colors
 */
const stringToColor = (string: AvailableStates, theme: Theme) => {
  switch (string) {
    case 'warning':
      return theme.palette.warning.main;
    case 'error':
      return theme.palette.error.main;
    case 'success':
      return theme.palette.success.main;
    default:
      return `${theme.palette.info.main}`;
  }
};

const EventAdminContent: React.FC<EventContentProps> = ({ data, isMobile }) => {
  const theme = useDynamicTheme();

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <tbody>
        <tr>
          <td>
            <IconText
              icon={<ConfirmationNumberIcon />}
              label={data.booked.label}
              spacing={8}
              style={{ color: stringToColor(data.booked.state, theme) }}
              isMobile={!!isMobile}
            />
          </td>
          <td>
            <IconText
              icon={<EmojiPeopleIcon />}
              label={data.attended.label}
              spacing={8}
              style={{ color: stringToColor(data.attended.state, theme) }}
              isMobile={!!isMobile}
            />
          </td>
          <td>
            <IconText
              icon={<PaymentIcon />}
              label={data.payment.label}
              spacing={8}
              style={{ color: stringToColor(data.payment.state, theme) }}
              isMobile={!!isMobile}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EventAdminContent;
