import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Typography } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useCheckedInStyles from './CheckedIn.styles';

export interface CheckedInProps {
  displayText: boolean;
  checkedIn: boolean;
}

const CheckedIn: React.FC<CheckedInProps> = ({ displayText, checkedIn }) => {
  const classes = useCheckedInStyles();
  const theme = useDynamicTheme();

  const iconStyle: React.CSSProperties = {
    width: '16px',
    height: '16px',
  };

  const color: React.CSSProperties = {
    color: checkedIn ? theme.palette.success.main : theme.palette.warning.main,
  };

  return (
    <div className={classes.root} style={color}>
      {checkedIn ? <VerifiedUserIcon style={iconStyle} /> : <WarningIcon style={iconStyle} />}
      {displayText && <Typography variant="caption">{checkedIn ? 'Checked-In' : 'Not Checked-In'}</Typography>}
    </div>
  );
};

export default CheckedIn;
