import React from 'react';

import { TableHead, TableRow, Typography } from '@mui/material';

import TableSortLabel from '@mui/material/TableSortLabel';
import CustomTableCell from 'components/customTableCell/CustomTableCell';

const UsersTableHeader = () => (
  <TableHead>
    <TableRow>
      <CustomTableCell>
        <TableSortLabel hideSortIcon>
          <Typography variant="body2">Name</Typography>
        </TableSortLabel>
      </CustomTableCell>
      <CustomTableCell style={{ width: '180px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body2">Status</Typography>
          </TableSortLabel>
        </div>
      </CustomTableCell>
      <CustomTableCell style={{ width: '180px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body2">Attendance</Typography>
          </TableSortLabel>
        </div>
      </CustomTableCell>
      <CustomTableCell style={{ width: '150px' }}>
        <TableSortLabel hideSortIcon>
          <Typography variant="body2">Payment</Typography>
        </TableSortLabel>
      </CustomTableCell>
    </TableRow>
  </TableHead>
);

export default UsersTableHeader;
