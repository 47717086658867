import { CircularProgress, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import { Control } from 'react-hook-form';

import useIsMobile from 'hooks/useIsMobile';
import { UserTicket } from 'queries/admin-users/admin-user-tickets';
import Attendance from '../../components/Attendance';
import BookingBodyDesktop from '../../components/BookingsBodyDesktop';
import BookingBodyMobile from '../../components/BookingsBodyMobile';
import BookingsHeader from '../../components/BookingsHeader';
import DateInterval from '../../components/DateInterval';
import PriceInterval from '../../components/PriceInterval';
import { BookingSearchFilter } from '../../hooks/useSearchFilter';
import { filterEventListAdmin } from '/utils/eventFilter';

interface BookingsListProps {
  userId: number;
  searchValue: string;
  control: Control<any, any>;
  filter: BookingSearchFilter;
  selectedEvents: number[];
  handleAllSelect: () => void;
  handleSelectEvent: (id: number) => void;
  events: UserTicket[];
}

const BookingsList: React.FC<BookingsListProps> = ({
  userId,
  searchValue,
  control,
  filter,
  selectedEvents,
  handleAllSelect,
  handleSelectEvent,
  events,
}) => {
  const isMobile = useIsMobile('sm');

  const [sortBy, setSortBy] = useState('startDatetime');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [timeAnchorEl, setTimeAnchorEl] = useState(null);
  const [priceAnchorEl, setPriceAnchorEl] = useState(null);
  const [attendedAnchorEl, setAttendedAnchorEl] = useState(null);

  // Create a general comparacter between two objects by key as parameter
  const comparator = (a: any, b: any, key: string) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };

  // Comparator for sorting things by date
  const orderComparator = (order: 'asc' | 'desc', key: string) => {
    if (order === 'asc') return (a: any, b: any) => comparator(a, b, key);
    return (a: any, b: any) => comparator(b, a, key);
  };

  const handleSort = (key: string) => {
    if (sortBy === key) {
      if (sortDirection === 'asc') setSortDirection('desc');
      else setSortDirection('asc');
    } else {
      setSortBy(key);
      setSortDirection('asc');
    }
  };

  // time anchor click
  const timeHandleClick = (event: any) => {
    setTimeAnchorEl(event.currentTarget);
  };

  // price anchor click
  const priceHandleClick = (event: any) => {
    setPriceAnchorEl(event.currentTarget);
  };

  // attended anchor click
  const attendedHandleClick = (event: any) => {
    setAttendedAnchorEl(event.currentTarget);
  };

  if (!userId) {
    return <></>;
  }

  if (events === undefined) return <CircularProgress color="secondary" />;

  return (
    <>
      <Menu anchorEl={timeAnchorEl} keepMounted open={Boolean(timeAnchorEl)} onClose={() => setTimeAnchorEl(null)}>
        <div style={{ padding: '8px', width: '300px' }}>
          <DateInterval control={control} />
        </div>
      </Menu>

      <Menu anchorEl={priceAnchorEl} keepMounted open={Boolean(priceAnchorEl)} onClose={() => setPriceAnchorEl(null)}>
        <div style={{ padding: '8px', width: '300px' }}>
          <PriceInterval control={control} />
        </div>
      </Menu>

      <Menu
        anchorEl={attendedAnchorEl}
        keepMounted
        open={Boolean(attendedAnchorEl)}
        onClose={() => setAttendedAnchorEl(null)}
      >
        <div style={{ padding: '8px' }}>
          <Attendance control={control} />
        </div>
      </Menu>

      {!isMobile ? (
        <TableContainer style={{ marginTop: '20px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <BookingsHeader
                  allChecked={events ? selectedEvents.length === events.length : false}
                  onClick={() => handleAllSelect()}
                  timeFilterClick={timeHandleClick}
                  priceFilterClick={priceHandleClick}
                  attendedFilterClick={attendedHandleClick}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSortBy={handleSort}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {filterEventListAdmin(events || [], filter, searchValue)
                .sort(orderComparator(sortDirection, sortBy))
                .map((event: any) => (
                  <BookingBodyDesktop
                    key={`booking-body-desktop-${event.bookingId}`}
                    onClick={() => handleSelectEvent(event.bookingId)}
                    isActivated={selectedEvents.includes(event.bookingId) || false}
                    isPaid={event.basePriceSek ? event.isPaid : true}
                    isAttended={event.isAttending}
                    date={new Date(event.startDatetime).toISOString().substring(0, 10)}
                    title={event.displayTitle}
                    price={event.basePriceSek || 0}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {filterEventListAdmin(events || [], filter, searchValue).map((event: any) => (
            <BookingBodyMobile
              key={`booking-body-mobile-${event.bookingId}`}
              onClick={() => handleSelectEvent(event.bookingId)}
              isActivated={selectedEvents.includes(event.bookingId) || false}
              isPaid={event.basePriceSek ? event.isPaid : true}
              isAttended={event.isAttending}
              date={new Date(event.startDatetime).toISOString().substring(0, 10)}
              title={event.displayTitle}
              price={event.basePriceSek || 0}
            />
          ))}
        </>
      )}
    </>
  );
};

export default BookingsList;
