import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import { useNavigate } from 'react-router-dom';

const adminDeleteEvent = async (pid: string | number, eventId: number) => {
  await Backend.request('DELETE', `/admin/${pid}/event/${eventId}`, {});
};

const useEventDelete = (id: string) => {
  const projectId = useProjectId();
  const navigate = useNavigate();
  const feedback = useOpenFeedback();

  return useMutation({
    mutationFn: async () => {
      await adminDeleteEvent(projectId, parseInt(id, 10));
    },
    onSuccess: async () => {
      navigate(-1); // Go back to previous page
      feedback.openSuccess('Event deleted successfully');
    },
  });
};

export default useEventDelete;
