import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableRow, Typography } from '@mui/material';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import React from 'react';
import { EventFilterRow } from '../types/types';

const EventFiltersRow: React.FC<EventFilterRow> = ({ eventFilter, onEdit, onDelete }) => {
  return (
    <TableRow>
      <CustomTableCell>
        <Typography
          variant="body2"
          style={{
            textOverflow: 'ellipsis',
            minWidth: '0px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {eventFilter.name}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          {eventFilter.eventIds.length}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          {eventFilter.studyProgramIds.length}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '4px' }}>
          <IconButton onClick={onEdit} aria-label="edit event group" size="small">
            <EditIcon color="secondary" fontSize="inherit" />
          </IconButton>
          <IconButton onClick={onDelete} aria-label="delete event group" size="small">
            <DeleteIcon color="secondary" fontSize="inherit" />
          </IconButton>
        </div>
      </CustomTableCell>
    </TableRow>
  );
};

export default EventFiltersRow;
