import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';

interface AddUserMutationProps {
  projectId: string;
  userId: number;
  eventId: number;
  isPaid: boolean;
  isAttending: boolean;
}

const useAddUserBooking = (onDone?: () => void) => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ projectId, userId, eventId, isPaid, isAttending }: AddUserMutationProps) => {
      await Backend.request('POST', `admin/${projectId}/booking/${eventId}/${userId}`, {}, { isPaid, isAttending });
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: ['getAdminEventDetails', variables.eventId.toString()] });
      await queryClient.invalidateQueries({
        queryKey: ['getAdminUserTicketsQuery', variables.projectId, variables.userId],
      });
      feedback.openSuccess('User added to event');
      if (onDone) onDone();
    },
  });
};

export default useAddUserBooking;
