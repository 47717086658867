import React from 'react';
import { Typography } from '@mui/material';

interface Entry {
  label: React.ReactNode;
  size: number;
}

interface Props {
  entries: Entry[];
  rowSize: number;
}

/**
 * Displays the general settings in the users overview page
 *
 * @param entries entries from props
 * @param rowSize how many columns before new row
 */
const InfoGeneral: React.FC<Props> = ({ entries, rowSize }) => {
  /**
   * Formats the data that is put in and returns an array of JSX elements to be displayed
   */
  const renderRows = () => {
    const returnArray: any = [];
    let row: any = [];
    let currentIndex = 0;

    entries.forEach((entry) => {
      row.push(
        <td key={`entry-${entry.label?.toString()}`} colSpan={entry.size}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            style={{ marginBottom: '9px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {entry.label}
          </Typography>
        </td>
      );

      currentIndex += entry.size;
      if (currentIndex >= rowSize) {
        returnArray.push(<tr key={`row-${returnArray.length}`}>{row}</tr>);

        row = [];
        currentIndex = 0;
      }
    });

    return returnArray;
  };

  return (
    <>
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tbody>{renderRows()}</tbody>
      </table>
    </>
  );
};

export default InfoGeneral;
