import React, { useState } from 'react';

import { TextField } from '@mui/material/';
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { useQuery } from '@tanstack/react-query';

import { useDebounce } from 'use-debounce';
import { getSearchUserQuery, SearchUserResponse } from 'queries/admin-users/admin-search';

interface SearchBarProps {
  setSelected: (id: number) => void;
  projectId: string;
  placeholder?: string;
  filter?: number[];
  clearOnReset?: boolean; // Clear the input when the selected value is reset
  clearOnEmpty?: boolean; // Clear the selected user when the input is empty
}

const SearchBar: React.FC<SearchBarProps> = ({
  setSelected,
  projectId,
  placeholder = 'Search',
  filter = [],
  clearOnReset = false,
  clearOnEmpty = true,
}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 350);
  const searchUserQuery = useQuery(getSearchUserQuery({ projectId: projectId.toString(), search: debouncedQuery }));
  const filteredOptions =
    filter.length > 0 ? searchUserQuery.data?.filter((x) => filter.includes(x.id)) : searchUserQuery.data;

  const handleInputChange = (_: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setQuery(value);
      if (value === '' && clearOnEmpty) setSelected(0);
    } else if (reason === 'clear') {
      setSelected(0);
      setQuery('');
    } else if (reason === 'reset' && clearOnReset) {
      setQuery('');
    }
  };

  const handleOnChange = (
    _: React.ChangeEvent<{}>,
    value: SearchUserResponse | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption') {
      if (!value) return;
      setSelected(value?.id);
      setQuery(`${value.name} ${value.email}`);
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(x) => {
        return `${x.name} ${x.email}`;
      }}
      options={filteredOptions || []}
      inputValue={query}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      blurOnSelect
      noOptionsText={query.length > 1 ? 'Could not find any user' : 'Search for a name or a user id'}
      renderInput={(params) => <TextField label={placeholder} {...params} variant="outlined" color="secondary" />}
    />
  );
};

export default SearchBar;
