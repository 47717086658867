import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Theme } from '@mui/material';
import { UserPreferences } from 'queries/admin-users/admin-user-food-prefs';
import { UserGeneralInfo } from 'queries/admin-users/admin-user-general';
import { StudyOption } from 'types/types';

const formatGeneralInfo = (
  category: number,
  copyToClipboard: (text: string) => void,
  theme: Theme,
  generalInfo?: UserGeneralInfo,
  study?: StudyOption,
  preferences?: UserPreferences,
  isMobile?: boolean
): { label: React.ReactNode; size: number }[] => {
  if (!generalInfo || !study || !preferences)
    // make sure there is data to display
    return [];

  const formattedGeneralInfo = {
    email: generalInfo.email || '',
    birthdayDate: generalInfo.birthdayDate.slice(0, 10) || '',
    age: Math.floor(((new Date() as any) - new Date(generalInfo.birthdayDate).getTime()) / 3.15576e10),
    nationality: generalInfo.nationality || '',
    phoneNumber: generalInfo.phoneNumber ? `+${generalInfo.phoneNumber}` : '',
    personNumber: generalInfo.personNumber || '',
    sex: generalInfo.sex || '',
  };

  const small = isMobile ? 2 : 1;
  const big = isMobile ? 2 : 3;

  switch (category) {
    case 0: // general
      if (isMobile)
        return [
          {
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{formattedGeneralInfo.email}&nbsp;</div>
                <FileCopyIcon
                  style={{ width: '16px', color: theme.palette.secondary.main, cursor: 'pointer' }}
                  onClick={() => copyToClipboard(formattedGeneralInfo.email)}
                />
              </div>
            ),
            size: 2,
          },
          {
            label: formattedGeneralInfo.birthdayDate,
            size: 1,
          },
          {
            label: `Age: ${formattedGeneralInfo.age}`,
            size: 1,
          },
          {
            label: formattedGeneralInfo.nationality,
            size: 1,
          },
          {
            label: formattedGeneralInfo.phoneNumber !== '' ? formattedGeneralInfo.phoneNumber : 'No phone number',
            size: 1,
          },
          {
            label: formattedGeneralInfo.personNumber !== '' ? formattedGeneralInfo.personNumber : 'No personal number',
            size: 1,
          },
          {
            label: formattedGeneralInfo.sex,
            size: 1,
          },
        ];
      return [
        {
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{formattedGeneralInfo.email}&nbsp;</div>
              <FileCopyIcon
                style={{ width: '16px', color: theme.palette.secondary.main, cursor: 'pointer' }}
                onClick={() => copyToClipboard(formattedGeneralInfo.email)}
              />
            </div>
          ),
          size: isMobile ? 2 : 1,
        },
        {
          label: `${formattedGeneralInfo.birthdayDate} (Age: ${formattedGeneralInfo.age})`,
          size: 1,
        },
        {
          label: formattedGeneralInfo.nationality,
          size: 1,
        },
        {
          label: formattedGeneralInfo.phoneNumber !== '' ? formattedGeneralInfo.phoneNumber : 'No phone number',
          size: 1,
        },
        {
          label: formattedGeneralInfo.personNumber !== '' ? formattedGeneralInfo.personNumber : 'No personal number',
          size: 1,
        },
        {
          label: formattedGeneralInfo.sex,
          size: 1,
        },
      ];
    case 1: // studies
      return [
        { label: study.nameEn, size: 2 },
        { label: study.abbreviation, size: 1 },
        { label: study.typeEn, size: 1 },
        { label: study.school, size: 1 },
        { label: study.chapter, size: 1 },
      ];
    case 2: // preferences
      return [
        { label: `Arrival Date: ${generalInfo.arrivalDate.split('T')[0]}`, size: small },
        { label: `Alcohol: ${preferences.drinking ? 'Yes' : 'No'}`, size: small },
        { label: `Food: ${preferences.foodPrefs.join(', ')}`, size: small },
        { label: `Allergies: ${preferences.allergies.concat(preferences.other).join(', ')}`, size: big },
      ];
    default:
      return [];
  }
};

export default formatGeneralInfo;
