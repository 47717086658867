import useDeleteBookings from 'queries/admin-events/useDeleteBookings';
import usePayBookings from 'queries/admin-events/usePayBookings';
import { UserTicket } from 'queries/admin-users/admin-user-tickets';

export interface PayBookingProps {
  events: UserTicket[];
  bookingIds: number[];
}

const useBookingActions = (userId: number, handleClearSelect: () => void) => {
  const deleteBookings = useDeleteBookings(userId, handleClearSelect);

  const payBookings = usePayBookings(userId);

  return { deleteBookings, payBookings };
};

export default useBookingActions;
