import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Collapse, Divider, FormControlLabel, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getUserTransferCheckQuery } from 'queries/admin-users/admin-ticket-transfer-check';
import { getAdminUserTicketsQuery } from 'queries/admin-users/admin-user-tickets';
import CheckOverview from '../../../components/CheckOverview';
import useTransferTicket from '../../../../../../queries/admin-events/useTransferTicket';
import GeneralSettings from '../../GeneralSettingsContainer';
import TicketChecklistContainer from './TicketChecklistContainer';
import SearchBar from '/components/searchBar/SearchBar';
import SubpageModal from '/components/subpageModal/SubpageModal';

interface Props {
  userId: number;
  isOpen: boolean;
  closeModal: () => void;
  selectedEvents?: number[];
}

const TicketTransferContainer: React.FC<Props> = ({ userId, isOpen, closeModal, selectedEvents }) => {
  const isMobile = useIsMobile();

  const theme = useDynamicTheme();

  const [searchUserId, setSearchUserId] = useState<number | undefined>(undefined);

  const projectId = useProjectId();
  const { data: events } = useQuery(getAdminUserTicketsQuery({ projectId, userId: userId || 0 }));
  const { data: checks, refetch: refetchChecks } = useQuery(
    getUserTransferCheckQuery({
      projectId,
      userId: searchUserId || 0,
      bookingId: selectedEvents && selectedEvents.length > 0 ? selectedEvents[0] : 0,
    })
  );

  const [expanded, setExpanded] = useState(false);
  const [isAttending, setIsAttending] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isFree, setIsFree] = useState(false);

  useEffect(() => {
    if (!events || !selectedEvents) return;
    if (isOpen) {
      const selectedEvent = events.find((e: any) => e.bookingId === selectedEvents[0]);
      if (!selectedEvent) return;
      setIsAttending(selectedEvent.isAttending);
      setIsPaid(selectedEvent.isPaid);
      setIsFree(!selectedEvent.basePriceSek);
    }
  }, [isOpen, events, selectedEvents]);

  const returnSuccessfullCount = () => {
    if (!checks) return 0;
    return (
      (checks.isBeforeTransferTime.isOk ? 1 : 0) +
      (checks.isFiltered.isOk ? 1 : 0) +
      (checks.isMaxGroups.isOk ? 1 : 0) +
      (checks.isOverlapping.isOk ? 1 : 0)
    );
  };

  const handleCloseModal = () => {
    setSearchUserId(undefined);
    setExpanded(false);
    setIsAttending(false);
    setIsPaid(false);
    closeModal();
  };

  const transferTicket = useTransferTicket(userId, handleCloseModal);

  const searchUser = (value: number) => {
    setSearchUserId(value);
    refetchChecks();
  };

  const disableButton = () => {
    if (checks) return !checks.isNovelEvent.isOk;
    return true;
  };

  const handleSubmit = () => {
    transferTicket.mutate({
      projectId,
      userId: searchUserId || 0,
      bookingId: selectedEvents && selectedEvents.length > 0 ? selectedEvents[0] : 0,
      isAttending,
      isPaid,
    });
  };

  return (
    <>
      <SubpageModal
        hideHeader={!isMobile}
        title="Transfer ticket"
        maxWidth={918}
        isOpen={isOpen}
        reset={() => null}
        close={handleCloseModal}
        hideReset
        variant="thick"
      >
        <div style={{ width: '90vw' }} /> {/* This is to fix a bug in the modal */}
        <div style={{ display: 'flex', marginTop: '20px', marginBottom: '22px' }}>
          <div style={{ flex: 1 }}>
            <Typography variant={isMobile ? 'h6' : 'h5'}>Transfer ticket</Typography>
            <Typography variant="body2" color="textSecondary">
              Transfer the ticket to another user.
            </Typography>
          </div>
          {!isMobile && (
            <IconButton onClick={handleCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          )}
        </div>
        {!isMobile && <Divider />}
        <Typography variant="h6" style={{ marginBottom: '16px', marginTop: '16px' }}>
          Choose User
        </Typography>
        <SearchBar setSelected={searchUser} projectId={projectId} clearOnReset clearOnEmpty={false} />
        {searchUserId && (
          <>
            <GeneralSettings userId={searchUserId} hideCheckedIn />
            <Typography variant="h6">Event information</Typography>
            <Typography variant="body2" color="textSecondary">
              Coming soon (TM)
            </Typography>
            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />

            <CheckOverview
              isMobile={isMobile}
              success={returnSuccessfullCount() === 4}
              setExpanded={() => setExpanded(!expanded)}
              isExpanded={expanded}
              description={`${returnSuccessfullCount()}/4 successful`}
            />

            <Collapse in={isMobile ? expanded : true} timeout="auto">
              {isMobile && <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />}
              <TicketChecklistContainer checks={checks} isMobile={isMobile} />
            </Collapse>

            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
            <Typography variant="h6">Status</Typography>
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isFree || isPaid}
                  disabled={isFree}
                  name="checkedC"
                  color="secondary"
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsPaid(!isPaid)}
                />
              }
              label={
                <Typography
                  variant="body2"
                  style={{ display: 'inline' }}
                  color={`#000000${isFree ? theme.palette.other.opacityDisabled : ''}`}
                >
                  {isFree ? 'Free event' : 'Mark as paid'}
                </Typography>
              }
            />
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isAttending}
                  name="checkedD"
                  color="secondary"
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsAttending(!isAttending)}
                />
              }
              label={
                <Typography variant="body2" style={{ display: 'inline' }}>
                  Mark as attending
                </Typography>
              }
            />

            <Button
              fullWidth
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              disabled={disableButton() || transferTicket.isPending}
              style={{ marginTop: '16px', marginBottom: '25px' }}
            >
              {disableButton() ? 'User already booked' : 'confirm'}
            </Button>
          </>
        )}
      </SubpageModal>
    </>
  );
};

export default TicketTransferContainer;
