import React from 'react';

import { Button, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useProjectId from 'hooks/useProjectId';
import { getUserCheckedInQuery, toggleUserCheckedIn } from 'queries/admin-users/admin-user-checked-in';

interface Props {
  userId: number;
}

const CheckedInContainer: React.FC<Props> = ({ userId }) => {
  const theme = useDynamicTheme();
  const projectId = useProjectId();
  const { data: checkedIn, isFetching } = useQuery(
    getUserCheckedInQuery({
      projectId,
      userId,
    })
  );

  const toggleUserCheckedInMutation = toggleUserCheckedIn({ projectId, userId });

  if (!userId) {
    return <></>;
  }

  if (checkedIn === undefined) return <CircularProgress color="secondary" />;

  return (
    <Button
      variant="contained"
      color="secondary"
      style={{ backgroundColor: checkedIn ? theme.palette.success.main : '', width: '136px' }}
      onClick={() => toggleUserCheckedInMutation.mutate(checkedIn)}
      disabled={toggleUserCheckedInMutation.isPending || isFetching}
    >
      {checkedIn ? 'Checked In' : 'Check In'}
    </Button>
  );
};

export default CheckedInContainer;
