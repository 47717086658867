import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import { Divider, Typography } from '@mui/material';
import EventFiltersList from './containers/EventFiltersList';

const FiltersOverview = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <Typography variant={isMobile ? 'h5' : 'h4'} style={isMobile ? { marginTop: '16px', marginBottom: '10px' } : {}}>
        Event Filters
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} style={{ marginTop: 0, marginBottom: '12px' }}>
        Create specific event filters containing events visible only for specific study programs.
      </Typography>
      <Divider style={{ marginTop: '16px' }} />
      <EventFiltersList />
    </>
  );
};

export default FiltersOverview;
