import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TableRow, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import useDynamicTheme from 'hooks/useDynamicTheme';

interface BookingBodyDesktopProps {
  isActivated: boolean;
  isPaid: boolean;
  isAttended: boolean;
  date: string;
  title: string;
  price: number;
  onClick: () => void;
}

const BookingBodyDesktop: React.FC<BookingBodyDesktopProps> = ({
  isActivated,
  isPaid,
  isAttended,
  date,
  title,
  price,
  onClick,
}) => {
  const theme = useDynamicTheme();
  return (
    <TableRow
      style={{
        backgroundColor: isActivated ? `${theme.palette.secondary.main}${theme.palette.overlay.opacity.selected}` : '',
        color: isActivated ? theme.palette.secondary.main : '',
      }}
    >
      <CustomTableCell>
        <Checkbox checked={isActivated} color="secondary" onChange={onClick} style={{ padding: 0 }} />
      </CustomTableCell>
      <CustomTableCell>
        <div style={{ display: 'flex' }}>
          <Typography variant="body1" style={{ color: isActivated ? theme.palette.secondary.main : '' }}>
            {date}
          </Typography>
        </div>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="body1" style={{ color: isActivated ? theme.palette.secondary.main : '' }}>
          {title}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body1" style={{ color: isPaid ? theme.palette.success.main : theme.palette.error.main }}>
            {price} SEK
          </Typography>
        </div>
      </CustomTableCell>
      <CustomTableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!isAttended ? (
            <CloseIcon style={{ color: theme.palette.error.main }} />
          ) : (
            <CheckIcon style={{ color: theme.palette.success.main }} />
          )}
        </div>
      </CustomTableCell>
    </TableRow>
  );
};

export default BookingBodyDesktop;
