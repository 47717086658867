import React, { useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton } from '@mui/material';
import ClearableTextField from 'components/clearableTextField/ClearableTextField';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import AddEventFab from './components/add-event-fab';
import AdminEventList from './containers/EventList';
import { AdminHeader } from '/components/adminHeader/AdminHeader';

const EventOverview = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <AdminHeader
        isMobile={isMobile}
        title="Events"
        subtitle="Create and edit all events of the project. Use the management function to change payment and attendance status of the user."
      />
      <div style={{ display: 'flex', gap: '16px' }}>
        <ClearableTextField value={searchValue} setValue={setSearchValue} placeholder="Search Event" label="" />
        <IconButton disabled style={{ width: '56px', height: '56px' }} size="large">
          <FilterListIcon fontSize="small" />
        </IconButton>
      </div>
      <AdminEventList searchValue={searchValue} />
      <AddEventFab openModal={() => navigate('create')} />
    </>
  );
};

export default EventOverview;
