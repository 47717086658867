import { useMutation } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

const deleteEventFilter = async (projectId: string, eventFilterId: string | number) => {
  await Backend.request('DELETE', `admin/${projectId}/event-filter/${eventFilterId}`);
};

const useDeleteEventFilter = (onSuccess: () => Promise<void>) => {
  const projectId = useProjectId();

  return useMutation({
    mutationFn: async (deleteFilterId: number | null) => {
      if (!deleteFilterId) return;
      await deleteEventFilter(projectId, deleteFilterId);
    },

    onSuccess: onSuccess,
  });
};

export default useDeleteEventFilter;
