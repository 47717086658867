import React from 'react';

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import ToolsRow from 'pages/admin/events/sub-pages/manage/components/ToolsRow';
import useFileUpload from 'queries/admin-events/useFileUpload';
import useProjectId from 'hooks/useProjectId';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
});

const FlexRowItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  imageKey: string;
  title: string;
  accept: '.webp';
}

const ImageUploadRow: React.FC<Props> = ({ imageKey, title, accept }) => {
  const theme = useDynamicTheme();
  const uploadImage = useFileUpload();
  const projectId = useProjectId();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files as FileList)[0];
    uploadImage.mutate({ file, imageKey, projectId });
  };

  return (
    <>
      <Row>
        <FlexRowItem>
          <ToolsRow title={title} subtitle="" />
          <LoadingButton component="label" variant="contained" color="secondary">
            Upload
            <VisuallyHiddenInput type="file" accept={accept} onChange={handleFileUpload} />
          </LoadingButton>
        </FlexRowItem>
      </Row>
    </>
  );
};

export default ImageUploadRow;
