import React from 'react';
import { Button, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import useIsMobile from 'hooks/useIsMobile';

interface AddBookingButtonProps {
  openModal: () => void;
  userId?: number;
  buttonText?: string;
}

const AddBookingButton: React.FC<AddBookingButtonProps> = ({ openModal, userId, buttonText = 'Add Event' }) => {
  const isMobile = useIsMobile();

  if (!userId) return <></>;

  return (
    <>
      {isMobile ? (
        <>
          <Fab
            onClick={openModal}
            color="secondary"
            aria-label="add"
            style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 99 }}
          >
            <AddIcon />
          </Fab>
        </>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          style={{
            borderRadius: '50px',
            height: '48px',
            marginTop: '16px',
            float: 'right',
          }}
          onClick={openModal}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

export default AddBookingButton;
