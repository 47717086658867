import React from 'react';

import ColorHexField from 'components/colorPicker/ColorHexField';
import ColorPicker from 'components/colorPicker/ColorPicker';

interface Props {
  label: string;
  color: string;
  setColor: (hexColor: string) => void;
}

const ColorPickerField: React.FC<Props> = ({ label, color, setColor }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ColorHexField
        label={label}
        readOnly
        color={color}
        actualColor={color}
        setColor={setColor}
        setAnchorEl={setAnchorEl}
      />
      <ColorPicker label={label} color={color} setColor={setColor} anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  );
};

export default ColorPickerField;
