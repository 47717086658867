import { useQuery } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import useEditEventFilter from 'queries/admin-events/useEditEventFilter';
import getAdminEventFilterQuery from 'queries/admin-events/admin-event-filter';
import getAdminEventsQuery from 'queries/admin-events/admin-events';
import getUserStudyOptionsQuery from 'queries/user/user-study';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Errors {
  name: string;
}

const useEditFilter = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const feedback = useOpenFeedback();
  const { eventFilterId } = useParams<{ eventFilterId?: string }>();
  const adminEvents = useQuery(getAdminEventsQuery({ projectId }));
  const eventFilters = useQuery(getAdminEventFilterQuery({ projectId }));
  const studyPrograms = useQuery(getUserStudyOptionsQuery());

  const isCreationMode = !eventFilterId;

  const [filterName, setFilterName] = useState('');
  const [editing, setEditing] = useState(false);
  const [eventSearch, setEventSearch] = useState('');
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [programSearch, setProgramSearch] = useState('');
  const [selectedPrograms, setSelectedPrograms] = useState<number[]>([]);
  const [errors, setErrors] = useState<Errors>({ name: '' });

  const saveFilter = useEditEventFilter();

  const handleFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(e.target.value);
  };

  const handleEventSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventSearch(e.target.value);
  };

  const handleProgramSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramSearch(e.target.value);
  };

  const handleSelectAllPrograms = () => {
    if (selectedPrograms.length === studyPrograms.data?.length) {
      setSelectedPrograms([]);
    } else {
      setSelectedPrograms(studyPrograms.data?.map((program) => program.studyProgramId) || []);
    }
  };

  const handleProgramSelect = (programId: number) => {
    if (selectedPrograms.includes(programId)) {
      setSelectedPrograms(selectedPrograms.filter((program) => program !== programId));
    } else {
      setSelectedPrograms([...selectedPrograms, programId]);
    }
  };

  const handleEventSelect = (id: number) => {
    if (selectedEvents.includes(id)) {
      setSelectedEvents(selectedEvents.filter((event) => event !== id));
    } else {
      setSelectedEvents([...selectedEvents, id]);
    }
  };

  const handleSubmit = () => {
    if (filterName.length === 0) {
      setErrors({
        ...errors,
        name: 'Event filter name is required',
      });
      return;
    }

    setErrors({ name: '' });

    saveFilter.mutate({
      isCreationMode,
      eventFilterId,
      selectedEvents: selectedEvents,
      filterName: filterName,
      selectedPrograms: selectedPrograms,
    });
  };

  const handleEditing = () => {
    if (isCreationMode) {
      handleSubmit();
      return;
    }

    if (!editing) {
      setEditing(true);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!adminEvents.data) return;
    if (!eventFilters.data) return;
    if (!studyPrograms.data) return;
    if (isCreationMode) return;

    const currentFilter = eventFilters.data.find((filter) => filter.id === parseInt(eventFilterId as string, 10));

    if (!currentFilter) return;

    // fill form with data
    setFilterName(currentFilter.name);
    setSelectedEvents(currentFilter.eventIds);
    setSelectedPrograms(currentFilter.studyProgramIds);
  }, [adminEvents.data, eventFilters.data, studyPrograms.data, isCreationMode]);

  return {
    eventsData: adminEvents.data,
    filtersData: eventFilters.data,
    studyProgramsData: studyPrograms.data,
    eventFilterId,
    isCreationMode,
    filterName,
    selectedEvents,
    eventSearch,
    programSearch,
    selectedPrograms,
    handleFilterNameChange,
    handleEventSearchChange,
    handleEventSelect,
    handleProgramSelect,
    handleProgramSearchChange,
    handleSelectAllPrograms,
    errors,
    editing: editing || isCreationMode,
    handleEditing,
  };
};

export default useEditFilter;
