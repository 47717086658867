import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useSubMenuStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 99,
    height: '56px',
    position: 'fixed',
    backgroundColor: '#fff',
    padding: '0 1rem',
    borderRadius: '0',
    top: 0,
    left: 0,
    right: 0,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    color: theme.palette.secondary.main,
    alignItems: 'center',
    height: '100%',
    gap: '1rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
  },
  arrowBackIcon: {
    width: '1.4rem',
    height: '1.4rem',
  },
}));

export default useSubMenuStyles;
