import React from 'react';

import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import useDynamicTheme from 'hooks/useDynamicTheme';

export type AdminEventCheckItemProps = {
  label: string;
  value: string;
  showStatus: boolean;
  isOk: boolean;
};

const Item = ({ label, value, showStatus, isOk }: AdminEventCheckItemProps) => {
  const theme = useDynamicTheme();
  return (
    <div
      style={{
        display: 'flex',
        gap: showStatus ? '2px' : '6px',
        alignItems: 'center',
        marginBottom: '5px',
      }}
    >
      <Typography variant="body2">{label}:</Typography>
      {showStatus &&
        (isOk ? (
          <CheckIcon fontSize="small" style={{ color: theme.palette.success.main }} />
        ) : (
          <CloseIcon fontSize="small" color="error" />
        ))}
      <Typography variant="body2" color="textSecondary" noWrap>
        {value}
      </Typography>
    </div>
  );
};

export type AdminEventCheckProps = {
  items: AdminEventCheckItemProps[];
};

/**
 * Displays a list of checkable list items
 */
const AdminEventCheck = ({ items }: AdminEventCheckProps) => (
  <>
    {items.map((item) => (
      <div key={`admin--event--check--${item.label}`}>
        <Item label={item.label} value={item.value} showStatus={item.showStatus} isOk={item.isOk} />
      </div>
    ))}
  </>
);

export default AdminEventCheck;
