import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

const validateHexColorPartial = (hexColor: string) => {
  if (hexColor.match(/[^a-fA-F0-9]/g)) return false;
  return true;
};

const inputIsColor = (input: string): boolean => {
  if (input.length !== 7) return false;
  if (input[0] !== '#') return false;
  if (!validateHexColorPartial(input.slice(1, 3))) return false;
  if (!validateHexColorPartial(input.slice(3, 5))) return false;
  if (!validateHexColorPartial(input.slice(5, 7))) return false;
  return true;
};

const useColorInput = (color: string, setColor: (hexColor: string) => void) => {
  const [innerColor, setInnerColor] = useState(color);
  const [colorInput, setColorInput] = useState(color);

  // input change > useEffect colorInput > useEffect innerColor > input change (block)
  const handleChange = (hexColor: string) => {
    // block circular update
    if (hexColor === colorInput) return;
    setColorInput(hexColor);
  };

  // Need to put in wrapper function, as when dragging too fast, an infinite loop is created
  const delayedUpdate = useCallback(
    _.debounce((c: string) => {
      if (inputIsColor(c)) {
        setColor(c);
        setInnerColor(c);
      }
    }, 1),
    []
  );

  useEffect(() => {
    delayedUpdate(colorInput);
  }, [colorInput, delayedUpdate]);

  useEffect(() => {
    setColorInput(innerColor);
  }, [innerColor]);

  return { colorInput, innerColor, setInnerColor, handleChange };
};

export default useColorInput;
