import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import { useNavigate } from 'react-router-dom';

interface MutationProps {
  isCreationMode: boolean;
  eventFilterId: string | undefined;
  selectedEvents: number[];
  filterName: string;
  selectedPrograms: number[];
}

const useEditEventFilter = () => {
  const projectId = useProjectId();
  const feedback = useOpenFeedback();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({
      isCreationMode,
      eventFilterId,
      selectedEvents,
      filterName,
      selectedPrograms,
    }: MutationProps) => {
      await Backend.request(
        isCreationMode ? 'POST' : 'PATCH',
        isCreationMode ? `/admin/${projectId}/event-filter` : `/admin/${projectId}/event-filter/${eventFilterId}`,
        {},
        {
          eventIds: selectedEvents,
          name: filterName,
          studyProgramIds: selectedPrograms,
        }
      );
    },

    onSuccess: () => {
      feedback.openSuccess('Event filter saved successfully');
      navigate(-1);
    },
  });
};

export default useEditEventFilter;
