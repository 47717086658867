import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  label: React.ReactNode;
}

const CheckboxForm: React.FC<Props> = ({ checked, onChange, disabled, label }) => (
  <FormControlLabel
    control={<Checkbox color="secondary" checked={checked} onChange={onChange} disabled={disabled} />}
    label={label}
    style={{ flexGrow: '1' }}
  />
);

export default CheckboxForm;
