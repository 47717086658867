import { Typography } from '@mui/material';
import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import SwitchComponent from 'components/form/Switch';
import TextComponent from 'components/form/Text';
import { editEventFormKeys } from '../types/FormTypes';
import { AttendanceInputContainer } from './EventAttendancePrice.styles';
import { InputRoot } from './EventInput.styles';

interface EventAttendancePriceProps {
  control: Control<any, any>;
  watch: UseFormWatch<any>;
}

const EventAttendancePrice: React.FC<EventAttendancePriceProps> = ({ control, watch }) => {
  const buildRules = (pleaseEnterText: string, watchField: string): { [x: string]: any } => ({
    required: { value: !watch(watchField), message: pleaseEnterText },
    minValue: { value: 0, message: 'Minimum 0' },
  });

  return (
    <>
      <InputRoot>
        <Typography variant="body1">Attendance</Typography>
        <AttendanceInputContainer>
          <TextComponent
            name={editEventFormKeys.attendanceLimit}
            control={control}
            rules={buildRules('Enter attendance limit', editEventFormKeys.isNoAttendanceLimit)}
            type="number"
            label="Attendance limit"
            disabled={watch(editEventFormKeys.isNoAttendanceLimit)}
            style={{ margin: '0' }}
            helperText="Enter the max. amount of participants"
          />
          <SwitchComponent
            name={editEventFormKeys.isNoAttendanceLimit}
            control={control}
            labelOn="No limit"
            labelOff=""
          />
        </AttendanceInputContainer>
      </InputRoot>
      <InputRoot>
        <Typography variant="body1">Price</Typography>
        <AttendanceInputContainer>
          <TextComponent
            name={editEventFormKeys.price}
            control={control}
            rules={buildRules('Enter price', editEventFormKeys.isFree)}
            type="number"
            label="Price"
            disabled={watch(editEventFormKeys.isFree)}
            style={{ margin: '0' }}
            helperText="Enter the price in SEK"
          />
          <SwitchComponent name={editEventFormKeys.isFree} control={control} labelOn="Free" labelOff="" />
        </AttendanceInputContainer>
      </InputRoot>
    </>
  );
};

export default EventAttendancePrice;
