import { AdminHeader } from 'components/adminHeader/AdminHeader';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import useImportEvents from './hooks/useImportEvents';
import ImportEvents from './containers/ImportEvents';

const ImportAndExport = () => {
  const isMobile = useIsMobile();
  const importEvents = useImportEvents();

  return (
    <>
      <AdminHeader isMobile={isMobile} title="Import & Export" subtitle="Upload and download data from the system." />
      <ImportEvents handleImport={importEvents.addFile} loading={importEvents.loading} />
    </>
  );
};

export default ImportAndExport;
