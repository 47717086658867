import React from 'react';

import { AdminHeader } from 'components/adminHeader/AdminHeader';

const EventGeneralHeader = () => (
  <AdminHeader
    isMobile
    title="General Settings"
    subtitle="Primary event settings. All fields are required and must be filled out"
    disableDivider
  />
);

export default EventGeneralHeader;
