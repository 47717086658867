import React from 'react';

import { Close, DeleteOutline, Edit } from '@mui/icons-material';
import { TableRow, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { UserAccessEditable } from 'pages/admin/access/hooks/useUsersAccess';
import UserRoleMenu from './UserRoleMenu';

interface Props {
  user: UserAccessEditable;
  roles: {
    roleId: number;
    title: string;
  }[];
  setUserRole: (accountId: number, roleId: number) => void;
  setUserEditing: (accountId: number) => void;
}

const BodyMobile: React.FC<Props> = ({ user, roles, setUserRole, setUserEditing }) => {
  const theme = useDynamicTheme();

  return (
    <TableRow>
      <CustomTableCell style={{ maxWidth: '212px' }}>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          {user.editing ? (
            <IconButton
              onClick={() => null}
              size="small"
              aria-label="delete role"
              style={{ color: `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}` }}
              disabled
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>
          ) : null}
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Typography noWrap variant="body2" style={{ marginBottom: '8px' }}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
            <Typography noWrap variant="caption">
              {user.email}
            </Typography>
          </div>
        </div>
      </CustomTableCell>
      <CustomTableCell>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              {roles.find((role) => role.roleId === user.roleId)?.title || 'No Role'}
            </Typography>
            {user.editing ? (
              <UserRoleMenu
                options={roles.map((role) => ({
                  label: role.title,
                  onclick: () => {
                    setUserRole(user.accountId, role.roleId);
                  },
                }))}
              />
            ) : null}
          </div>
          <div>
            {user.editing ? (
              <IconButton onClick={() => setUserEditing(user.accountId)} size="small" aria-label="close delete option">
                <Close fontSize="inherit" color="primary" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setUserEditing(user.accountId)} size="small" aria-label="edit role">
                <Edit color="secondary" fontSize="inherit" />
              </IconButton>
            )}
          </div>
        </div>
      </CustomTableCell>
    </TableRow>
  );
};

export default BodyMobile;
