import React, { useEffect, useState } from 'react';
import useProjectId from 'hooks/useProjectId';
import { useQuery } from '@tanstack/react-query';
import getPrivateText from 'queries/texts/get-private-text';
import ButtonComponent from 'components/form/Button';
import { CircularProgress } from '@mui/material';
import TextEditor from 'components/textEditor/TextEditor';
import useSetPrivateText from 'queries/texts/useSetPrivateText';

const PagesEvents: React.FC = () => {
  const projectId = useProjectId();
  const { data, isLoading } = useQuery(getPrivateText(projectId));
  const [text, setText] = useState<string>(data?.EVENTS_MAIN ?? '');
  const updateText = useSetPrivateText('EVENTS_MAIN', 'Events Text');
  const [fetchedText, setFetchedText] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.EVENTS_MAIN) {
      setText(data.EVENTS_MAIN);
      setFetchedText(true);
    }
  }, [data]);

  return isLoading || !fetchedText ? (
    <CircularProgress />
  ) : (
    <>
      <TextEditor initialText={text} setText={setText} />
      <ButtonComponent
        click={() => {
          updateText.mutate(text);
        }}
        label="Save"
        fixedWidth
        loading={updateText.isPending}
      />
    </>
  );
};
export default PagesEvents;
