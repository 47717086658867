import { styled } from '@mui/material';

export const InputRoot = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  marginBottom: '16px',
}));

export default InputRoot;
