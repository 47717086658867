import React from 'react';
import { Paper } from '@mui/material';

import useSubMenuStyles from './SubMenu.styles';
import BackArrowText from './BackArrowText';

export interface SubMenuProps {
  isOpen: boolean;
  label: string;
  backButton: () => void;
  rightIcon?: React.ReactNode;
}

const SubMenu: React.FC<SubMenuProps> = ({ isOpen, label, backButton, rightIcon }) => {
  const classes = useSubMenuStyles();

  if (!isOpen) {
    return <></>;
  }

  return (
    <Paper id="sub-menu" elevation={2} className={classes.root}>
      <BackArrowText label={label} backButton={backButton} />
      {rightIcon}
    </Paper>
  );
};

export default SubMenu;
