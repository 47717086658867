import makeStyles from '@mui/styles/makeStyles';

const useCheckedInStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default useCheckedInStyles;
