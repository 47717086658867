import React from 'react';

import { HexColorPicker } from 'react-colorful';
import ColorHexField from './ColorHexField';
import ColorPopup from './ColorPopup';
import './react-colorful.styles.css';
import useColorInput from './useColorInput';

interface Props {
  label: string;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  color: string;
  setColor: (hexColor: string) => void;
}

const ColorPicker: React.FC<Props> = ({ label, anchorEl, handleClose, color, setColor }) => {
  const colorVm = useColorInput(color, setColor);
  return (
    <ColorPopup anchorEl={anchorEl} handleClose={handleClose}>
      <ColorHexField
        label={label}
        color={colorVm.colorInput}
        actualColor={colorVm.innerColor}
        setColor={colorVm.handleChange}
      />
      <HexColorPicker color={colorVm.innerColor} onChange={colorVm.setInnerColor} />
    </ColorPopup>
  );
};

export default ColorPicker;
