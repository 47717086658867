import * as Backend from 'queries/BackendRequest';
import { StudyOption } from 'types/types';

export const getStudyOptions = async () => {
  const response = await Backend.request<StudyOption[]>('GET', '/account/programmes');
  return response;
};

const fetchStudyOptions = async (): Promise<StudyOption[]> => {
  try {
    const response = await getStudyOptions();

    const sInfo: any = response.data.data;
    sInfo.sort((a: any, b: any) => a.nameEn.localeCompare(b.nameEn));
    return sInfo;
  } catch (error) {
    /* empty */
  }
  return [];
};

const getUserStudyOptionsQuery = () => ({
  queryKey: ['getUserStudyOptionsQuery'],
  queryFn: () => fetchStudyOptions(),
});

export default getUserStudyOptionsQuery;
