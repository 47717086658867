import { Typography } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';

import TextComponent from 'components/form/Text';
import { editEventFormKeys } from '../types/FormTypes';

interface EventTitleDescProps {
  control: Control<any, any>;
}

const EventTitleDesc: React.FC<EventTitleDescProps> = ({ control }) => (
  <div style={{ display: 'flex', gap: '8px', flexDirection: 'column', marginBottom: '16px' }}>
    <Typography variant="body1">Event</Typography>
    <TextComponent
      name={editEventFormKeys.title}
      control={control}
      rules={{
        required: { value: true, message: 'Enter event title' },
        maxLength: { value: 64, message: 'Maximum 64 characters' },
      }}
      type="text"
      label="Title"
      helperText="Maximum 64 characters"
      style={{ margin: '0', marginBottom: '0.5rem' }}
    />
    <TextComponent
      name={editEventFormKeys.description}
      control={control}
      rules={{
        required: { value: true, message: 'Enter event description' },
      }}
      type="text"
      label="Description"
      rows={12}
      style={{ margin: '0' }}
    />
  </div>
);

export default EventTitleDesc;
