import React from 'react';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import OptionsMenuButton, { OptionsMenuButtonProps } from './OptionsMenuButton';

export interface OptionsMenuProps {
  onDeleteClick: () => void;
  onTransferClick: () => void;
}

const OptionsMenu: React.FC<OptionsMenuProps> = ({ onDeleteClick, onTransferClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const items: OptionsMenuButtonProps[] = [
    {
      icon: <DeleteIcon fontSize="small" />,
      label: 'Delete user',
      onClick: onDeleteClick,
    },
    {
      icon: <SwapHorizIcon fontSize="small" />,
      label: 'Transfer',
      onClick: onTransferClick,
      disabled: true,
    },
  ];

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="user-options-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
        size="large"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu id="user-options-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {items.map((item) => (
          <div key={`options-menu-item-${item.label}`}>
            <OptionsMenuButton {...item} />
          </div>
        ))}
      </Menu>
    </>
  );
};

export default OptionsMenu;
