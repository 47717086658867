import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import useEventImport, { EventBatch } from 'queries/event/useEventImport';
import React from 'react';

const useImportEvents = () => {
  const projectId = useProjectId();
  const feedback = useOpenFeedback();
  const sendData = useEventImport();

  const addFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || !files.length) {
      feedback.openError('Please upload a file.');
      return;
    }

    const file: File = files[0];
    if (file.type !== 'application/json') {
      feedback.openError('Please upload a json file.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (loadEvent) => {
      const contents = loadEvent.target?.result;
      if (!contents) {
        feedback.openError('Error loading file contents.');
        return;
      }
      let parsedContents: EventBatch;
      try {
        parsedContents = JSON.parse(contents.toString());
      } catch (error) {
        feedback.openError('Error parsing file contents.');
        return;
      }
      if (parsedContents?.events === undefined) {
        feedback.openError('Error parsing file contents.');
        return;
      }
      sendData.mutate({ eventList: parsedContents, pid: projectId });
    };
    reader.readAsText(file);
  };

  return { addFile, loading: sendData.isPending };
};

export default useImportEvents;
