import BackArrowText from 'components/subMenu/BackArrowText';
import SubMenu from 'components/subMenu/SubMenu';
import SubMenuSpacer from 'components/subMenu/SubMenuSpacer';
import { useSubMenuContext } from 'context/SubMenuContext';
import useIsMobile from 'hooks/useIsMobile';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

interface OpenEventEditSubMenuProps {
  handleBack?: () => void;
  rightIcon?: React.ReactNode;
}

const OpenEventEditSubMenu: React.FC<OpenEventEditSubMenuProps> = ({ handleBack, rightIcon }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const subMenu = useSubMenuContext();

  useEffect(() => {
    if (!subMenu.isSubMenu) {
      subMenu.openSubMenu();
    }
    return () => {
      subMenu.closeSubMenu();
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      subMenu.openSubMenu();
    }
  }, [isMobile]);

  const handleBackClick = () => {
    if (handleBack !== undefined) {
      handleBack();
      return;
    }
    navigate(-1);
  };

  return (
    <>
      {!isMobile ? (
        <div style={{ marginBottom: '1rem' }}>
          <BackArrowText label="Back to overview" backButton={handleBackClick} />
        </div>
      ) : (
        <>
          {createPortal(
            <SubMenu isOpen label="Back to overview" backButton={handleBackClick} rightIcon={rightIcon} />,
            document.body
          )}
          <SubMenuSpacer />
        </>
      )}
    </>
  );
};

export default OpenEventEditSubMenu;
