import AddIcon from '@mui/icons-material/Add';
import { Button, Fab } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';

interface Props {
  onClick: () => void;
  label?: string;
  icon?: React.ReactNode;
}

const FabOrButton: React.FC<Props> = ({ onClick, icon = <AddIcon />, label = 'Create' }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Fab
        onClick={onClick}
        color="secondary"
        aria-label="create"
        style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: '1200' }}
      >
        {icon}
      </Fab>
    );
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={icon}
      style={{
        borderRadius: '50px',
        height: '48px',
        marginTop: '16px',
        float: 'right',
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default FabOrButton;
