import { Divider, Typography } from '@mui/material';
import SearchBar from 'components/searchBar/SearchBar';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DisplayUserContainer from './containers/DisplayUserContainer';

const Users: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const projectId = useProjectId();

  const handleUserSelect = (id: number) => {
    navigate(`${id}`);
  };

  return (
    <>
      <Typography variant={isMobile ? 'h5' : 'h4'} style={isMobile ? { marginTop: '16px', marginBottom: '10px' } : {}}>
        Users
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} style={{ marginTop: 0, marginBottom: '12px' }}>
        Search for users and check them in, view and update event bookings.
      </Typography>
      <Divider style={{ marginBottom: '16px' }} />
      <SearchBar setSelected={handleUserSelect} projectId={projectId} clearOnEmpty={false} />
      <Routes>
        <Route path="/:userId" element={<DisplayUserContainer />} />
        <Route path="*" element={<div />} />
      </Routes>
    </>
  );
};

export default Users;
