import React from 'react';

import { AdminHeader } from 'components/adminHeader/AdminHeader';
import useIsMobile from 'hooks/useIsMobile';

const EditEventHeader = () => {
  const isMobile = useIsMobile();

  return (
    <AdminHeader
      isMobile={isMobile}
      title="Manage Event"
      subtitle="See the status of the event and manage all bookings."
      disableDivider
    />
  );
};

export default EditEventHeader;
