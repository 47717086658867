import React from 'react';
import { Divider } from '@mui/material';
import { AdminHeader } from 'components/adminHeader/AdminHeader';
import useIsMobile from 'hooks/useIsMobile';
import ImageUploadRow from './containers/ImageUploadRow';

const CustomizationThemeImages = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <AdminHeader isMobile={isMobile} title="Change theme images" subtitle="Upload images for the project theme" />
      <ImageUploadRow imageKey="NAVBAR_LOGO" title="Logo Desktop" accept=".webp" />
      <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
      <ImageUploadRow imageKey="BRAND_LOGO" title="Logo Mobile" accept=".webp" />
      <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
      <ImageUploadRow imageKey="LANDING" title="Landing Page Image" accept=".webp" />
    </>
  );
};

export default CustomizationThemeImages;
