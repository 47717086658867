import { useMutation } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

interface MutationProps {
  accountId: number;
}

const useDeleteUser = () => {
  const projectId = useProjectId();
  return useMutation({
    mutationFn: async ({ accountId }: MutationProps) => {
      await Backend.request('delete', `admin/${projectId}/user`, { removalId: accountId });
    },
  });
};

export default useDeleteUser;
