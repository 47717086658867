import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import { getBookingStatus, getTimeDuration, getTransferStatus } from 'pages/admin/events/utils/EventCardMapper';
import { BookingStats } from 'queries/admin-events/types';
import { EventDetails } from 'types/types';
import AdminEventCardTrimmed from './AdminEventCardTrimmed';

interface EventDisplayProps {
  event: EventDetails;
  stats: BookingStats;
}

const EventDisplay: React.FC<EventDisplayProps> = ({ event, stats }) => {
  const isMobile = useIsMobile();

  return (
    <AdminEventCardTrimmed
      title={event.displayTitle}
      subtitle={getTimeDuration(event)}
      eventId={event.eventId}
      currentEventBooked={stats.countBooked}
      currentEventAttended={stats.countAttending}
      maxEventAttended={event.attendanceLimit}
      paidPeople={stats.countPaid}
      isMobile={isMobile}
      price={event.basePriceSek}
      checks={[
        {
          label: 'Location',
          value: event.location,
          showStatus: false,
          isOk: false,
        },
        {
          label: 'Price',
          value: `${event.basePriceSek} SEK`,
          showStatus: false,
          isOk: false,
        },
        {
          ...getBookingStatus(event),
        },
        {
          ...getTransferStatus(event),
        },
      ]}
      bookings={event.eventBookings}
    />
  );
};

export default EventDisplay;
