import * as Backend from 'queries/BackendRequest';

export interface GetSearchUserParams {
  projectId: string;
  search: string;
}

export interface SearchUserResponse {
  id: number;
  email: string;
  name: string;
  score: number;
}

export const getSearchUser = async ({ projectId, search }: GetSearchUserParams): Promise<SearchUserResponse[]> => {
  const result = await Backend.request<SearchUserResponse[]>('get', `/admin/${projectId}/users/search/${search}`);
  return result?.data?.data;
};

export const getSearchUserQuery = ({ projectId, search }: GetSearchUserParams) => ({
  queryKey: ['getSearchUserQuery', search],
  queryFn: () => getSearchUser({ projectId, search }),
  enabled: !!projectId && search.length > 1,
});
