import React, { useMemo } from 'react';
import OpenEventEditSubMenu from 'containers/subMenu/OpenEventEditSubMenu';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import { CheckboxEventList } from 'components/adminCheckboxEventList/CheckboxEventList';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MobileEditFab from 'components/mobileEditFab/MobileEditFab';
import useDeleteFilter from '../overview/hooks/useDeleteFilter';
import useEditFilter from './hooks/useEditFilter';
import FilterDeleteDialog from '../components/FilterDeleteDialog';
import { ApplyEventsContainer } from './components/Containers';
import ProgramCard from './components/ProgramCard';
import ProgramHead from './components/ProgramHead';
import ProgramRow from './components/ProgramRow';
import { StudyProgramRow } from './types/types';

const FiltersEdit = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const editFilter = useEditFilter();
  const deleteFilter = useDeleteFilter({
    afterSuccess: () => {
      navigate(-1);
    },
  });

  const mappedProgramRow: StudyProgramRow[] = useMemo((): StudyProgramRow[] => {
    if (!editFilter.eventsData || !editFilter.filtersData || !editFilter.studyProgramsData) return [];
    const mappedPrograms = editFilter.studyProgramsData.map((program) => {
      return {
        studyOption: program,
        selected: editFilter.selectedPrograms.includes(program.studyProgramId),
        onClick: () => editFilter.handleProgramSelect(program.studyProgramId),
      };
    });

    if (editFilter.programSearch.length > 2) {
      return mappedPrograms.filter(
        (program) =>
          program.studyOption.nameEn.toLowerCase().includes(editFilter.programSearch.toLowerCase()) ||
          program.studyOption.abbreviation.toLowerCase().includes(editFilter.programSearch.toLowerCase())
      );
    }
    return mappedPrograms;
  }, [
    editFilter.eventsData,
    editFilter.filtersData,
    editFilter.studyProgramsData,
    editFilter.selectedPrograms,
    editFilter.programSearch,
  ]);

  const openDeleteDialog = () => {
    deleteFilter.handleDeleteFilter(parseInt(editFilter.eventFilterId as string, 10), editFilter.filterName);
  };

  if (!editFilter.eventsData || !editFilter.filtersData || !editFilter.studyProgramsData)
    return <CircularProgress color="secondary" />;

  return (
    <>
      <MobileEditFab isEditing={editFilter.editing} onClick={editFilter.handleEditing} />
      <FilterDeleteDialog
        name={deleteFilter.deleteFilterName}
        confirmOnClick={deleteFilter.handleConfirmDeleteFilter}
        cancelOnClick={deleteFilter.handleCancelDeleteFilter}
        isOpen={deleteFilter.deleteDialogOpen}
        buttonDisable={deleteFilter.isLoading}
      />
      <OpenEventEditSubMenu
        handleBack={() => navigate(-1)}
        rightIcon={
          !editFilter.isCreationMode && (
            <IconButton color="secondary" onClick={openDeleteDialog}>
              <DeleteOutlineIcon />
            </IconButton>
          )
        }
      />
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <TextField
          label="Event Filter Name"
          color="secondary"
          value={editFilter.filterName}
          onChange={editFilter.handleFilterNameChange}
          error={editFilter.errors.name.length > 0}
          helperText={editFilter.errors.name}
          fullWidth
          disabled={!editFilter.editing}
        />
        {!isMobile && (
          <>
            {!editFilter.isCreationMode && (
              <Button color="secondary" style={{ height: '37px', width: '87px' }} onClick={openDeleteDialog}>
                Delete
              </Button>
            )}
            <Button
              color={editFilter.editing ? 'success' : 'secondary'}
              variant="contained"
              style={{ height: '37px', width: '96px' }}
              onClick={editFilter.handleEditing}
            >
              {editFilter.editing ? 'Save' : 'Edit'}
            </Button>
          </>
        )}
      </div>
      <Divider style={{ padding: '8px 0px' }} />
      <ApplyEventsContainer isMobile={isMobile}>
        <div style={{ flex: 0.6, marginBottom: '8px' }}>
          <Typography variant="h6">Applied Events</Typography>
          <Typography variant="body2">Select events the filter applies to</Typography>
        </div>
        <TextField
          label="Search Events"
          value={editFilter.eventSearch}
          onChange={editFilter.handleEventSearchChange}
          color="secondary"
          style={{ flex: 0.4 }}
        />
      </ApplyEventsContainer>
      <CheckboxEventList
        checked={editFilter.selectedEvents}
        events={editFilter.eventsData
          .filter((event) =>
            editFilter.eventSearch.length > 3
              ? event.displayTitle.toLowerCase().includes(editFilter.eventSearch.toLowerCase())
              : event
          )
          .map((event) => ({
            id: event.eventId,
            name: event.displayTitle,
            date: new Date(event.startDatetime),
          }))}
        onClick={(id: number) => editFilter.handleEventSelect(id)}
        isMobile={isMobile}
        isEditing={editFilter.editing}
      />
      <ApplyEventsContainer isMobile={isMobile} style={{ marginTop: '1rem' }}>
        <div style={{ flex: 0.6, marginBottom: '8px' }}>
          <Typography variant="h6">Applied Study Programs</Typography>
          <Typography variant="body2">Select programs the filter applies to</Typography>
        </div>
        <TextField
          label="Search Programs"
          value={editFilter.programSearch}
          onChange={editFilter.handleProgramSearchChange}
          color="secondary"
          style={{ flex: 0.4 }}
        />
      </ApplyEventsContainer>

      {isMobile ? (
        <div style={{ maxHeight: '440px', overflow: 'scroll', marginBottom: '16px' }}>
          {mappedProgramRow.map((programRow) => (
            <div style={{ paddingBottom: '16px' }}>
              <ProgramCard key={programRow.studyOption.studyProgramId} {...programRow} />
            </div>
          ))}
        </div>
      ) : (
        <TableContainer sx={{ maxHeight: '440px' }}>
          <Table stickyHeader>
            <ProgramHead
              allSelected={editFilter.selectedPrograms.length === editFilter.studyProgramsData.length}
              onClick={() => editFilter.handleSelectAllPrograms()}
            />
            <TableBody>
              {mappedProgramRow.map((programRow) => (
                <ProgramRow key={programRow.studyOption.studyProgramId} {...programRow} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default FiltersEdit;
