import * as Backend from 'queries/BackendRequest';

interface CheckEvent {
  details: string[];
  isOk: boolean;
}

export interface CheckEventToUserResponse {
  isFiltered: CheckEvent;
  isFull: CheckEvent;
  isMaxGroups: CheckEvent;
  isOverlapping: CheckEvent;
}

interface GetUserAddBookingCheckProps {
  projectId: string | number;
  userId: string | number;
  eventId: string | number;
}

const fetchCheckEvent = async ({
  projectId,
  userId,
  eventId,
}: GetUserAddBookingCheckProps): Promise<CheckEventToUserResponse> => {
  const result = await Backend.request<CheckEventToUserResponse>(
    'GET',
    `/admin/${projectId}/check-booking/${eventId}/${userId}`
  );
  return result.data.data;
};

export const getUserAddBookingCheckQuery = ({ projectId, userId, eventId }: GetUserAddBookingCheckProps) => ({
  queryKey: ['getUserAddBookingCheckQuery', projectId, userId, eventId],
  queryFn: () => fetchCheckEvent({ projectId, userId, eventId }),
  enabled: !!projectId && !!userId && !!eventId,
});

export default getUserAddBookingCheckQuery;
