import * as Backend from 'queries/BackendRequest';
import { EventGroup } from './types';
import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import { useNavigate } from 'react-router-dom';
import useProjectId from 'hooks/useProjectId';

interface ProjectIdProps {
  projectId: string;
}

export const getAdminEventGroup = async ({ projectId }: ProjectIdProps): Promise<EventGroup[]> => {
  const resp = await Backend.request('GET', `/admin/${projectId}/event-group`);
  const list = Object.values(resp?.data?.data) as EventGroup[];
  return list;
};

export const getAdminEventGroupQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEventGroup'],
  queryFn: () => getAdminEventGroup({ projectId }),
  enabled: !!projectId,
});

interface SaveGroupProps {
  isCreationMode: boolean;
  selectedEvents?: number[];
  isBroadcasted: boolean;
  hasTicketLimit: boolean;
  ticketLimit: string;
  groupName: string;
  eventGroupId?: string;
}

export const useSaveGroup = () => {
  const feedback = useOpenFeedback();
  const navigate = useNavigate();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: async ({
      isCreationMode,
      selectedEvents,
      isBroadcasted,
      hasTicketLimit,
      ticketLimit,
      groupName,
      eventGroupId,
    }: SaveGroupProps) => {
      if (isCreationMode && selectedEvents) {
        await Backend.request(
          'POST',
          `/admin/${projectId}/event-group`,
          {},
          {
            eventIDs: selectedEvents,
            isBroadcasted,
            ticketLimit: hasTicketLimit ? parseInt(ticketLimit, 10) : 0,
            name: groupName,
          }
        );
      } else {
        await Backend.request(
          'PATCH',
          `/admin/${projectId}/event-group/${eventGroupId}`,
          {},
          {
            eventIDs: selectedEvents,
            isBroadcasted,
            ticketLimit: hasTicketLimit ? parseInt(ticketLimit, 10) : 0,
            name: groupName,
          }
        );
      }
    },
    onSuccess: () => {
      feedback.openSuccess('Event group saved successfully');
      navigate(-1);
    },
  });
};
