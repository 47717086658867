import { Checkbox, TableRow, Typography } from '@mui/material';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import React from 'react';
import { StudyProgramRow } from '../types/types';

const ProgramRow: React.FC<StudyProgramRow> = ({ studyOption, selected, onClick }) => {
  return (
    <TableRow>
      <CustomTableCell>
        <Checkbox checked={selected} color="secondary" onChange={onClick} style={{ padding: 0 }} />
      </CustomTableCell>
      <CustomTableCell>
        <Typography
          variant="body2"
          style={{
            textOverflow: 'ellipsis',
            minWidth: '0px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {studyOption.typeEn}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography
          variant="body2"
          style={{
            textOverflow: 'ellipsis',
            minWidth: '0px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {studyOption.nameEn}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          {studyOption.chapter}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          {studyOption.school}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          {studyOption.abbreviation}
        </Typography>
      </CustomTableCell>
    </TableRow>
  );
};

export default ProgramRow;
