import { useMutation, useQueryClient } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import { EventDetails } from 'types/types';
import * as Backend from 'queries/BackendRequest';

interface MutationProps {
  eventId: string | number;
  bookingId: number;
  isPaid: boolean;
  isAttending: boolean;
}

const useUpdateBooking = () => {
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const sendData = useMutation({
    mutationFn: async ({ bookingId, isAttending, isPaid, eventId }: MutationProps) => {
      const previousEventDetails = queryClient.getQueryData<EventDetails>(['getAdminEventDetails', eventId]);

      if (!previousEventDetails) {
        return;
      }

      const newEventDetails: EventDetails = {
        ...previousEventDetails,
        eventBookings: previousEventDetails.eventBookings.map((booking) => {
          if (booking.bookingId === bookingId) {
            return {
              ...booking,
              isAttending,
              isPaid,
            };
          }
          return booking;
        }),
      };

      queryClient.setQueryData(['getAdminEventDetails', eventId], newEventDetails);
      await Backend.request('PATCH', `/admin/${projectId}/booking/${bookingId}`, {}, { isPaid, isAttending });
    },
  });

  const changeBooking = (eventId: string, bookingId: number, isAttending: boolean, isPaid: boolean) => {
    sendData.mutate({ eventId, bookingId, isAttending, isPaid });
  };

  return {
    isLoading: sendData.isPending,
    changeBooking,
  };
};

export default useUpdateBooking;
