import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Collapse, Divider, IconButton, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useQuery } from '@tanstack/react-query';
import SearchBar from 'components/searchBar/SearchBar';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getUserAddBookingCheckQuery } from 'queries/admin-users/admin-add-booking-check';
import useAddUserBooking from 'queries/admin-users/useAddUserBooking';
import { UserTicket, getAdminUserTicketsQuery } from 'queries/admin-users/admin-user-tickets';
import React, { useState } from 'react';
import CheckOverview from '../../pages/admin/users/components/CheckOverview';
import EventsOverview from '../../pages/admin/users/containers/EventsOverviewContainer';
import GeneralSettings from '../../pages/admin/users/containers/GeneralSettingsContainer';
import ChecklistContainer from '../../pages/admin/users/containers/booking/addBooking/ChecklistContainer';
import SubpageModal from '/components/subpageModal/SubpageModal';

export type User = {
  userId: number;
  name: string;
};

interface AddUserModalProps {
  eventId: number;
  isOpen: boolean;
  closeModal: () => void;
}

const AddUserModalContainer: React.FC<AddUserModalProps> = ({ eventId, isOpen, closeModal }) => {
  const isMobile = useIsMobile();

  const [isPaid, setIsPaid] = useState(false);
  const [isAttending, setIsAttending] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const projectId = useProjectId();
  const { data: bookedEvents } = useQuery(getAdminUserTicketsQuery({ projectId, userId: selectedUser?.userId || 0 }));
  const { data: eventCheck } = useQuery(
    getUserAddBookingCheckQuery({ projectId, userId: selectedUser?.userId || 0, eventId })
  );

  const handleCloseModal = () => {
    setIsPaid(false);
    setIsAttending(false);
    setIsExpanded(false);
    setSelectedUser(null);
    closeModal();
  };

  const addUser = useAddUserBooking(handleCloseModal);

  /*   const handleEventChange = (eventId: number) => {
		setSelectedEvent(events?.find((event: UserEvent) => event.eventId === eventId) || null);
	}; */

  const handleUserSelect = (id: number) => {
    setSelectedUser({ userId: id, name: '' });
  };

  const handleSubmit = () => {
    addUser.mutate({
      projectId,
      userId: selectedUser?.userId || 0,
      eventId,
      isPaid,
      isAttending,
    });
  };

  const returnSuccessfullCount = () => {
    if (!eventCheck) return 0;

    return (
      (eventCheck.isFull.isOk ? 1 : 0) +
      (eventCheck.isOverlapping.isOk ? 1 : 0) +
      (eventCheck.isFiltered.isOk ? 1 : 0) +
      (eventCheck.isMaxGroups.isOk ? 1 : 0)
    );
  };

  const checkUserAlreadyBooked = () => {
    if (!bookedEvents) return false;
    return bookedEvents.some((event: UserTicket) => event.eventId === eventId);
  };

  return (
    <>
      <SubpageModal
        hideHeader={!isMobile}
        title="Add User to Event"
        maxWidth={918}
        isOpen={isOpen}
        reset={() => null}
        close={handleCloseModal}
        hideReset
        variant="thick"
      >
        <div style={{ display: 'flex', marginTop: '20px', marginBottom: '22px' }}>
          <div style={{ flex: 1 }}>
            <Typography variant={isMobile ? 'h6' : 'h5'}>Add a User</Typography>
            <Typography variant="body2" color="textSecondary">
              Search for a user and add to event.
            </Typography>
          </div>
          {!isMobile && (
            <IconButton onClick={handleCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
        <SearchBar setSelected={handleUserSelect} projectId={projectId} placeholder="Search User" />

        <GeneralSettings userId={selectedUser?.userId as number} />
        {selectedUser?.userId !== 0 && eventCheck && (
          <>
            <EventsOverview userId={selectedUser?.userId as number} />
            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
            <CheckOverview
              isMobile={isMobile}
              success={returnSuccessfullCount() === 4}
              setExpanded={() => setIsExpanded(!isExpanded)}
              isExpanded={isExpanded}
              description={`${returnSuccessfullCount()}/4 successful`}
            />
            <Collapse in={isMobile ? isExpanded : true} timeout="auto">
              {isMobile && <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />}
              <ChecklistContainer checks={eventCheck} />
            </Collapse>
            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
            <Typography variant="h6">Status</Typography>
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isPaid}
                  name="checkedA"
                  color="secondary"
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsPaid(!isPaid)}
                />
              }
              label={
                <Typography variant="body2" style={{ display: 'inline' }}>
                  Mark as paid
                </Typography>
              }
            />
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isAttending}
                  name="checkedA"
                  color="secondary"
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsAttending(!isAttending)}
                />
              }
              label={
                <Typography variant="body2" style={{ display: 'inline' }}>
                  Mark as attending
                </Typography>
              }
            />
            <Button
              fullWidth
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              disabled={checkUserAlreadyBooked() || addUser.isPending}
              style={{ marginTop: '16px', marginBottom: '25px' }}
            >
              {checkUserAlreadyBooked() ? 'User already booked' : 'Confirm'}
            </Button>
          </>
        )}
      </SubpageModal>
    </>
  );
};

export default AddUserModalContainer;
