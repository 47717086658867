import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EventGroupEdit from './sub-pages/edit/EventGroupEdit';
import GroupsOverview from './sub-pages/overview/GroupsOverview';

const Groups = () => {
  return (
    <Routes>
      <Route path="create" element={<EventGroupEdit />} />
      <Route path="edit/:eventGroupId" element={<EventGroupEdit />} />
      <Route path="*" element={<GroupsOverview />} />
    </Routes>
  );
};

export default Groups;
