import { CircularProgress, Table, TableBody } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FabOrButton from 'components/fabOrButton/FabOrButton';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import GroupDeleteDialog from 'pages/admin/groups/components/GroupDeleteDialog';
import { getAdminEventGroupQuery } from 'queries/admin-events/admin-event-group';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import EventGroupsCard from '../components/EventGroupsCard';
import EventGroupsHead from '../components/EventGroupsHead';
import EventGroupsRow from '../components/EventGroupsRow';
import useDeleteGroup from '../hooks/useDeleteGroup';
import { EventGroupRow } from '../types/events';

const EventGroupsList: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const projectId = useProjectId();
  const eventGroups = useQuery(getAdminEventGroupQuery({ projectId }));
  const deleteGroup = useDeleteGroup({});

  const handleEdit = (id: number) => {
    navigate(`edit/${id}`);
  };

  const handleDelete = (id: number, name: string) => {
    deleteGroup.handleDeleteGroup(id, name);
  };

  const mappedGroups: EventGroupRow[] = useMemo((): EventGroupRow[] => {
    if (!eventGroups.data) return [];
    return eventGroups.data.map((group) => {
      return {
        id: group.id,
        name: group.name,
        appliedEvents: group.eventIDs.length,
        ticketLimit: group.ticketLimit,
        isDisplaying: group.isBroadcasted,
        onEdit: () => handleEdit(group.id),
        onDelete: () => handleDelete(group.id, group.name),
      };
    });
  }, [eventGroups.data]);

  if (!eventGroups.data) return <CircularProgress color="secondary" />;

  return (
    <>
      <GroupDeleteDialog
        name={deleteGroup.deleteGroupName}
        confirmOnClick={deleteGroup.handleConfirmDeleteGroup}
        cancelOnClick={deleteGroup.handleCancelDeleteGroup}
        isOpen={deleteGroup.deleteDialogOpen}
        buttonDisable={deleteGroup.isLoading}
      />
      {isMobile ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingTop: '8px' }}>
          {mappedGroups.map((group) => (
            <EventGroupsCard key={group.id} {...group} />
          ))}
        </div>
      ) : (
        <Table>
          <EventGroupsHead />
          <TableBody>
            {mappedGroups.map((group) => (
              <EventGroupsRow key={group.id} {...group} />
            ))}
          </TableBody>
        </Table>
      )}
      <FabOrButton onClick={() => navigate('create')} />
    </>
  );
};

export default EventGroupsList;
