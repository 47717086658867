import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import { useMutation } from '@tanstack/react-query';

const useDeleteEventGroup = (onSuccess: () => Promise<void>) => {
  const projectId = useProjectId();
  return useMutation({
    mutationFn: async (deleteGroupId: number | null) => {
      if (!deleteGroupId) return;
      await Backend.request('DELETE', `admin/${projectId}/event-group/${deleteGroupId}`);
    },
    onSuccess: onSuccess,
  });
};

export default useDeleteEventGroup;
