import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { StudyProgramRow } from '../types/types';

interface CardLabelProps {
  label: string;
}

const CardLabel: React.FC<CardLabelProps> = ({ label }) => {
  return (
    <div>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
    </div>
  );
};

const ProgramCard: React.FC<StudyProgramRow> = ({ studyOption, selected, onClick }) => {
  const theme = useDynamicTheme();
  return (
    <Card>
      <CardActionArea
        onClick={onClick}
        style={{
          backgroundColor: selected ? `${theme.palette.secondary.main}${theme.palette.overlay.opacity.selected}` : '',
        }}
      >
        <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              color="textPrimary"
              variant="h6"
              noWrap
              aria-label="Event group filter"
              style={{
                flex: 1,
                minWidth: '0px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {studyOption.nameEn}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CardLabel label={studyOption.typeEn} />
            <CardLabel label={studyOption.chapter} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CardLabel label={studyOption.abbreviation} />
            <CardLabel label={studyOption.school} />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProgramCard;
