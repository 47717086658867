import * as Backend from 'queries/BackendRequest';
import { UserEvent } from 'types/types';

interface ProjectIdProps {
  projectId: string;
}

const getAdminEvents = async ({ projectId }: ProjectIdProps): Promise<UserEvent[]> => {
  const resp = await Backend.request<UserEvent[]>('GET', `/admin/${projectId}/events`);
  return resp?.data?.data;
};

const getAdminEventsQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEvents'],
  queryFn: () => getAdminEvents({ projectId }),
  enabled: !!projectId,
});

export default getAdminEventsQuery;
