import React from 'react';
import { Chip, Typography } from '@mui/material';

interface CategoryChipsProps {
  data: string[];
}

/**
 * Displays chips and their data
 * expects a array of strings
 */
const CategoryChips: React.FC<CategoryChipsProps> = ({ data }) => {
  if (data.length === 0) {
    return <Typography variant="body2">No categories found for this user</Typography>;
  }

  return (
    <div>
      <Typography variant="body2" style={{ marginBottom: '8px' }}>
        Categories
      </Typography>
      {data.map((chip) => (
        <Chip
          key={`chip-${chip}`}
          label={<Typography variant="body2">{chip}</Typography>}
          style={{ marginRight: '8px', marginBottom: '8px' }}
        />
      ))}
    </div>
  );
};

export default CategoryChips;
