/* eslint-disable max-lines */
import {
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { $createCodeNode, CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { $isListNode, ListItemNode, ListNode, insertList } from '@lexical/list';
import { $convertFromMarkdownString, $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import { $createHeadingNode, $createQuoteNode, $isHeadingNode, HeadingNode, QuoteNode } from '@lexical/rich-text';

import React, { useEffect } from 'react';

import { $setBlocksType } from '@lexical/selection';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  EditorState,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  TextFormatType,
  UNDO_COMMAND,
} from 'lexical';
import './TextEditor.css';
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  Redo,
  Segment,
  Title,
  Undo,
} from '@mui/icons-material';

const EDITOR_NODES = [CodeNode, HeadingNode, LinkNode, ListNode, ListItemNode, QuoteNode];

const InitialReceivePlugin: React.FC<{ initialText: string }> = ({ initialText }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      $convertFromMarkdownString(initialText, TRANSFORMERS);
    });
  }, [editor]);

  return <></>;
};

const HeadingPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext();
  const [formats, setFormats] = React.useState<TextFormatType[]>([]);

  useEffect(() => {
    const updateListener = () => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const activeFormats: TextFormatType[] = [];
          const isBold = selection.hasFormat('bold' as never);
          const isItalic = selection.hasFormat('italic' as never);

          if (isBold) activeFormats.push('bold');
          if (isItalic) activeFormats.push('italic');

          setFormats(activeFormats);
        }
      });
    };

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(updateListener);
    });
  }, [editor]);

  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: TextFormatType[]) => {
    let changedElements: TextFormatType[] = newFormats.filter((x) => !formats.includes(x));
    if (changedElements.length === 0) {
      changedElements = formats.filter((x) => !newFormats.includes(x));
    }

    editor.update(() => {
      const selection = $getSelection();
      if (!selection) return;
      if ($isRangeSelection(selection)) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, changedElements[0]);
      }
    });
  };

  return (
    <>
      <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting">
        <ToggleButton value="bold" aria-label="bold">
          <FormatBold />
        </ToggleButton>
        <ToggleButton value="italic" aria-label="italic">
          <FormatItalic />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

const BlockPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = React.useState('paragraph');

  useEffect(() => {
    const updateListener = () => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode();
          const topNode = anchorNode.getTopLevelElementOrThrow();
          const parentType = topNode.getType();
          if (parentType !== 'heading' && parentType !== 'list') {
            setBlockType(parentType);
          } else if (parentType === 'heading' && $isHeadingNode(topNode)) {
            setBlockType(`heading${topNode.getTag().slice(-1)}`);
          } else if (parentType === 'list' && $isListNode(topNode)) {
            setBlockType(topNode.getTag());
          }
        }
      });
    };

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(updateListener);
    });
  }, [editor]);

  const handleBlockTypeChange = (event: SelectChangeEvent) => {
    const newBlockType = event.target.value;
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        setBlockType(newBlockType);
        switch (newBlockType) {
          case 'heading1':
            $setBlocksType(selection, () => $createHeadingNode('h1'));
            break;
          case 'heading2':
            $setBlocksType(selection, () => $createHeadingNode('h2'));
            break;
          case 'heading3':
            $setBlocksType(selection, () => $createHeadingNode('h3'));
            break;
          case 'heading4':
            $setBlocksType(selection, () => $createHeadingNode('h4'));
            break;
          case 'heading5':
            $setBlocksType(selection, () => $createHeadingNode('h5'));
            break;
          case 'heading6':
            $setBlocksType(selection, () => $createHeadingNode('h6'));
            break;
          case 'code':
            $setBlocksType(selection, $createCodeNode);
            break;
          case 'quote':
            $setBlocksType(selection, $createQuoteNode);
            break;
          case 'ol':
            insertList(editor, 'number');
            break;
          case 'ul':
            insertList(editor, 'bullet');
            break;
          default:
            $setBlocksType(selection, $createParagraphNode);
        }
      }
    });
  };

  return (
    <>
      <Select
        size="small"
        value={blockType}
        onChange={handleBlockTypeChange}
        displayEmpty
        inputProps={{ 'aria-label': 'block type' }}
        style={{ marginLeft: '8px', transform: 'translateY(-2px)' }}
      >
        <MenuItem value="paragraph">
          <Segment /> Paragraph
        </MenuItem>
        <MenuItem value="heading1">
          <Title /> Heading 1
        </MenuItem>
        <MenuItem value="heading2">
          <Title /> Heading 2
        </MenuItem>
        <MenuItem value="heading3">
          <Title /> Heading 3
        </MenuItem>
        <MenuItem value="heading4">
          <Title /> Heading 4
        </MenuItem>
        <MenuItem value="heading5">
          <Title /> Heading 5
        </MenuItem>
        <MenuItem value="heading6">
          <Title /> Heading 6
        </MenuItem>
        <MenuItem value="code">
          <Code /> Code Block
        </MenuItem>
        <MenuItem value="quote">
          <FormatQuote /> Quote Block
        </MenuItem>
        <MenuItem value="ul" aria-label="unordered-list">
          <FormatListBulleted /> Bullet List
        </MenuItem>
        <MenuItem value="ol" aria-label="numbered-list">
          <FormatListNumbered /> Numbered List
        </MenuItem>
      </Select>
    </>
  );
};

const HistoryButtons: React.FC = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <>
      <ButtonGroup size="large" style={{ marginLeft: '8px', height: '49px' }} color="inherit">
        <Button style={{ maxWidth: '60px' }} onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}>
          <Undo />
        </Button>
        <Button style={{ maxWidth: '60px' }} onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}>
          <Redo />
        </Button>
      </ButtonGroup>
    </>
  );
};

interface TextEditorProps {
  initialText: string;
  setText: (text: string) => void;
}

const theme = {
  root: 'editor-root',
  text: {
    bold: 'textBold',
    italic: 'textItalic',
    underline: 'textUnderline',
  },
};

const TextEditor: React.FC<TextEditorProps> = ({ initialText, setText }) => {
  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    nodes: EDITOR_NODES,
    // eslint-disable-next-line no-console
    onError: (e: any) => console.error(e),
  };

  const onChange = (editor: EditorState) => {
    editor.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      setText(markdown);
    });
  };

  return (
    <section style={{ padding: '8px' }}>
      <Typography component="span">
        <LexicalComposer initialConfig={initialConfig}>
          <HeadingPlugin />
          <BlockPlugin />
          <HistoryButtons />
          <RichTextPlugin
            contentEditable={<ContentEditable />}
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={null}
          />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <HistoryPlugin />
          <OnChangePlugin onChange={onChange} />
          <InitialReceivePlugin initialText={initialText} />
        </LexicalComposer>
      </Typography>
    </section>
  );
};
export default TextEditor;
