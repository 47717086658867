import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import deepEqual from 'utils/equals';

type Attended = 'attended' | 'notAttended';

export interface BookingSearchFilter {
  startDate?: string;
  endDate?: string;
  startPrice?: number;
  endPrice?: number;
  attended: Attended[];
}

interface FormValues {
  startDate?: string;
  endDate?: string;
  startPrice?: number;
  endPrice?: number;
  attended?: boolean;
  notAttended?: boolean;
}

export const useSearchFilter = () => {
  const { control, watch, reset } = useForm<FormValues>({ mode: 'onSubmit' });
  const watchFields = watch();
  const [eventNameSearch, setEventNameSearch] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<BookingSearchFilter>({
    attended: [],
  });

  const handleSearchFilterChange = (filter: BookingSearchFilter) => {
    setSearchFilter(filter);
  };

  /**
   * pushes to change filter
   */
  const changeFilter = () => {
    const attended: Attended[] = [];
    if (watchFields.attended) attended.push('attended');
    if (watchFields.notAttended) attended.push('notAttended');
    let startDatetime;
    let endDatetime;

    if (watchFields.startDate) {
      startDatetime = new Date(Date.parse(watchFields.startDate));
      startDatetime.setHours(0, 0, 0, 0);
    }

    if (watchFields.endDate) {
      endDatetime = new Date(Date.parse(watchFields.endDate));
      endDatetime.setHours(0, 0, 0, 0);
    }

    const filter = {
      startDate: startDatetime ? new Date(startDatetime).toJSON() : undefined,
      endDate: endDatetime ? new Date(endDatetime).toJSON() : undefined,
      startPrice: watchFields.startPrice || undefined,
      endPrice: watchFields.endPrice || undefined,
      attended: attended || [],
    };

    if (!deepEqual(filter, searchFilter)) {
      handleSearchFilterChange(filter);
    }
  };

  const handleReset = () => {
    reset({
      startDate: undefined,
      endDate: undefined,
      startPrice: undefined,
      endPrice: undefined,
      attended: false,
      notAttended: false,
    });
  };

  /**
   * Set events on change
   */
  useEffect(() => {
    changeFilter();
  }, [watchFields]);

  return { control, searchFilter, changeFilter, handleReset, eventNameSearch, setEventNameSearch };
};
