import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const iconStyle: React.CSSProperties = {
  width: '18px',
  height: '16px',
};

type OptionButton = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

const DesktopButton = ({ icon, label, onClick, disabled }: OptionButton) => (
  <Button color="secondary" style={{ padding: 0, textTransform: 'capitalize' }} onClick={onClick} disabled={disabled}>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {icon}
      <Typography variant="caption">{label}</Typography>
    </div>
  </Button>
);

type MobileMenuProps = {
  items: OptionButton[];
};

const MobileMenu = ({ items }: MobileMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: '6px' }}
        color="secondary"
        size="large"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {items.map((item) => (
          <MenuItem
            key={`admin-event-card-${item.label}`}
            onClick={item.onClick}
            color="secondary"
            disabled={item.disabled}
          >
            <ListItemIcon color="secondary">{item.icon}</ListItemIcon>
            <Typography variant="inherit">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

type EventAdminButtonProps = {
  isMobile: boolean;
  onManageClick: () => void;
  onEditClick: () => void;
  onTransferClick: () => void;
  onAddUserClick: () => void;
};

const EventAdminButton = ({
  isMobile,
  onManageClick,
  onEditClick,
  onTransferClick,
  onAddUserClick,
}: EventAdminButtonProps) => {
  const items: OptionButton[] = [
    {
      icon: <SyncAltIcon style={iconStyle} />,
      label: 'Transfer',
      onClick: onTransferClick,
      disabled: true,
    },
    {
      icon: <PersonAddOutlinedIcon style={iconStyle} />,
      label: 'Add user',
      onClick: onAddUserClick,
      disabled: false,
    },
  ];

  return (
    <>
      <Divider style={{ marginBottom: isMobile ? '8px' : '16px', marginTop: '8px' }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: !isMobile ? '8px' : undefined,
        }}
      >
        <div style={{ gap: '16px' }}>
          <Button variant="contained" color="secondary" onClick={onManageClick}>
            Manage
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            style={{ width: '97px', marginLeft: '16px' }}
            onClick={onEditClick}
          >
            Edit
          </Button>
        </div>
        <div style={{ display: 'flex', gap: '40px' }}>
          {isMobile ? (
            <MobileMenu items={items} />
          ) : (
            items.map((item) => (
              <div key={`desktop-button-${item.label}`}>
                <DesktopButton icon={item.icon} label={item.label} onClick={item.onClick} disabled={item.disabled} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default EventAdminButton;
