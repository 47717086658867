import { CircularProgress } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import ManageEventHeader from './components/ManageEventHeader';
import BookedUsers from './containers/BookedUsers';
import EventDisplay from './containers/EventDisplay';
import useGetEventDetails from './hooks/useGetEventDetails';
import OpenEventEditSubMenu from '/containers/subMenu/OpenEventEditSubMenu';

const ManageEvent: React.FC = () => {
  const { id } = useParams();
  const eventDetails = useGetEventDetails(id as string);

  if (eventDetails.isLoading || eventDetails.isError || !eventDetails.data || !eventDetails.stats)
    return <CircularProgress color="secondary" />;

  return (
    <>
      <OpenEventEditSubMenu />
      <ManageEventHeader />
      <EventDisplay event={eventDetails.data} stats={eventDetails.stats} />
      <BookedUsers eventData={eventDetails.data} />
    </>
  );
};

export default ManageEvent;
