import React from 'react';
import OpenEventEditSubMenu from 'containers/subMenu/OpenEventEditSubMenu';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Divider, IconButton, TextField, Typography } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import { CheckboxEventList } from 'components/adminCheckboxEventList/CheckboxEventList';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MobileEditFab from 'components/mobileEditFab/MobileEditFab';
import useEditGroup from './hooks/useEditGroup';
import { ApplyEventsContainer, CheckboxContainer } from './components/Containers';
import CheckboxForm from './components/CheckboxForm';
import CheckboxSubtitle from './components/CheckboxSubtitle';
import useDeleteGroup from '../overview/hooks/useDeleteGroup';
import GroupDeleteDialog from '../../components/GroupDeleteDialog';

const EventGroupEdit = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const editGroup = useEditGroup();
  const deleteGroup = useDeleteGroup({
    afterSuccess: () => {
      navigate(-1);
    },
  });

  const openDeleteDialog = () => {
    deleteGroup.handleDeleteGroup(parseInt(editGroup.eventGroupId as string, 10), editGroup.groupName);
  };

  if (!editGroup.eventsData || !editGroup.groupsData) return <CircularProgress color="secondary" />;

  return (
    <>
      <GroupDeleteDialog
        name={deleteGroup.deleteGroupName}
        confirmOnClick={deleteGroup.handleConfirmDeleteGroup}
        cancelOnClick={deleteGroup.handleCancelDeleteGroup}
        isOpen={deleteGroup.deleteDialogOpen}
        buttonDisable={deleteGroup.isLoading}
      />
      <OpenEventEditSubMenu
        handleBack={() => navigate(-1)}
        rightIcon={
          !editGroup.isCreationMode && (
            <IconButton color="secondary" onClick={openDeleteDialog}>
              <DeleteOutlineIcon />
            </IconButton>
          )
        }
      />
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <TextField
          label="Event Group Name"
          color="secondary"
          value={editGroup.groupName}
          onChange={editGroup.handleGroupNameChange}
          error={editGroup.errors.name.length > 0}
          helperText={editGroup.errors.name}
          fullWidth
          disabled={!editGroup.editing}
        />
        {!isMobile && (
          <>
            {!editGroup.isCreationMode && (
              <Button color="secondary" style={{ height: '37px', width: '87px' }} onClick={openDeleteDialog}>
                Delete
              </Button>
            )}
            <Button
              color={editGroup.editing ? 'success' : 'secondary'}
              variant="contained"
              style={{ height: '37px', width: '96px' }}
              onClick={editGroup.handleEditing}
            >
              {editGroup.editing ? 'Save' : 'Edit'}
            </Button>
          </>
        )}
      </div>
      <Divider style={{ padding: '8px 0px' }} />
      <ApplyEventsContainer isMobile={isMobile}>
        <div style={{ flex: 0.6, marginBottom: '8px' }}>
          <Typography variant="h6">Applied Events</Typography>
          <Typography variant="body2">Select events the group applies to</Typography>
        </div>
        <TextField
          label="Search Events"
          value={editGroup.eventSearch}
          onChange={editGroup.handleEventSearchChange}
          color="secondary"
          style={{ flex: 0.4 }}
        />
      </ApplyEventsContainer>
      <CheckboxEventList
        checked={editGroup.selectedEvents}
        events={editGroup.eventsData
          .filter((event) =>
            editGroup.eventSearch.length > 3
              ? event.displayTitle.toLowerCase().includes(editGroup.eventSearch.toLowerCase())
              : event
          )
          .map((event) => ({
            id: event.eventId,
            name: event.displayTitle,
            date: new Date(event.startDatetime),
          }))}
        onClick={(id: number) => editGroup.handleEventSelect(id)}
        isMobile={isMobile}
        isEditing={editGroup.editing}
      />

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '16px 0px' }}>
        <Typography variant="h6">Settings</Typography>
        <CheckboxContainer>
          <CheckboxForm
            checked={editGroup.isBroadcasted}
            onChange={editGroup.handleBroadcastChange}
            disabled={!editGroup.editing}
            label="Display event group to users."
          />
          <CheckboxSubtitle text={`Shown as "Event Category" on the Booking page.`} />
        </CheckboxContainer>

        <CheckboxContainer>
          <CheckboxForm
            checked={editGroup.hasTicketLimit}
            onChange={editGroup.handleTicketLimitChange}
            disabled={!editGroup.editing}
            label="Limit the amount of tickets."
          />
          <CheckboxSubtitle text="Does not apply to bookings made through the admin pages." />
          <TextField
            type="number"
            style={{ maxWidth: '185px', width: '100%', marginLeft: '35px', marginTop: '8px' }}
            label="Ticket Limit"
            color="secondary"
            disabled={!editGroup.hasTicketLimit || !editGroup.editing}
            value={editGroup.ticketLimit}
            onChange={editGroup.handleTicketLimitNumberChange}
            error={editGroup.errors.ticketLimit.length > 0}
            helperText={editGroup.errors.ticketLimit}
          />
        </CheckboxContainer>
        <MobileEditFab isEditing={editGroup.editing} onClick={editGroup.handleEditing} />
      </div>
    </>
  );
};

export default EventGroupEdit;
