import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material';
import React from 'react';

const iconMobile: React.CSSProperties = {
  marginRight: '8px',
  height: '38px',
  width: '44px',
};

const ColoredWarningIcon = styled(WarningIcon)(({ theme }) => ({
  ...iconMobile,
  color: theme.palette.warning.main,
}));

const ColoredCheckIcon = styled(CheckIcon)(({ theme }) => ({
  ...iconMobile,
  color: theme.palette.success.main,
}));

interface CheckOverviewProps {
  success: boolean;
  setExpanded: () => void;
  isExpanded: boolean;
  description: string;
  isMobile: boolean;
}

const CheckOverview: React.FC<CheckOverviewProps> = ({ success, setExpanded, isExpanded, description, isMobile }) => {
  return (
    <div style={{ display: 'flex', marginBottom: !isMobile ? '16px' : '' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {success ? <ColoredCheckIcon /> : <ColoredWarningIcon />}
        <div>
          <Typography variant="h6">{success ? 'Checks successful' : 'Checks failed'}</Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </div>
      </div>
      {isMobile && (
        <IconButton
          style={{ position: 'relative', top: '0px', padding: '0px', height: '27px', marginLeft: 'auto' }}
          color="secondary"
          onClick={setExpanded}
          size="large"
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}
    </div>
  );
};

export default CheckOverview;
