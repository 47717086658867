import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, InputAdornment, TextField } from '@mui/material';

interface Props {
  label: string;
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const ClearableTextField: React.FC<Props> = ({ label, placeholder = '', value, setValue, style, disabled }) => (
  <TextField
    fullWidth
    color="secondary"
    disabled={disabled}
    variant="outlined"
    label={label || ''}
    placeholder={placeholder}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    style={{ ...style }}
    InputProps={{
      endAdornment: value ? (
        <InputAdornment position="end">
          <IconButton onClick={() => setValue('')} edge="end" size="large">
            <CancelIcon />
          </IconButton>
        </InputAdornment>
      ) : null,
    }}
  />
);

export default ClearableTextField;
