import React from 'react';
import { Typography, Divider } from '@mui/material';

import useDynamicTheme from 'hooks/useDynamicTheme';
import { Variant } from '@mui/material/styles/createTypography';

export const AdminHeader = ({ isMobile = false, title = '', subtitle = '', disableDivider = false, small = false }) => {
  const theme = useDynamicTheme();

  const renderVariant = (mobileVariant: Variant, desktopVariant: Variant) => {
    return isMobile ? mobileVariant : desktopVariant;
  };

  return (
    <>
      <Typography
        variant={small ? 'h6' : renderVariant('h5', 'h4')}
        style={isMobile && !small ? { marginTop: '16px', marginBottom: '10px' } : undefined}
      >
        {title}
      </Typography>
      <Typography
        variant={renderVariant('body2', 'body1')}
        style={{
          marginTop: 0,
          marginBottom: '12px',
          color: `${theme.palette.surface.text}${theme.palette.other.opacityMedium}`,
        }}
      >
        {subtitle}
      </Typography>
      {!disableDivider && <Divider style={{ marginBottom: '16px' }} />}
    </>
  );
};

export default AdminHeader;
