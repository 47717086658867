import { ThemeColors } from 'types/types';
import * as Backend from '/queries/BackendRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import useDynamicTheme from 'hooks/useDynamicTheme';

const useChangeColors = () => {
  const queryClient = useQueryClient();
  const feedback = useOpenFeedback();
  const projectId = useProjectId();
  const theme = useDynamicTheme();

  return useMutation({
    mutationFn: async (data: ThemeColors) => {
      await Backend.request('PUT', `/admin/${projectId}/theme/colors`, {}, data);
    },
    onSuccess: (r, sentData) => {
      theme.palette = { ...theme.palette, ...sentData };
      feedback.openSuccess('Your changes have been saved!');
      queryClient.invalidateQueries({ queryKey: ['getTheme'] });
    },
  });
};

export default useChangeColors;
