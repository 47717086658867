import React from 'react';
import { useParams } from 'react-router-dom';
import BookingsContainer from './booking/BookingsContainer';
import EventsOverview from './EventsOverviewContainer';
import GeneralSettings from './GeneralSettingsContainer';

const DisplayUserContainer = () => {
  const { userId } = useParams();

  const parsedUser = userId ? parseInt(userId, 10) : 0;

  return (
    <>
      <GeneralSettings userId={parsedUser} />
      <EventsOverview userId={parsedUser} />
      <BookingsContainer userId={parsedUser} />
    </>
  );
};

export default DisplayUserContainer;
