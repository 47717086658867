import { TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import React from 'react';

const EventGroupsHead = () => {
  return (
    <TableHead>
      <TableRow>
        <CustomTableCell>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Group Name</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Applied Events</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Ticket Limit</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Displayed?</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1" style={{ paddingRight: '4px' }}>
              Actions
            </Typography>
          </TableSortLabel>
        </CustomTableCell>
      </TableRow>
    </TableHead>
  );
};

export default EventGroupsHead;
