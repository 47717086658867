import * as Backend from 'queries/BackendRequest';
import { EventBookingStats } from './types';

interface ProjectIdProps {
  projectId: string;
}

const getAdminEventStats = async ({ projectId }: ProjectIdProps): Promise<EventBookingStats[]> => {
  const resp = await Backend.request<EventBookingStats[]>('GET', `/admin/${projectId}/event/bookingstats`);
  if (!resp?.data?.data) {
    return [];
  }
  return resp.data.data;
};

const getAdminEventStatsQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEventStats'],
  queryFn: () => getAdminEventStats({ projectId }),
  enabled: !!projectId,
});

export default getAdminEventStatsQuery;
