interface Event {
  id: number;
  name: string;
  date: Date;
}

/**
 * sorts events by date
 *
 * @param {array} events list of events
 * @returns sorted events
 */
export const sortEventsByDate = (events: Array<Event>) => {
  return events.sort((a, b) => b.date.getTime() - a.date.getTime());
};

/**
 * sorts events by their checked status
 *
 * @param {array} events list of events
 * @param {array} checked checked list
 * @returns sorted events
 */
export const sortEventsByChecked = (events: Array<Event>, checked: Array<number>) => {
  return events.sort((a, b) => {
    if (checked.includes(a.id) && !checked.includes(b.id)) {
      return -1;
    }
    if (!checked.includes(a.id) && checked.includes(b.id)) {
      return 1;
    }
    return 0;
  });
};
