import React from 'react';
import { Typography } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import useNameEmailStyles from './NameEmail.styles';

export interface NameEmailProps {
  checkedIn?: React.ReactNode;
  firstName: string;
  lastName: string;
  email: string;
  copyToClipboard: (email: string) => void;
  style?: React.CSSProperties;
}

const NameEmail: React.FC<NameEmailProps> = ({ firstName, lastName, email, copyToClipboard, style, checkedIn }) => {
  const classes = useNameEmailStyles();

  return (
    <div className={classes.root} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Typography variant="h6" className={classes.name}>
          {firstName} {lastName}
        </Typography>
        {checkedIn}
      </div>
      <div className={classes.emailIconContainer}>
        <Typography variant="caption" color="textSecondary">
          {email}
        </Typography>
        <FileCopyOutlinedIcon className={classes.emailIcon} onClick={() => copyToClipboard(email)} />
      </div>
    </div>
  );
};

export default NameEmail;
