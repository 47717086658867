import Dialog from 'components/dialog/Dialog';
import React from 'react';

interface Props {
  text: string;
  confirmOnClick: () => void;
  cancelOnClick: () => void;
  isOpen: boolean;
  buttonDisable: boolean;
}

const DeleteDialog: React.FC<Props> = ({ text, confirmOnClick, cancelOnClick, isOpen, buttonDisable }) => (
  <Dialog
    title=""
    question={text}
    buttonLabel="DELETE"
    confirmOnClick={confirmOnClick}
    cancelOnClick={cancelOnClick}
    isOpen={isOpen}
    buttonDisable={buttonDisable}
  />
);

export default DeleteDialog;
