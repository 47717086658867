/* eslint-disable react/destructuring-assignment */
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ImportAndExport from './ImportAndExport/ImportAndExport';
import Access from './access/Access';
import Customization from './customization/Customization';
import EventFilter from './eventFilter/Filters';
import AdminEventsPage from './events/Events';
import Groups from './groups/Groups';
import Users from './users/Users';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import TabBar from '/components/tabs/Tabs';

const Admin: React.FC = () => {
  const isMobile = useIsMobile();
  const projectId = useProjectId();

  const categories = {
    Users: `/${projectId}/admin/users`,
    Events: `/${projectId}/admin/events`,
    'Event Filters': `/${projectId}/admin/filters`,
    'Event Groups': `/${projectId}/admin/groups`,
    Customization: `/${projectId}/admin/customization`,
    'Import & Export': `/${projectId}/admin/importandexport`,
    'Access Management': `/${projectId}/admin/management`,
  };

  if (!projectId) return <></>;

  return (
    <>
      <TabBar tablist={categories} centeredUntil={undefined} />
      <Content sidebar={!isMobile ? categories : undefined}>
        <Routes>
          <Route path="/users/*" element={<Users />} />
          <Route path="/events/*" element={<AdminEventsPage />} />
          <Route path="/filters/*" element={<EventFilter />} />
          <Route path="/groups/*" element={<Groups />} />
          <Route path="/customization/*" element={<Customization />} />
          <Route path="/importandexport" element={<ImportAndExport />} />
          <Route path="/management/*" element={<Access />} />
          <Route path="*" element={<Navigate to="users" />} />
        </Routes>
      </Content>
      <Footer />
    </>
  );
};

export default Admin;
