import * as Backend from 'queries/BackendRequest';
import { EventFilter } from './types';

interface ProjectIdProps {
  projectId: string;
}

const getAdminEventFilter = async ({ projectId }: ProjectIdProps): Promise<EventFilter[]> => {
  const resp = await Backend.request<EventFilter[]>('GET', `/admin/${projectId}/event-filter`);
  return resp?.data?.data;
};

const getAdminEventFilterQuery = ({ projectId }: ProjectIdProps) => ({
  queryKey: ['getAdminEventFilter'],
  queryFn: () => getAdminEventFilter({ projectId }),
  enabled: !!projectId,
});

export default getAdminEventFilterQuery;
