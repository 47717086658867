import React from 'react';
import Typography from '@mui/material/Typography';

import Checkbox from '@mui/material/Checkbox';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useDynamicTheme from 'hooks/useDynamicTheme';

interface IconButtonProps {
  icon: React.ReactNode;
  label: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, label, disabled, onClick }) => {
  const theme = useDynamicTheme();

  return (
    <button
      type="button"
      style={{
        display: 'flex',
        border: 'none',
        backgroundColor: 'transparent',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: disabled ? `#000000${theme.palette.other.opacityDisabled}` : theme.palette.secondary.main,
        cursor: disabled ? 'default' : 'pointer',
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <Typography variant="caption">{label}</Typography>
    </button>
  );
};

interface BookingsOptionsProps {
  onClickPay: () => void;
  onClickTransfer: () => void;
  onClickDelete: () => void;
  selectAll?: boolean;
  onClickSelectAll?: () => void;
  isMobile?: boolean;
  isSelectedActive: boolean;
  clickTransferEnabled: boolean;
  areAllSelectedPaid: boolean;
}

const BookingsOptions: React.FC<BookingsOptionsProps> = ({
  onClickPay,
  onClickTransfer,
  onClickDelete,
  selectAll,
  onClickSelectAll,
  isMobile,
  isSelectedActive,
  clickTransferEnabled,
  areAllSelectedPaid,
}) => (
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
    {isMobile && (
      <IconButton
        label="Select All"
        icon={<Checkbox color="secondary" checked={selectAll} onChange={onClickSelectAll} style={{ padding: '0px' }} />}
      />
    )}
    <IconButton
      label={!isSelectedActive ? 'Pay' : areAllSelectedPaid ? 'Unpay' : 'Pay'}
      icon={<CreditCardIcon />}
      onClick={onClickPay}
      disabled={!isSelectedActive}
    />
    <IconButton label="Transfer" icon={<SwapHorizIcon />} onClick={onClickTransfer} disabled={!clickTransferEnabled} />
    <IconButton label="Delete" icon={<DeleteOutlineIcon />} onClick={onClickDelete} disabled={!isSelectedActive} />
  </div>
);

export default BookingsOptions;
