import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useDynamicTheme from 'hooks/useDynamicTheme';

interface Props {
  options: {
    onclick: () => void;
    label: string;
  }[];
}

const UserRoleMenu: React.FC<Props> = ({ options }) => {
  const theme = useDynamicTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ArrowDropDownIcon
        aria-controls="userRoleMenu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          width: 18,
          height: 18,
          opacity: 0.6,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      />
      <Menu id="userRoleMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={`option--${option.label}`}
            onClick={() => {
              option.onclick();
              handleClose();
            }}
            sx={{
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserRoleMenu;
