import React, { useState } from 'react';
import AddBookingButton from '../../../../users/containers/booking/addBooking/AddBookingButtonContainer';
import AddUserModalContainer from '../../../../../../components/eventAddUser/AddUserModalContainer';

interface AddUserContainerProps {
  eventId: number;
}

const AddUserContainer: React.FC<AddUserContainerProps> = ({ eventId }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddBookingButton openModal={() => setOpen(true)} userId={eventId} buttonText="Add User" />
      <AddUserModalContainer eventId={eventId} isOpen={open} closeModal={() => setOpen(false)} />
    </>
  );
};

export default AddUserContainer;
