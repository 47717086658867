import React from 'react';

import { Divider } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import EventCard from './EventCardBase';
import EventCategory from './EventCategory';
import EventDescription from './EventDescription';
import EventAdminContent from './EventAdminContent';
import EventAdminButton from './EventAdminButton';
import AdminEventCheck, { AdminEventCheckItemProps } from './AdminEventCheck';
import { buildEventsContentProps } from './utils';

export type AdminEventCardProps = {
  title: string;
  subtitle: string;
  currentEventBooked: number;
  currentEventAttended: number;
  maxEventAttended: number;
  paidPeople: number;
  isMobile: boolean;
  price: number;
  description: string;
  checks: AdminEventCheckItemProps[];
  categories: string[];
  filters: string[];
  onManageClick: () => void;
  onEditClick: () => void;
  onTransferClick: () => void;
  onAddUserClick: () => void;
};

export const AdminEventCard = ({
  title,
  subtitle,
  currentEventBooked,
  currentEventAttended,
  maxEventAttended,
  paidPeople,
  isMobile,
  price,
  description,
  checks,
  categories,
  filters,
  onManageClick,
  onEditClick,
  onTransferClick,
  onAddUserClick,
}: AdminEventCardProps) => {
  const theme = useDynamicTheme();
  const mediumBlack = `${theme.palette.surface.text}${theme.palette.other.opacityMedium}`;

  return (
    <EventCard
      title={title}
      subtitle={subtitle}
      isMobile={isMobile}
      statistics={
        <>
          <Divider style={{ marginBottom: '16px', marginTop: '8px' }} />
          <EventAdminContent
            data={buildEventsContentProps({
              currentEventBooked,
              currentEventAttended,
              maxEventAttended,
              paidPeople,
              price,
              isMobile,
            })}
            isMobile={isMobile}
          />
        </>
      }
      button={
        <EventAdminButton
          isMobile={isMobile}
          onManageClick={onManageClick}
          onEditClick={onEditClick}
          onTransferClick={onTransferClick}
          onAddUserClick={onAddUserClick}
        />
      }
    >
      <div style={{ marginTop: '16px' }} />
      <AdminEventCheck items={checks} />
      <Divider style={{ marginTop: '16px' }} />
      <EventDescription
        description={description}
        style={{
          color: mediumBlack,
        }}
      />
      <Divider style={{ marginTop: '16px' }} />
      <EventCategory isMobile={isMobile} categories={categories} />
      <Divider style={{ marginTop: '8px' }} />
      <EventCategory title="Event Filter" isMobile={isMobile} categories={filters} />
    </EventCard>
  );
};
