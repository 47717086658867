import * as Backend from 'queries/BackendRequest';

export interface UserTicket {
  bookingId: number;
  accountId: number;
  firstName: string;
  lastName: string;
  isPaid: boolean;
  isAttending: boolean;
  projectId: number;
  eventId: number;
  displayTitle: string;
  description: string;
  location: string;
  bookingStartDatetime: string;
  bookingEndDatetime: string;
  unbookingEndDatetime: string;
  swapEndDatetime: string;
  startDatetime: string;
  endDatetime: string;
  basePriceSek: number;
  attendanceLimit: number;
  eventGroups: number[];
}

interface GetUserTicketParams {
  projectId: string | number;
  userId: string | number;
}

const fetchUserTickets = async ({ projectId, userId }: GetUserTicketParams): Promise<UserTicket[]> => {
  const result = await Backend.request<UserTicket[]>('get', `/admin/${projectId}/user/tickets/${userId}`, {
    projectId,
  });
  return result.data.data;
};

export const getAdminUserTicketsQuery = ({ projectId, userId }: GetUserTicketParams) => ({
  queryKey: ['getAdminUserTicketsQuery', projectId, userId],
  queryFn: () => fetchUserTickets({ projectId, userId }),
  enabled: !!projectId && !!userId,
});

export default getAdminUserTicketsQuery;
