import useProjectId from 'hooks/useProjectId';
import * as Backend from '/queries/BackendRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';

const useSetPrivateText = (key: string, name: string) => {
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const feedback = useOpenFeedback();

  return useMutation({
    mutationFn: async (newText: string) => {
      await Backend.request(
        'PUT',
        `/admin/content/${projectId}/string`,
        {},
        {
          stringKey: key,
          content: newText,
        }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getPrivateText', projectId] });
      feedback.openSuccess(`${name} Successfully Updated`);
    },
    onError: () => {
      feedback.openError(`Failed to update ${name}`);
    },
  });
};

export default useSetPrivateText;
