import * as Backend from 'queries/BackendRequest';
import { UserEvent } from 'types/types';

interface IGetAdminEvent {
  projectId: string;
  eventId: string;
}

export const getAdminEvent = async ({ projectId, eventId }: IGetAdminEvent): Promise<UserEvent> => {
  const resp = await Backend.request<UserEvent>('GET', `/admin/${projectId}/event/${eventId}`);
  return resp.data.data;
};

export const getAdminEventQuery = ({ projectId, eventId }: IGetAdminEvent) => ({
  queryKey: ['getAdminEvent', eventId],
  queryFn: () => getAdminEvent({ projectId, eventId }),
  enabled: !!projectId,
});
