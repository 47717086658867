import ColorPickerField from 'components/colorPicker';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  control: Control<any, any>;
}

const ColorComponent: React.FC<Props> = ({ name, label, control }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => <ColorPickerField label={label} color={value} setColor={onChange} />}
    />
  );
};

export default ColorComponent;
