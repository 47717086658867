import { Card, CardContent, IconButton, Typography } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { EventGroupRow } from '../types/events';

interface CardStatsProps {
  label: string;
  value: React.ReactNode;
}

const CardStats: React.FC<CardStatsProps> = ({ label, value }) => {
  return (
    <div>
      <Typography variant="caption" color="textPrimary">
        {label}:{' '}
      </Typography>
      <Typography variant="caption" color="textSecondary" aria-label="number of events this group applies to">
        {value}
      </Typography>
    </div>
  );
};

const EventGroupsCard: React.FC<EventGroupRow> = ({ name, appliedEvents, ticketLimit, isDisplaying, onEdit }) => {
  return (
    <Card>
      <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            color="textPrimary"
            variant="h6"
            noWrap
            aria-label="Event group name"
            style={{
              flex: 1,
              minWidth: '0px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {name}
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <IconButton onClick={onEdit} aria-label="edit event group" size="small">
              <EditIcon color="secondary" fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardStats label="Events" value={appliedEvents} />
          <CardStats label="Limit" value={ticketLimit > 0 ? ticketLimit : 'None'} />
          <CardStats label="Displayed" value={isDisplaying ? 'Yes' : 'No'} />
        </div>
      </CardContent>
    </Card>
  );
};

export default EventGroupsCard;
