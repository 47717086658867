import React from 'react';
import { Button } from '@mui/material';

interface EventButtonsProps {
  onBack: () => void;
  onSubmit: () => void;
  isCreationMode?: boolean;
}

const EventEditButtons: React.FC<EventButtonsProps> = ({ onBack, onSubmit, isCreationMode }) => (
  <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginBottom: '16px' }}>
    <Button color="secondary" onClick={onBack}>
      Cancel
    </Button>
    <Button variant="contained" color="secondary" style={{ width: '100px' }} onClick={onSubmit}>
      {isCreationMode ? 'Create' : 'Save'}
    </Button>
  </div>
);

export default EventEditButtons;
