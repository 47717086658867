import React from 'react';

import { CheckTransferToUserResponse } from 'queries/admin-users/admin-ticket-transfer-check';
import CheckList from '../../../components/CheckList';

interface Props {
  checks?: CheckTransferToUserResponse;
  isMobile: boolean;
}

const TicketChecklistContainer: React.FC<Props> = ({ checks, isMobile }) => {
  const buildResponse = () => {
    if (checks) {
      return [
        {
          success: checks.isBeforeTransferTime.isOk,
          title: 'Transfer Ending Time',
          description: checks.isBeforeTransferTime.isOk
            ? 'User can transfer their ticket'
            : 'User cannot transfer their ticket anymore',
        },
        {
          success: checks.isOverlapping.isOk,
          title: 'Overlap',
          description: checks.isOverlapping.isOk
            ? 'Event does not overlap with other booked events'
            : `Event overlaps with booked ${checks.isOverlapping.details[0]} by the user`,
        },
        {
          success: checks.isFiltered.isOk,
          title: 'Event Filter',
          description: checks.isFiltered.isOk
            ? 'Event visible on booking page'
            : `${checks.isFiltered.details[0]} prevents visibility for user`,
        },
        {
          success: checks.isMaxGroups.isOk,
          title: 'Event Groups',
          description: checks.isMaxGroups.isOk
            ? 'No event group ticket limit for this event reached'
            : `User has reached ticket limit for ${checks.isMaxGroups.details[0]}`,
        },
      ];
    }

    return [];
  };

  return <CheckList isMobile={isMobile} items={buildResponse()} />;
};

export default TicketChecklistContainer;
