import React from 'react';
import { Divider } from '@mui/material';
import CustomizationCard from 'components/customizationCard/CustomizationCard';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { useNavigate } from 'react-router-dom';
import { AdminHeader } from '/components/adminHeader/AdminHeader';

const CustomizationOverview: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const projectId = useProjectId();
  return (
    <>
      <AdminHeader
        isMobile={isMobile}
        title="Customization"
        subtitle="Customize the website content and color theme to inform about your project and represent your brand."
        disableDivider
      />
      <Divider style={{ marginBottom: '8px', marginTop: '12px' }} />
      <CustomizationCard
        title="General"
        description="Edit the name and social media links for the project"
        isMobile={isMobile}
        onClick={() => {
          navigate(`/${projectId}/admin/customization/general`);
        }}
      />
      <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
      <CustomizationCard
        title="Theme"
        description="Edit the color theme and logo of the website."
        isMobile={isMobile}
        onClick={() => {
          navigate(`/${projectId}/admin/customization/theme`);
        }}
        disabled={false}
      />
      <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
      <CustomizationCard
        title="Pages"
        description="Edit the content of each page of the website including description text and pictures."
        isMobile={isMobile}
        onClick={() => {
          navigate(`/${projectId}/admin/customization/pages`);
        }}
        disabled={false}
      />
    </>
  );
};

export default CustomizationOverview;
