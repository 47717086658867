import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import getUserTicketsQuery from 'queries/event/user-tickets';

const useDeleteBookings = (userId: number, handleClearSelect: () => void) => {
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const feedback = useOpenFeedback();

  return useMutation({
    mutationFn: async (bookingIds: number[]) => {
      const proms: Promise<any>[] = [];

      bookingIds.forEach((event) => {
        proms.push(Backend.request('DELETE', `admin/${projectId}/ticket/${event}`));
      });
      await Promise.all(proms);
    },

    onSuccess: async () => {
      await queryClient.prefetchQuery(getUserTicketsQuery(userId));
      await queryClient.invalidateQueries({ queryKey: ['getAdminUserTicketsQuery', projectId, userId] });
      feedback.openSuccess('Events deleted');
      handleClearSelect();
    },
  });
};

export default useDeleteBookings;
