import { useMutation, useQueryClient } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';
import { EventDetails } from 'types/types';

const deleteUserBooking = async (pid: string, bookingId: number) => {
  await Backend.request('DELETE', `/admin/${pid}/ticket/${bookingId}`, {});
};

interface MutationProps {
  eventId: string | number;
  bookingId: number;
}

const useDeleteUserBooking = () => {
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: async ({ eventId, bookingId }: MutationProps) => {
      const previousEventDetails = queryClient.getQueryData<EventDetails>(['getAdminEventDetails', eventId]);

      if (!previousEventDetails) {
        return;
      }

      const newEventDetails: EventDetails = {
        ...previousEventDetails,
        eventBookings: previousEventDetails.eventBookings.filter((booking) => booking.bookingId !== bookingId),
      };

      queryClient.setQueryData(['getAdminEventDetails', eventId], newEventDetails);
      await deleteUserBooking(projectId, bookingId);
    },
  });
};

export default useDeleteUserBooking;
