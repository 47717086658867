import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import useDynamicTheme from 'hooks/useDynamicTheme';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

const SecondarySwitch = styled(Switch)(({ theme }) => ({
  switchBase: {
    color: `${theme.palette.secondary.light}${theme.palette.overlay.opacity.activated}`,
  },
  checked: {
    color: theme.palette.secondary.dark,
  },
  track: { backgroundColor: `${theme.palette.secondary.dark}${theme.palette.other.opacityDisabled}` },
}));

interface SwitchComponentProps {
  name: string;
  control: Control<any, any>;
  labelOn?: string;
  labelOff?: string;
  disabled?: boolean;
}

/**
 * Renders a full-width Select component with options
 * @param data:string, A React hook data
 * @param setData:function, Setter for react hook data
 * @param label:string, label for component
 * @param options:list list of {value, label} objects for options to choose
 * @param error:string, renders error if this is not empty
 */
const SwitchComponent: React.FC<SwitchComponentProps> = ({ name, control, labelOn, labelOff, disabled }) => {
  const theme = useDynamicTheme();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <Grid component="label" container alignItems="center" spacing={1}>
            {labelOff ? (
              <Grid
                item
                style={{
                  color: disabled
                    ? `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}`
                    : `${theme.palette.surface.text}${theme.palette.other.opacityHigh}`,
                }}
              >
                {labelOff}
              </Grid>
            ) : null}
            <Grid item>
              <SecondarySwitch
                checked={field.value}
                color="secondary"
                onChange={(e) => field.onChange(e.target.checked)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled || false}
              />
            </Grid>
            {labelOn ? (
              <Grid
                item
                style={{
                  color: disabled
                    ? `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}`
                    : `${theme.palette.surface.text}${theme.palette.other.opacityHigh}`,
                }}
              >
                {labelOn}
              </Grid>
            ) : null}
          </Grid>
        )}
      />
    </>
  );
};

export default SwitchComponent;
