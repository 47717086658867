import { useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useDeleteEventFilter from 'queries/admin-events/useDeleteEventFilter';
import { useState } from 'react';

interface Props {
  afterSuccess?: () => void;
}

const useDeleteFilter = ({ afterSuccess }: Props) => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState<number | null>(null);
  const [deleteFilterName, setDeleteFilterName] = useState<string | null>(null);

  const deleteFilterMutation = useDeleteEventFilter(async () => {
    await queryClient.invalidateQueries({ queryKey: ['getAdminEventFilter'] });
    feedback.openSuccess('Event filter deleted successfully');
    setDeleteDialogOpen(false);
    if (afterSuccess) afterSuccess();
  });

  const handleDeleteFilter = (groupId: number, name: string) => {
    setDeleteFilterId(groupId);
    setDeleteFilterName(name);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDeleteFilter = () => {
    deleteFilterMutation.mutate(deleteFilterId);
  };

  const handleCancelDeleteFilter = () => {
    setDeleteDialogOpen(false);
  };

  return {
    deleteDialogOpen,
    deleteFilterName,
    handleDeleteFilter,
    handleConfirmDeleteFilter,
    handleCancelDeleteFilter,
    isLoading: deleteFilterMutation.isPending,
  };
};

export default useDeleteFilter;
