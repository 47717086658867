import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import { getUserCheckedInQuery } from 'queries/admin-users/admin-user-checked-in';
import { UserTicket } from 'queries/admin-users/admin-user-tickets';
import * as Backend from 'queries/BackendRequest';
import getUserTicketsQuery from 'queries/event/user-tickets';

export interface PayBookingProps {
  events: UserTicket[];
  bookingIds: number[];
}

const usePayBookings = (userId: number) => {
  const feedback = useOpenFeedback();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { data: checkedIn } = useQuery(
    getUserCheckedInQuery({
      projectId,
      userId,
    })
  );

  return useMutation({
    mutationFn: async ({ bookingIds, events }: PayBookingProps) => {
      const filteredEvents = events.filter((event) => bookingIds.includes(event.bookingId));

      // Only set paid to true if there is a selected event that is not paid
      let setPaid = false;

      filteredEvents.forEach((event) => {
        if (!event.isPaid) setPaid = true;
      });

      const proms: Promise<any>[] = [];
      filteredEvents.forEach((event) => {
        proms.push(Backend.request('PATCH', `admin/${projectId}/booking/${event.bookingId}`, {}, { isPaid: setPaid }));
      });

      await Promise.all(proms);
      feedback.openSuccess(`Events ${setPaid ? 'paid' : 'unpaid'}`);
      if (checkedIn === false) {
        feedback.openWarning(`User not checked in`);
      }
    },
    onSuccess: async () => {
      await queryClient.prefetchQuery(getUserTicketsQuery(userId));
      await queryClient.invalidateQueries({ queryKey: ['getAdminUserTicketsQuery', projectId, userId] });
    },
  });
};

export default usePayBookings;
