import React from 'react';

import { Typography, styled } from '@mui/material';
import ColorComponent from 'components/form/Color';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { Control } from 'react-hook-form';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
});

const FlexRowItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flexWrap: 'wrap',
  justifyContent: 'start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));

interface ColorInput {
  label: string;
  name: string;
}

interface Props {
  title: string;
  subtitle: string;
  colorInputs: Array<ColorInput>;
  control: Control<any, any>;
}

const ColorRow: React.FC<Props> = ({ title, subtitle, colorInputs, control }) => {
  return (
    <>
      <Row>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Typography variant="h6" style={{ lineHeight: '27px' }}>
            {title}
          </Typography>
        </div>

        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <Typography variant="caption" color="textSecondary">
            {subtitle}
          </Typography>
        </div>
        <FlexRowItem>
          {colorInputs.map((colorInput) => (
            <div style={{ width: '200px' }}>
              <ColorComponent label={colorInput.label} name={colorInput.name} control={control} />
            </div>
          ))}
        </FlexRowItem>
      </Row>
      <div style={{ marginBottom: '32px' }} />
    </>
  );
};

export default ColorRow;
