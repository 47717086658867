import { Typography } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';

import DateComponent from 'components/form/Date';
import { editEventFormKeys } from '../types/FormTypes';
import { InputRoot } from './EventInput.styles';
import { InputContainer } from './EventStartEnd.styles';

interface EventStartEndProps {
  control: Control<any, any>;
  getValues: (value: string) => any;
}
const isValidDate = (v: string, getValues: (value: string) => any): boolean | string => {
  const eventStartDatetime = Date.parse(v);
  const eventEndDatetime = Date.parse(getValues(editEventFormKeys.endDatetime));

  if (eventStartDatetime > eventEndDatetime) {
    return 'Event must start before it ends';
  }
  return true;
};

const EventStartEnd: React.FC<EventStartEndProps> = ({ control, getValues }) => {
  return (
    <InputRoot>
      <Typography variant="body1">Event Date</Typography>
      <InputContainer>
        <DateComponent
          control={control}
          name={editEventFormKeys.startDatetime}
          label="Start date"
          style={{ marginTop: '0', marginBottom: '0', flex: 1 }}
          helperText="Enter the start date and time of the event. Dates and times are in CE(S)T"
          isDatetime
          validateRules={{
            dateMatch: (v: string) => isValidDate(v, getValues),
          }}
        />
        <DateComponent
          control={control}
          name={editEventFormKeys.endDatetime}
          label="End date"
          style={{ marginTop: '0', marginBottom: '0', flex: 1 }}
          helperText="Enter the end date and time of the event. Dates and times are in CE(S)T"
          isDatetime
        />
      </InputContainer>
    </InputRoot>
  );
};

export default EventStartEnd;
