import React from 'react';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import RoleFilter from './RoleFilter';

interface Props {
  roleSortToggle: () => void;
  roleSortUp: boolean;
  filteredRoles: number[];
  setFilteredRoles: (roles: number[]) => void;
}

const HeaderMobile: React.FC<Props> = ({ roleSortToggle, roleSortUp, filteredRoles, setFilteredRoles }) => (
  <>
    <CustomTableCell>
      <Typography variant="body1">Name</Typography>
    </CustomTableCell>
    <CustomTableCell>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <RoleFilter filteredRoles={filteredRoles} setFilteredRoles={setFilteredRoles} />
        <span style={{ marginTop: 2, marginLeft: '4px', marginRight: '4px' }}>
          <Typography variant="body1">Role</Typography>
        </span>
        {!roleSortUp ? (
          <ArrowDownward style={{ width: 20, height: 20, opacity: 0.6, cursor: 'pointer' }} onClick={roleSortToggle} />
        ) : (
          <ArrowUpward style={{ width: 20, height: 20, opacity: 0.6, cursor: 'pointer' }} onClick={roleSortToggle} />
        )}
      </div>
    </CustomTableCell>
  </>
);

export default HeaderMobile;
