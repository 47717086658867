import { Checkbox, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import React from 'react';

interface Props {
  allSelected: boolean;
  onClick: () => void;
}

const ProgramHead: React.FC<Props> = ({ allSelected, onClick }) => {
  return (
    <TableHead style={{ backgroundColor: '#ffffff' }}>
      <TableRow>
        <CustomTableCell>
          <Checkbox checked={allSelected} color="secondary" onChange={onClick} style={{ padding: 0 }} />
        </CustomTableCell>
        <CustomTableCell style={{ width: '210px' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Type</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ maxWidth: '200px', width: '0' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Program</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right', width: '100px' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Chapter</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right', width: '100px' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">School</Typography>
          </TableSortLabel>
        </CustomTableCell>
        <CustomTableCell style={{ textAlign: 'right', width: '100px' }}>
          <TableSortLabel hideSortIcon>
            <Typography variant="body1">Code</Typography>
          </TableSortLabel>
        </CustomTableCell>
      </TableRow>
    </TableHead>
  );
};

export default ProgramHead;
