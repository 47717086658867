import ButtonComponent from 'components/form/Button';
import useDynamicTheme from 'hooks/useDynamicTheme';
import React from 'react';
import ColorRow from './containers/ColorRow';
import useColorCustomizationForm from './hooks/useColorCustomizationForm';

const CustomizeThemeColors: React.FC = () => {
  const theme = useDynamicTheme();
  const colorCustomizationForm = useColorCustomizationForm();

  return (
    <>
      <ColorRow
        title="Primary"
        subtitle="Primary color of the app"
        control={colorCustomizationForm.control}
        colorInputs={[
          { label: 'Main', name: 'primary.main' },
          { label: 'Light', name: 'primary.light' },
          { label: 'Dark', name: 'primary.dark' },
          { label: 'Contrast Text', name: 'primary.contrastText' },
        ]}
      />
      <ColorRow
        title="Secondary"
        subtitle="Secondary color of the app"
        colorInputs={[
          { label: 'Main', name: 'secondary.main' },
          { label: 'Light', name: 'secondary.light' },
          { label: 'Dark', name: 'secondary.dark' },
          { label: 'Contrast Text', name: 'secondary.contrastText' },
        ]}
        control={colorCustomizationForm.control}
      />
      <ColorRow
        title="Information"
        subtitle="Information Colours"
        control={colorCustomizationForm.control}
        colorInputs={[
          { label: 'Main', name: 'info.main' },
          { label: 'Contrast Text', name: 'info.contrastText' },
        ]}
      />
      <ColorRow
        title="Success"
        subtitle="Success Colours"
        control={colorCustomizationForm.control}
        colorInputs={[
          { label: 'Main', name: 'success.main' },
          { label: 'Contrast Text', name: 'success.contrastText' },
        ]}
      />
      <ColorRow
        title="Warning"
        subtitle="Warning Colours"
        control={colorCustomizationForm.control}
        colorInputs={[
          { label: 'Main', name: 'warning.main' },
          { label: 'Contrast Text', name: 'warning.contrastText' },
        ]}
      />
      <ColorRow
        title="Error"
        subtitle="Error Colours"
        control={colorCustomizationForm.control}
        colorInputs={[
          { label: 'Main', name: 'error.main' },
          { label: 'Contrast Text', name: 'error.contrastText' },
        ]}
      />
      <div style={{ display: 'flex', gap: '20px' }}>
        <ButtonComponent
          click={() => {
            colorCustomizationForm.submitChanges(colorCustomizationForm.getValues());
          }}
          label="Save"
          fixedWidth
          loading={colorCustomizationForm.isLoading}
        />
        <ButtonComponent
          click={() => {
            colorCustomizationForm.reset(theme.palette);
          }}
          label="Reset"
          fixedWidth
        />
      </div>
    </>
  );
};

export default CustomizeThemeColors;
