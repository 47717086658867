import { enqueueSnackbar } from 'notistack';
import SnackBarCloseIcon from 'components/snackBar/SnackBarCloseIcons';
import useDynamicTheme from './useDynamicTheme';

const useOpenFeedback = () => {
  const theme = useDynamicTheme();

  const openSuccess = (text: string) => {
    enqueueSnackbar(text, {
      variant: 'success',
      action: (id) => SnackBarCloseIcon(theme, 'success', id),
      style: { backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText },
    });
  };

  const openError = (text: string) => {
    enqueueSnackbar(text, {
      variant: 'error',
      action: (id) => SnackBarCloseIcon(theme, 'error', id),
      style: { backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText },
    });
  };

  const openWarning = (text: string) => {
    enqueueSnackbar(text, {
      variant: 'warning',
      action: (id) => SnackBarCloseIcon(theme, 'warning', id),
      style: { backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText },
    });
  };

  const openInfo = (text: string) => {
    enqueueSnackbar(text, {
      variant: 'info',
      action: (id) => SnackBarCloseIcon(theme, 'info', id),
      style: { backgroundColor: theme.palette.info.main, color: theme.palette.info.contrastText },
    });
  };

  return {
    openSuccess,
    openError,
    openWarning,
    openInfo,
  };
};

export default useOpenFeedback;
