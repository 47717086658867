import React from 'react';
import { Control } from 'react-hook-form';

import DateComponent from 'components/form/Date';
import EventBookingHeader from '../components/EventBookingHeader';
import { editEventFormKeys } from '../types/FormTypes';
import { BookingInputContainer } from './EventBooking.styles';
import { InputContainer } from './EventStartEnd.styles';

interface EventBookingProps {
  control: Control<any, any>;
  getValues: (value: string) => any;
}

const noMargins = { marginTop: '0', marginBottom: '0', flex: 1 };

const isValidDate = (v: string, getValues: (value: string) => any): boolean | string => {
  const bookingStartDatetime = Date.parse(v);
  const bookingEndDatetime = Date.parse(getValues(editEventFormKeys.bookingEndDatetime));
  const unbookingEndDatetime = Date.parse(getValues(editEventFormKeys.unbookingEndDatetime));
  const swapEndDatetime = Date.parse(getValues(editEventFormKeys.swapEndDatetime));

  if (bookingStartDatetime > bookingEndDatetime) {
    return 'Booking start must be before booking end';
  }

  if (bookingStartDatetime > unbookingEndDatetime) {
    return 'Booking start must be before unbooking end';
  }

  if (bookingStartDatetime > swapEndDatetime) {
    return 'Booking start must be before transfer end';
  }

  return true;
};

const EventBooking: React.FC<EventBookingProps> = ({ control, getValues }) => {
  return (
    <>
      <EventBookingHeader />
      <BookingInputContainer>
        <InputContainer>
          <DateComponent
            control={control}
            name={editEventFormKeys.bookingStartDatetime}
            label="Booking start"
            style={noMargins}
            helperText="Date and time from which the event can be booked by users"
            validateRules={{
              dateMatch: (v: string) => isValidDate(v, getValues),
            }}
            isDatetime
          />
          <DateComponent
            control={control}
            name={editEventFormKeys.bookingEndDatetime}
            label="Booking end"
            style={noMargins}
            helperText="Date and time until which the event can be booked by users"
            isDatetime
          />
        </InputContainer>
        <DateComponent
          control={control}
          name={editEventFormKeys.unbookingEndDatetime}
          label="Unbooking End"
          style={noMargins}
          helperText="Date and time until which the event can be unbooked by ticket holders"
          isDatetime
        />
        <DateComponent
          control={control}
          name={editEventFormKeys.swapEndDatetime}
          label="Ticket Transfer End"
          style={noMargins}
          helperText="Date and time until which users can transfer their ticket to another user"
          isDatetime
        />
      </BookingInputContainer>
    </>
  );
};

export default EventBooking;
