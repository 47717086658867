import React, { useState } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import OpenEventEditSubMenu from 'containers/subMenu/OpenEventEditSubMenu';
import { AdminHeader } from 'components/adminHeader/AdminHeader';
import ChipList from 'pages/admin/events/sub-pages/edit/components/ChipList';
import SpacedDivider from 'pages/admin/events/sub-pages/edit/components/SpacedDivider';
import { EditEventFormCategory } from 'pages/admin/events/sub-pages/edit/types/FormTypes';
import CustomizationThemeImages from './sub-pages/CustomizationThemeImages';
import CustomizationThemeColors from './sub-pages/CustomizationThemeColors';
import CustomPagesCategoryRender from './CustomPagesCategoryRender';

const CustomizationColor = () => {
  const buildCategories = (): EditEventFormCategory[] => {
    return [
      {
        label: 'Colors',
        path: 'home',
        element: <CustomizationThemeColors />,
        onlyEdit: false,
      },
      {
        label: 'Logos',
        path: 'events',
        element: <CustomizationThemeImages />,
        onlyEdit: false,
      },
    ];
  };

  const categories = buildCategories();
  const [activeTab, setActiveTab] = useState(categories[0].path);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleBack = () => {
    navigate(-1);
  };

  const handleActiveTab = (path: string) => {
    setActiveTab(path);
  };

  return (
    <>
      <OpenEventEditSubMenu handleBack={handleBack} />
      <AdminHeader isMobile={isMobile} title="Theme" subtitle="Edit the theme of the website." />
      <ChipList categories={categories} activeTab={activeTab} setActiveTab={handleActiveTab} />
      <SpacedDivider />
      <CustomPagesCategoryRender categories={categories} activeTab={activeTab} />
    </>
  );
};

export default CustomizationColor;
