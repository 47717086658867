import { Typography } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';

import TextComponent from 'components/form/Text';
import { editEventFormKeys } from '../types/FormTypes';
import { InputRoot } from './EventInput.styles';

interface EventLocationProps {
  control: Control<any, any>;
}

const EventLocation: React.FC<EventLocationProps> = ({ control }) => {
  return (
    <InputRoot>
      <Typography variant="body1">Location</Typography>
      <TextComponent
        name={editEventFormKeys.location}
        control={control}
        rules={{
          required: { value: true, message: 'Enter location' },
        }}
        label="Location"
        helperText="Meeting Point or main location of the event"
        style={{ margin: '0' }}
      />
    </InputRoot>
  );
};

export default EventLocation;
