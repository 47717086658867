import * as Backend from 'queries/BackendRequest';
import { getOptions } from 'queries/user/user-options';
import { GetGeneralOptionsResponse } from 'types/types';

interface AccountInfo {
  birthdayDate: string;
  email: string;
  firstName: string;
  id: number;
  isMarketingAllowed: boolean;
  isTosAgreed: boolean;
  lastName: string;
  nationality: string;
  personNumber: string;
  phoneNumber: string;
  sex: string;
}

interface GeneralInfoResponse {
  AccountInfo: AccountInfo;
  RoleID: number;
}

interface GeneralArrivalDateResponse {
  arrivalDate: string;
}

export interface UserGeneralInfo extends AccountInfo {
  arrivalDate: string;
}

interface GetUserGeneralParams {
  projectId: string | number;
  userId: string | number;
}

const getGeneralInfo = async ({ projectId, userId }: GetUserGeneralParams): Promise<GeneralInfoResponse> => {
  const result = await Backend.request<GeneralInfoResponse>('get', `/admin/${projectId}/user/${userId}`);
  return result.data.data;
};

const getUserArrivalDate = async ({ projectId, userId }: GetUserGeneralParams): Promise<GeneralArrivalDateResponse> => {
  const result = await Backend.request<GeneralArrivalDateResponse>('GET', `admin/${projectId}/project/info/${userId}`);
  return result.data.data;
};

const formatGeneralInfo = (
  generalInfo: GeneralInfoResponse,
  arrivalInfo: GeneralArrivalDateResponse,
  options: GetGeneralOptionsResponse
): UserGeneralInfo => {
  const mappedGeneral = { ...generalInfo.AccountInfo, arrivalDate: arrivalInfo.arrivalDate };

  mappedGeneral.sex = options.gender.find((gen) => gen.value === mappedGeneral.sex)?.label || '';
  mappedGeneral.nationality =
    options.nationality.find((nation) => nation.value === mappedGeneral.nationality)?.label || '';
  return mappedGeneral;
};

const getUserGeneral = async ({ projectId, userId }: GetUserGeneralParams): Promise<UserGeneralInfo> => {
  const generalInfo = await getGeneralInfo({ projectId, userId });
  const arrivalInfo = await getUserArrivalDate({ projectId, userId });

  const options = await getOptions();

  const result = formatGeneralInfo(generalInfo, arrivalInfo, options.general);
  return result;
};

export const getUserGeneralQuery = ({ projectId, userId }: GetUserGeneralParams) => ({
  queryKey: ['getUserGeneralQuery', projectId, userId],
  queryFn: () => getUserGeneral({ projectId, userId }),
  enabled: !!projectId && !!userId,
});

export default getUserGeneralQuery;
