import * as Backend from 'queries/BackendRequest';
import { UserAccess } from 'types/types';

interface Props {
  projectId: string;
}

export const adminUsersRoles = async ({ projectId }: Props): Promise<UserAccess[]> => {
  const resp = await Backend.request<UserAccess[]>('get', `admin/${projectId}/roles/list`);
  return resp.data.data;
};

export const getAdminUsersRoles = ({ projectId }: Props) => ({
  queryKey: ['adminUsersRoles', projectId],
  queryFn: () => adminUsersRoles({ projectId }),
  enabled: !!projectId,
});
