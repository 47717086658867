import * as Backend from 'queries/BackendRequest';
import { ChangeEvent, PatchEvent } from './types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EditEventFormValues } from 'pages/admin/events/sub-pages/edit/types/FormTypes';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';

interface MutationProps {
  eventId: number;
  event: EditEventFormValues;
}

const patchAdminEvent = async (pid: string, eventId: number, patchEvent: PatchEvent) => {
  const changeEvent: ChangeEvent = {
    eventId,
    change: patchEvent,
    eventFilters: null,
    eventGroups: null,
  };
  await Backend.request('PATCH', `/event/${pid}`, {}, changeEvent);
};

const usePatchEvent = () => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  const sendData = useMutation({
    mutationFn: async ({ eventId, event }: MutationProps) => {
      const patchEvent: PatchEvent = {
        displayTitle: event.title,
        description: event.description,
        startDatetime: event.startDatetime,
        endDatetime: event.endDatetime,
        attendanceLimit: event.isNoAttendanceLimit ? 0 : event.attendanceLimit, // 0 means no limit
        basePriceSek: event.isFree ? 0 : event.price, // 0 means free
        location: event.location,
        bookingStartDatetime: event.bookingStartDatetime,
        bookingEndDatetime: event.bookingEndDatetime,
        unbookingEndDatetime: event.unbookingEndDatetime,
        swapEndDatetime: event.swapEndDatetime,
      };
      await patchAdminEvent(projectId, eventId, patchEvent);
    },
    onSuccess: async (_, { eventId }) => {
      await queryClient.invalidateQueries({ queryKey: ['getAdminEvent', eventId.toString()] });
      feedback.openSuccess('Event updated successfully');
    },
    onError: (error: { response: { data: { error: { message: string } } } }) => {
      feedback.openError(`Failed to update event: ${error.response.data.error.message ?? 'Unknown error'}`);
    },
  });
  const patchEvent = (eventId: number, event: EditEventFormValues) => {
    sendData.mutate({ eventId, event });
  };

  return {
    isLoading: sendData.isPending,
    patchEvent,
  };
};

export default usePatchEvent;
