import React from 'react';
import { Control } from 'react-hook-form';
import DateComponent from '/components/form/Date';

interface Props {
  control: Control<any, any>;
}

const DateInterval: React.FC<Props> = ({ control }) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ marginRight: '18px', width: '100%' }}>
          <DateComponent control={control} name="startDate" label="from" />
        </div>
        <div>-</div>
        <div style={{ marginLeft: '18px', width: '100%' }}>
          <DateComponent control={control} name="endDate" label="to" />
        </div>
      </div>
    </>
  );
};

export default DateInterval;
