import { useMutation } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

interface MutationProps {
  accountId: number;
  roleId: number;
}

const useSetUserRole = () => {
  const projectId = useProjectId();
  return useMutation({
    mutationFn: async ({ accountId, roleId }: MutationProps) => {
      await Backend.request('patch', `admin/${projectId}/role/${accountId}`, {}, { roleId });
    },
  });
};

export default useSetUserRole;
