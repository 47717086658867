import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import Dialog from 'components/dialog/Dialog';
import useGetEvent from '../../hooks/useGetEvent';
import CategoryRender from './components/CategoryRender';
import ChipList from './components/ChipList';
import EditEventHeader from './components/EditEventHeader';
import EventEditButtons from './components/EventEditButtons';
import SpacedDivider from './components/SpacedDivider';
import EventBooking from './containers/EventBooking';
import EventGeneral from './containers/EventGeneral';
import EventTools from './containers/EventTools';
import useCreateEvent from 'queries/admin-events/useCreateEvent';
import useEditEventForm from './hooks/useEditEventForm';
import usePatchEvent from 'queries/admin-events/usePatchEvent';
import { EditEventFormCategory, EditEventFormValues } from './types/FormTypes';
import OpenEventEditSubMenu from '/containers/subMenu/OpenEventEditSubMenu';

const buildCategories = (
  control: Control<EditEventFormValues, any>,
  watch: UseFormWatch<EditEventFormValues>,
  getValues: (value: string) => any,
  id: string,
  isCreationMode: boolean
): EditEventFormCategory[] => {
  return [
    {
      label: 'General',
      path: 'general',
      element: <EventGeneral control={control} watch={watch} getValues={getValues} />,
      onlyEdit: false,
    },
    {
      label: 'Booking',
      path: 'booking',
      element: <EventBooking control={control} getValues={getValues} />,
      onlyEdit: false,
    },
    {
      label: 'Tools',
      path: 'tools',
      element: <EventTools id={id} isCreationMode={isCreationMode} />,
      onlyEdit: true,
    },
  ];
};

interface Props {
  isCreationMode?: boolean;
}

const EditEvent: React.FC<Props> = ({ isCreationMode }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const patchEvent = usePatchEvent();
  const createEvent = useCreateEvent();
  const eventData = isCreationMode ? { data: undefined, isLoading: false, isError: false } : useGetEvent(id as string);
  const { control, watch, getValues, handleSubmit, checkHasChanges } = useEditEventForm(eventData?.data);
  const categories = buildCategories(control, watch, getValues, id as string, isCreationMode || false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(categories[0].path);

  const selectableTabs = isCreationMode ? categories.filter((category) => !category.onlyEdit) : categories;

  const onSubmit = (data: EditEventFormValues) => {
    if (isCreationMode) {
      createEvent.createEvent(data);
    } else {
      patchEvent.patchEvent(parseInt(id as string, 10), data);
    }
  };

  const handleBack = () => {
    if (checkHasChanges()) {
      setWarningDialogOpen(true);
      return;
    }
    navigate(-1);
  };

  const handleActiveTab = (path: string) => {
    setActiveTab(path);
  };

  if (eventData.isLoading || eventData.isError) return <CircularProgress color="secondary" />;

  return (
    <>
      <Dialog
        isOpen={warningDialogOpen}
        question="You have unsaved changes. Are you sure you want to proceed?"
        buttonLabel="Continue"
        confirmOnClick={() => navigate(-1)}
        cancelOnClick={() => setWarningDialogOpen(false)}
      />
      <OpenEventEditSubMenu handleBack={handleBack} />
      <EditEventHeader isCreationMode={isCreationMode} />
      <ChipList categories={selectableTabs} activeTab={activeTab} setActiveTab={handleActiveTab} />
      <SpacedDivider />
      <CategoryRender categories={categories} activeTab={activeTab} />
      {activeTab !== 'tools' && (
        <EventEditButtons onBack={handleBack} onSubmit={handleSubmit(onSubmit)} isCreationMode={isCreationMode} />
      )}
    </>
  );
};

export default EditEvent;
