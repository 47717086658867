import React from 'react';
import { Control } from 'react-hook-form';
import TextComponent from '/components/form/Text';

interface Props {
  control: Control<any, any>;
}

const PriceInterval: React.FC<Props> = ({ control }) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ marginRight: '18px', width: '100%' }}>
          <TextComponent control={control} name="startPrice" label="from" postfix="SEK" />
        </div>
        <div>-</div>
        <div style={{ marginLeft: '18px', width: '100%' }}>
          <TextComponent control={control} name="endPrice" label="to" postfix="SEK" />
        </div>
      </div>
    </>
  );
};

export default PriceInterval;
