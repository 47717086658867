import useDynamicTheme from 'hooks/useDynamicTheme';
import useChangeColors from 'queries/theme/useChangeColors';
import { useForm } from 'react-hook-form';
import { ThemeColors } from 'types/types';

const useColorCustomizationForm = () => {
  const theme = useDynamicTheme();
  const changeColors = useChangeColors();

  const submitChanges = (data: ThemeColors) => {
    const modifiedData = {
      primary: data.primary,
      secondary: data.secondary,
      info: data.info,
      success: data.success,
      warning: data.warning,
      error: data.error,
    };

    const limitedKeys: ('info' | 'warning' | 'error' | 'success')[] = ['info', 'warning', 'error', 'success'];

    limitedKeys.forEach((key) => {
      modifiedData[key].light = modifiedData[key].main;
      modifiedData[key].dark = modifiedData[key].main;
    });

    changeColors.mutate(modifiedData);
  };

  const { handleSubmit, getValues, setValue, control, reset } = useForm<ThemeColors>({
    mode: 'all',
    defaultValues: theme.palette,
  });

  return {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    submitChanges,
    isLoading: changeColors.isPending,
  };
};

export default useColorCustomizationForm;
