import styled from '@emotion/styled';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ApplyEventsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? 'column' : 'row')};
  padding: 16px 0px;
  gap: 8px;
`;
