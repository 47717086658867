import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  openModal: () => void;
}

const AddEventFab: React.FC<Props> = ({ openModal }) => {
  return (
    <Fab
      onClick={openModal}
      color="secondary"
      aria-label="add"
      style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 99 }}
    >
      <AddIcon />
    </Fab>
  );
};

export default AddEventFab;
