import { useEffect, useState } from 'react';
import deepEqual from 'utils/equals';
import { useForm } from 'react-hook-form';
import { UserEvent } from 'types/types';
import { editEventFormKeys, EditEventFormValues } from '../types/FormTypes';

const defaultValues: EditEventFormValues = {
  title: '',
  description: '',
  startDatetime: new Date().toISOString(),
  endDatetime: new Date().toISOString(),
  attendanceLimit: 0,
  isNoAttendanceLimit: false,
  price: 0,
  isFree: false,
  location: '',
  bookingStartDatetime: new Date().toISOString(),
  bookingEndDatetime: new Date().toISOString(),
  unbookingEndDatetime: new Date().toISOString(),
  swapEndDatetime: new Date().toISOString(),
};

const useEditEventForm = (event?: UserEvent) => {
  const [startValues, setStartValues] = useState<EditEventFormValues>(defaultValues);
  const { control, watch, setValue, handleSubmit, getValues } = useForm<EditEventFormValues>({
    mode: 'onSubmit',
    defaultValues,
  });
  const watchAll = watch();

  const checkHasChanges = (): boolean => {
    return !deepEqual(watchAll, startValues);
  };

  useEffect(() => {
    if (event) {
      setValue(editEventFormKeys.title as any, event.displayTitle);
      setValue(editEventFormKeys.description as any, event.description);
      setValue(editEventFormKeys.startDatetime as any, event.startDatetime);
      setValue(editEventFormKeys.endDatetime as any, event.endDatetime);
      setValue(editEventFormKeys.attendanceLimit as any, event.attendanceLimit);
      setValue(editEventFormKeys.price as any, event.basePriceSek);
      setValue(editEventFormKeys.location as any, event.location);
      setValue(editEventFormKeys.bookingStartDatetime as any, event.bookingStartDatetime);
      setValue(editEventFormKeys.bookingEndDatetime as any, event.bookingEndDatetime);
      setValue(editEventFormKeys.unbookingEndDatetime as any, event.unbookingEndDatetime);
      setValue(editEventFormKeys.swapEndDatetime as any, event.swapEndDatetime);
      setValue(editEventFormKeys.isNoAttendanceLimit as any, event.attendanceLimit === 0);
      setValue(editEventFormKeys.isFree as any, event.basePriceSek === 0);

      setStartValues({
        title: event.displayTitle,
        description: event.description,
        startDatetime: event.startDatetime,
        endDatetime: event.endDatetime,
        attendanceLimit: event.attendanceLimit,
        price: event.basePriceSek,
        location: event.location,
        bookingStartDatetime: event.bookingStartDatetime,
        bookingEndDatetime: event.bookingEndDatetime,
        unbookingEndDatetime: event.unbookingEndDatetime,
        swapEndDatetime: event.swapEndDatetime,
        isNoAttendanceLimit: event.attendanceLimit === 0,
        isFree: event.basePriceSek === 0,
      });
    }
  }, [event]);

  return {
    control,
    watch,
    handleSubmit,
    getValues,
    checkHasChanges,
  };
};

export default useEditEventForm;
