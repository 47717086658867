import React, { useState } from 'react';
import { UserTicket } from 'types/types';
import AddBookingButton from './AddBookingButtonContainer';
import AddBookingModalContainer from './AddBookingModalContainer';

interface AddBookingContainerProps {
  userId: number;
  bookedEvents: UserTicket[];
}

const AddBookingContainer: React.FC<AddBookingContainerProps> = ({ userId, bookedEvents }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddBookingButton openModal={() => setOpen(true)} userId={userId} />
      <AddBookingModalContainer
        userId={userId}
        isOpen={open}
        closeModal={() => setOpen(false)}
        bookedEvents={bookedEvents}
      />
    </>
  );
};

export default AddBookingContainer;
