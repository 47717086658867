import React, { useState } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import OpenEventEditSubMenu from 'containers/subMenu/OpenEventEditSubMenu';
import { AdminHeader } from 'components/adminHeader/AdminHeader';
import ChipList from 'pages/admin/events/sub-pages/edit/components/ChipList';
import SpacedDivider from 'pages/admin/events/sub-pages/edit/components/SpacedDivider';
import PagesHome from './PagesHome';
import PagesEvents from './PagesEvents';
import PagesTickets from './PagesTickets';
import PagesProjectRules from './PagesProjectRules';
import CustomPagesCategoryRender from './CustomPagesCategoryRender';

const CustomizationPages = () => {
  const buildCategories = () => {
    return [
      {
        label: 'Home',
        path: 'home',
        element: <PagesHome />,
        onlyEdit: false,
      },
      {
        label: 'Events',
        path: 'events',
        element: <PagesEvents />,
        onlyEdit: false,
      },
      {
        label: 'Tickets',
        path: 'tickets',
        element: <PagesTickets />,
        onlyEdit: false,
      },
      {
        label: 'Project Rules',
        path: 'project Rules',
        element: <PagesProjectRules />,
        onlyEdit: false,
      },
    ];
  };

  const categories = buildCategories();
  const [activeTab, setActiveTab] = useState(categories[0].path);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleBack = () => {
    navigate(-1);
  };

  const handleActiveTab = (path: string) => {
    setActiveTab(path);
  };

  return (
    <>
      <OpenEventEditSubMenu handleBack={handleBack} />
      <AdminHeader
        isMobile={isMobile}
        title="Pages"
        subtitle="Edit the description text for each page on the website."
      />
      <ChipList categories={categories} activeTab={activeTab} setActiveTab={handleActiveTab} />
      <SpacedDivider />
      <CustomPagesCategoryRender categories={categories} activeTab={activeTab} />
    </>
  );
};

export default CustomizationPages;
