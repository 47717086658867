import { useMutation } from '@tanstack/react-query';
import * as Backend from 'queries/BackendRequest';

type MutationProps = {
  file: any;
  imageKey: string;
  projectId: string;
};

//
const useFileUpload = () => {
  return useMutation({
    mutationFn: async ({ file, imageKey, projectId }: MutationProps) => {
      await Backend.request(
        'patch',
        `admin/${projectId}/theme/images`,
        { key: imageKey },
        { file },
        'multipart/form-data'
      );
    },
  });
};

export default useFileUpload;
