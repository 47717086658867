import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';

interface UserCheckedInParams {
  projectId: string | number;
  userId: string | number;
}

const fetchUserCheckedIn = async ({ projectId, userId }: UserCheckedInParams): Promise<boolean> => {
  const result = await Backend.request<boolean>('GET', `admin/${projectId}/user/checkin/${userId}`);
  return result.data.data;
};

const getUserCheckedInQuery = ({ projectId, userId }: UserCheckedInParams) => ({
  queryKey: ['getUserCheckedInQuery', projectId, userId],
  queryFn: () => fetchUserCheckedIn({ projectId, userId }),
  enabled: !!projectId && !!userId,
});

const toggleUserCheckedIn = ({ projectId, userId }: UserCheckedInParams) => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (checkedIn: boolean) => {
      await Backend.request('PATCH', `admin/${projectId}/user/checkin/${userId}`, {}, { checkedIn: !checkedIn });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(getUserCheckedInQuery({ projectId, userId }));
      feedback.openSuccess('Checked in status set');
    },
  });
};

export { getUserCheckedInQuery, toggleUserCheckedIn };
