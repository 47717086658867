import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography, styled } from '@mui/material';
import React from 'react';

const iconMobile: React.CSSProperties = {
  marginRight: '8px',
  height: '20px',
  marginLeft: '16px',
};

const ColoredWarningIcon = styled(WarningIcon)(({ theme }) => ({
  ...iconMobile,
  color: theme.palette.warning.main,
}));

const ColoredCheckIcon = styled(CheckIcon)(({ theme }) => ({
  ...iconMobile,
  color: theme.palette.success.main,
}));

interface ChecklistItem {
  success: boolean;
  title: string;
  description: string;
}

interface ChecklistProps {
  items: ChecklistItem[];
  isMobile: boolean;
}

const CheckList: React.FC<ChecklistProps> = ({ items, isMobile }) => {
  // Builds a table 1x4
  const buildMobile = () => {
    const returnArray: React.ReactNode[] = [];
    items.forEach((item) => {
      returnArray.push(
        <tr key={`checklist-${item.title}`}>
          <td>
            <div style={{ display: 'flex', marginBottom: '8px', width: '100%' }}>
              {item.success ? <ColoredCheckIcon /> : <ColoredWarningIcon />}
              <div>
                <Typography variant="body2">{item.title}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {item.description}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
      );
    });

    return returnArray;
  };

  // Builds a table 1x4
  const buildDesktop = () => {
    const returnArray: React.ReactNode[] = [];
    items.forEach((item) => {
      returnArray.push(
        <tr key={`checklist-${item.title}`}>
          <td>
            <div style={{ display: 'flex', marginBottom: '8px', width: '100%' }}>
              {item.success ? <ColoredCheckIcon /> : <ColoredWarningIcon />}
              <div>
                <Typography variant="body2" component="span">
                  {item.title}:{' '}
                </Typography>
                <Typography variant="caption" color="textSecondary" component="span">
                  {item.description}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
      );
    });

    return returnArray;
  };

  return (
    <>
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tbody>{isMobile ? buildMobile() : buildDesktop()}</tbody>
      </table>
    </>
  );
};

export default CheckList;
