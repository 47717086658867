import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import getAdminEventFilterQuery from 'queries/admin-events/admin-event-filter';
import { getAdminEventGroupQuery } from 'queries/admin-events/admin-event-group';
import getAdminEventStatsQuery from 'queries/admin-events/admin-event-stats';
import getAdminEventsQuery from 'queries/admin-events/admin-events';
import React, { useMemo } from 'react';
import { classifyEventList } from 'utils/eventFilter';
import EventListItem from './EventListItem';
import StartEventDivider from '/components/eventCard/StartEventDivider';

interface Props {
  searchValue: string;
}

const AdminEventList: React.FC<Props> = ({ searchValue }) => {
  const projectId = useProjectId();
  const isMobile = useIsMobile();
  const {
    data: eventsData,
    isLoading: eventsLoading,
    error: eventsError,
  } = useQuery(getAdminEventsQuery({ projectId }));
  const {
    data: eventStats,
    isLoading: eventStatsLoading,
    error: eventStatsError,
  } = useQuery(getAdminEventStatsQuery({ projectId }));
  const {
    data: eventFilters,
    isLoading: eventFiltersLoading,
    error: eventFiltersError,
  } = useQuery(getAdminEventFilterQuery({ projectId }));
  const {
    data: eventGroups,
    isLoading: eventGroupsLoading,
    error: eventGroupsError,
  } = useQuery(getAdminEventGroupQuery({ projectId }));

  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const eventsList = useMemo(() => {
    if (!eventsData) return [];

    if (debouncedSearchValue?.length < 3) {
      return classifyEventList(eventsData);
    }

    const eventsFiltered = eventsData?.filter((event) => {
      const eventTitle = event?.displayTitle.toLowerCase();
      const searchValueLower = debouncedSearchValue.toLowerCase();
      return eventTitle.includes(searchValueLower);
    });
    return classifyEventList(eventsFiltered);
  }, [eventsData, debouncedSearchValue]);

  if (
    !eventsData ||
    !eventStats ||
    !eventFilters ||
    !eventGroups ||
    eventsLoading ||
    eventsError ||
    eventStatsLoading ||
    eventStatsError ||
    eventFiltersLoading ||
    eventFiltersError ||
    eventGroupsLoading ||
    eventGroupsError
  ) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <>
      <div style={{ marginTop: '16px' }} />
      {eventsList?.map((item) => {
        const event = item.data;
        return (
          <div key={`admin-event-card-${event.eventId}`}>
            {item.first && <StartEventDivider date={event.startDatetime} />}
            <EventListItem
              event={event}
              statsList={eventStats}
              eventFilters={eventFilters}
              eventGroups={eventGroups}
              isMobile={isMobile}
            />
          </div>
        );
      })}
    </>
  );
};

export default AdminEventList;
