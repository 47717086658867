import React, { useState } from 'react';

import { Divider, Pagination, Table, TableBody } from '@mui/material';
import { AdminHeader } from 'components/adminHeader/AdminHeader';
import { UsersEntryRow } from 'components/eventManageUsers/CommonTypes';
import UsersMobileRow from 'components/eventManageUsers/UsersMobileRow';
import UsersTableHeader from 'components/eventManageUsers/UsersTableHeader';
import UsersTableRow from 'components/eventManageUsers/UsersTableRow';
import SearchBar from 'components/searchBar/SearchBar';
import useCopyClipboard from 'hooks/useCopyClipboard';
import useIsMobile from 'hooks/useIsMobile';
import usePagination from 'hooks/usePagination';
import useProjectId from 'hooks/useProjectId';
import { EventDetails } from 'types/types';
import useRemoveBooking from '../hooks/useRemoveBooking';
import useUpdateBooking from '../../../../../../queries/admin-events/useUpdateBooking';
import AddUserContainer from './AddUserContainer';

interface BookedUsersProps {
  eventData: EventDetails;
}

const BookedUsers: React.FC<BookedUsersProps> = ({ eventData }) => {
  const isMobile = useIsMobile();
  const copyClipboard = useCopyClipboard();
  const projectId = useProjectId();
  const { changeBooking } = useUpdateBooking();
  const { deleteBooking } = useRemoveBooking();

  const [searchValue, setSearchValue] = useState(0);

  const filteredBookings: UsersEntryRow[] = eventData.eventBookings
    .filter((booking) => searchValue === 0 || booking.accountId === searchValue)
    .slice()
    .map((booking) => ({
      name: {
        firstName: booking.firstName,
        lastName: booking.lastName,
        email: booking.email,
        copyToClipboard: () => copyClipboard.copy(booking.email),
      },
      attending: {
        isAttending: booking.isAttending,
        onClick: () =>
          changeBooking(eventData.eventId.toString(), booking.bookingId, !booking.isAttending, booking.isPaid),
      },
      paid: {
        isPaid: booking.isPaid,
        isFree: eventData.basePriceSek === 0,
        onClick: () =>
          changeBooking(eventData.eventId.toString(), booking.bookingId, booking.isAttending, !booking.isPaid),
      },
      options: {
        onDeleteClick: () => deleteBooking(eventData.eventId.toString(), booking.bookingId),
        onTransferClick: () => {},
      },
      isCheckedIn: booking.isCheckedIn,
    }));

  const pagination = usePagination<UsersEntryRow>(filteredBookings, 25);

  return (
    <>
      <div style={{ marginTop: '0.5rem' }} />
      <AdminHeader
        small
        isMobile
        title="Bookings"
        subtitle={isMobile ? 'Tap events to change their status.' : ''}
        disableDivider
      />
      <SearchBar
        setSelected={(value: number) => setSearchValue(value)}
        projectId={projectId}
        placeholder="Search user"
        filter={eventData.eventBookings.map((booking) => booking.accountId)}
      />
      {isMobile && <Divider style={{ marginTop: '1rem' }} />}
      {isMobile ? (
        <div style={{ marginTop: '1rem' }}>
          {pagination.currentElements.map((booking) => (
            <UsersMobileRow key={`booking--${booking.name.email}--${booking.name.firstName}`} {...booking} />
          ))}
        </div>
      ) : (
        <Table>
          <UsersTableHeader />
          <TableBody>
            {pagination.currentElements.map((booking) => (
              <UsersTableRow key={`booking--${booking.name.email}--${booking.name.firstName}`} {...booking} />
            ))}
          </TableBody>
        </Table>
      )}
      <Pagination
        count={pagination.totalPages}
        color="secondary"
        page={pagination.currentPage}
        style={{ margin: '16px 0' }}
        onChange={(e, num) => pagination.paginate(num)}
        size="small"
      />
      <AddUserContainer eventId={eventData.eventId} />
    </>
  );
};

export default BookedUsers;
