import * as Backend from 'queries/BackendRequest';
import { EventDetails } from 'types/types';

interface Props {
  projectId: string;
  eventId: string;
}

const getAdminEventDetails = async ({ projectId, eventId }: Props): Promise<EventDetails> => {
  const resp = await Backend.request<EventDetails>('GET', `/admin/${projectId}/event_aggregated/${eventId}`);
  return resp.data.data;
};

const getAdminEventDetailsQuery = ({ projectId, eventId }: Props) => ({
  queryKey: ['getAdminEventDetails', eventId],
  queryFn: () => getAdminEventDetails({ projectId, eventId }),
  enabled: !!projectId,
});

export default getAdminEventDetailsQuery;
