import React from 'react';
import DeleteDialog from 'components/deleteDialog/DeleteDialog';

interface Props {
  name: string | null;
  confirmOnClick: () => void;
  cancelOnClick: () => void;
  isOpen: boolean;
  buttonDisable: boolean;
}

const FilterDeleteDialog: React.FC<Props> = ({ name, confirmOnClick, cancelOnClick, isOpen, buttonDisable }) => (
  <DeleteDialog
    text={`Are you sure you want to delete the event filter '${name}'?`}
    confirmOnClick={confirmOnClick}
    cancelOnClick={cancelOnClick}
    isOpen={isOpen}
    buttonDisable={buttonDisable}
  />
);

export default FilterDeleteDialog;
