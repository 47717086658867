import React from 'react';
import { Typography } from '@mui/material';

interface IconTextProps {
  style?: React.CSSProperties;
  spacing: number;
  label: React.ReactNode | string;
  icon: React.ReactNode;
  isMobile: boolean;
}

/**
 * Displays an Icon and text pair
 * Props:
 *  - style : object, optional styling to object
 *  - spacing : number, spacing between text and icon in pixels
 *  - label : string,
 *  - icon : jsx
 */
const IconText: React.FC<IconTextProps> = ({ style, spacing, label, icon, isMobile }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: isMobile ? 'column' : 'row',
      ...style,
    }}
  >
    {icon}
    <Typography variant="body2" style={{ marginLeft: !isMobile ? `${spacing}px` : '0', textAlign: 'center' }}>
      {label}
    </Typography>
  </div>
);

export default IconText;
