import { useState } from 'react';

const usePagination = <T>(elements: T[], elementsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(elements.length / elementsPerPage);
  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  const currentElements = elements.slice(indexOfFirstElement, indexOfLastElement);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return {
    currentElements,
    paginate,
    currentPage,
    totalPages,
  };
};

export default usePagination;
