import { UserTicket } from 'queries/admin-users/admin-user-tickets';
import { useEffect, useState } from 'react';

const useEventSelect = (events?: UserTicket[], clearArray?: any[]) => {
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);

  const handleSelectEvent = (eventBookingId: number) => {
    let pseudoEvents: number[] = [...selectedEvents];
    if (pseudoEvents.includes(eventBookingId)) {
      pseudoEvents = pseudoEvents.filter((pseudoEvent: number) => pseudoEvent !== eventBookingId);
    } else {
      pseudoEvents.push(eventBookingId);
    }
    setSelectedEvents(pseudoEvents);
  };

  const handleAllSelect = () => {
    if (!events) return;

    if (selectedEvents.length !== events.length) {
      const pseudoEvents: number[] = [];
      events.forEach((event: UserTicket) => {
        pseudoEvents.push(event.bookingId);
      });

      setSelectedEvents(pseudoEvents);
    } else {
      setSelectedEvents([]);
    }
  };

  const handleClearSelect = () => {
    setSelectedEvents([]);
  };

  useEffect(() => {
    handleClearSelect();
  }, clearArray || []);

  return { selectedEvents, handleAllSelect, handleSelectEvent, handleClearSelect };
};

export default useEventSelect;
