import * as Backend from 'queries/BackendRequest';

interface CheckTransferTicket {
  projectId: number | string;
  bookingId: number;
  userId: number;
}

interface CheckEvent {
  details: string[];
  isOk: boolean;
}

export interface CheckTransferToUserResponse {
  isBeforeTransferTime: CheckEvent;
  isFiltered: CheckEvent;
  isMaxGroups: CheckEvent;
  isNovelEvent: CheckEvent;
  isOverlapping: CheckEvent;
}

const fetchCheckTicket = async ({
  projectId,
  userId,
  bookingId,
}: CheckTransferTicket): Promise<CheckTransferToUserResponse> => {
  const result = await Backend.request<CheckTransferToUserResponse>(
    'POST',
    `admin/${projectId}/ticket/transfer/check/${userId}`,
    {},
    { bookingID: bookingId }
  );

  return result.data.data;
};

export const getUserTransferCheckQuery = ({ projectId, userId, bookingId }: CheckTransferTicket) => ({
  queryKey: ['getUserTransferCheckQuery', projectId, userId, bookingId],
  queryFn: () => fetchCheckTicket({ projectId, userId, bookingId }),
  enabled: !!projectId && userId !== 0 && bookingId !== 0,
});

export default getUserTransferCheckQuery;
