import React from 'react';
import { Typography } from '@mui/material';

const CustomizationGeneral = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography color="textSecondary">General customization coming soon (TM).</Typography>
    </div>
  );
};

export default CustomizationGeneral;
