import React from 'react';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

/**
 * Returns string based on if it's alcohol and if it's mobile format
 */
const returnText = (isAlcohol: boolean, isMobile: boolean) => {
  if (isAlcohol) {
    if (isMobile) return 'A';
    return 'Alcohol';
  }

  if (isMobile) return 'N-A';
  return 'Non-Alcohol';
};
interface EventsTitleProps {
  isCollapsed: boolean;
  isAlcohol: boolean;
  isMobile: boolean;
  onClick: () => void;
}

/**
 * Displays the title for events overview
 * required props:
 *  - isCollapsed : boolean
 *  - isAlcohol : boolean
 *  - isMobile : boolean
 *  - onClick : function
 */
const EventsTitle: React.FC<EventsTitleProps> = ({ isCollapsed, isAlcohol, isMobile, onClick }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: isMobile ? '0' : '8px',
    }}
  >
    <Typography variant="h6">Events Overview ({returnText(isAlcohol, isMobile)})</Typography>
    {isMobile ? (
      <IconButton style={{ padding: '8px' }} onClick={onClick} color="secondary" size="large">
        {isCollapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </IconButton>
    ) : null}
  </div>
);

export default EventsTitle;
