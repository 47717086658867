import { styled } from '@mui/material';

export const AttendanceInputContainer = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  alignItems: 'baseline',
}));

export default AttendanceInputContainer;
