import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import * as Backend from 'queries/BackendRequest';

interface TransferTicketMutationProps {
  projectId: string;
  userId: number;
  bookingId: number;
  isPaid: boolean;
  isAttending: boolean;
}

const useTransferTicket = (originalUserId: number, onDone: () => void) => {
  const feedback = useOpenFeedback();
  const queryClient = useQueryClient();

  const transferTicket = useMutation({
    mutationFn: async ({ projectId, userId, bookingId, isPaid, isAttending }: TransferTicketMutationProps) => {
      await Backend.request(
        'POST',
        `admin/${projectId}/ticket/transfer/confirm/${userId}`,
        {},
        { bookingID: bookingId, isAttending, isPaid }
      );
    },
    onSuccess: async (_, variables) => {
      await queryClient.prefetchQuery({ queryKey: ['getUserTicketsQuery', variables.projectId, originalUserId] });
      await queryClient.invalidateQueries({
        queryKey: ['getAdminUserTicketsQuery', variables.projectId, originalUserId],
      });
      feedback.openSuccess('The ticket transfer was successful');
      onDone();
    },
  });
  return transferTicket;
};

export default useTransferTicket;
