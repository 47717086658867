import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  subtitle: string;
  style?: React.CSSProperties;
}

const ToolsRow: React.FC<Props> = ({ title, subtitle, style }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Typography variant="h6" style={{ lineHeight: '27px' }}>
          {title}
        </Typography>
      </div>
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Typography variant="caption" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default ToolsRow;
