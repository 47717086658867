import React from 'react';
import { Route, Routes } from 'react-router-dom';

import EditEvent from './sub-pages/edit/EditEvent';
import ManageEvent from './sub-pages/manage/ManageEvent';
import EventOverview from './sub-pages/overview/EventOverview';

const AdminEventsPage = () => (
  <Routes>
    <Route path="/create" element={<EditEvent isCreationMode />} />
    <Route path="/edit/:id" element={<EditEvent />} />
    <Route path="/manage/:id" element={<ManageEvent />} />
    <Route path="*" element={<EventOverview />} />
  </Routes>
);

export default AdminEventsPage;
