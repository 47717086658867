import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import getAdminEventDetailsQuery from 'queries/admin-events/admin-event-details';
import { BookingStats } from 'queries/admin-events/types';
import { useEffect, useState } from 'react';

const useGetEventDetails = (eventId: string) => {
  const projectId = useProjectId();
  const eventData = useQuery(getAdminEventDetailsQuery({ projectId, eventId }));
  const [stats, setStats] = useState<BookingStats>({
    countBooked: 0,
    countAttending: 0,
    countPaid: 0,
  });

  useEffect(() => {
    if (!eventData.data) return;
    const newStats: BookingStats = {
      countBooked: eventData.data.eventBookings.length,
      countAttending: eventData.data.eventBookings.filter((booking) => booking.isAttending).length,
      countPaid: eventData.data.eventBookings.filter((booking) => booking.isPaid).length,
    };
    setStats(newStats);
  }, [eventData.data]);

  return {
    data: eventData.data,
    stats,
    isLoading: eventData.isLoading,
    isError: eventData.isError,
  };
};

export default useGetEventDetails;
