import * as Backend from 'queries/BackendRequest';
import { PatchEvent } from '/queries/admin-events/types';
import { useMutation } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';

export interface EventBatch {
  events: PatchEvent[];
}

interface EventImportProps {
  eventList: EventBatch;
  pid: string;
}

const useEventImport = () => {
  const feedback = useOpenFeedback();

  return useMutation({
    mutationFn: async ({ eventList, pid }: EventImportProps) => {
      const form: EventBatch = {
        events: eventList.events,
      };
      await Backend.request('POST', `/event/${pid}/import`, {}, form);
    },
    onSuccess: async () => {
      feedback.openSuccess('Successfully imported events.');
    },
    onError: async () => {
      feedback.openError('Error importing events.');
    },
  });
};

export default useEventImport;
