import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';
import React from 'react';

interface Props {
  title?: string;
  question?: string;
  buttonLabel?: string;
  confirmOnClick?: () => void;
  cancelOnClick?: () => void;
  buttonDisable?: boolean;
  isOpen?: boolean;
  hideCancel?: boolean;
  buttonVariant?: 'text' | 'outlined' | 'contained';
}

const Dialog: React.FC<Props> = ({
  title = '',
  question = '',
  buttonLabel = '',
  confirmOnClick = () => null,
  cancelOnClick = () => null,
  buttonDisable = false,
  isOpen = false,
  hideCancel = false,
  buttonVariant = 'text',
}) => {
  return (
    <MuiDialog open={isOpen} onClose={cancelOnClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button onClick={cancelOnClick} color="secondary">
            Cancel
          </Button>
        )}
        <Button onClick={confirmOnClick} color="secondary" variant={buttonVariant} disabled={buttonDisable}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
