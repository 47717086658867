import { Typography } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';
import CheckComponent from '/components/form/Check';

const status = [
  { label: 'Attended', value: 'attended' },
  { label: 'Not attended', value: 'notAttended' },
];

interface AttendanceProps {
  control: Control<any, any>;
}

const Attendance: React.FC<AttendanceProps> = ({ control }) => {
  return (
    <>
      {status.map((stat) => (
        <CheckComponent
          key={stat.value}
          name={stat.value}
          control={control}
          label={<Typography variant="body2">{stat.label.charAt(0).toUpperCase() + stat.label.slice(1)}</Typography>}
          noMargin
          rules={undefined}
        />
      ))}
    </>
  );
};

export default Attendance;
