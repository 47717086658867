import React from 'react';
import { Button } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';

export interface AttendingButtonProps {
  isAttending: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}

const AttendingButton: React.FC<AttendingButtonProps> = ({ isAttending, onClick, style }) => {
  const theme = useDynamicTheme();
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="secondary"
      style={{ width: '105px', backgroundColor: isAttending ? theme.palette.success.main : '', ...style }}
    >
      {isAttending ? 'Attending' : 'Attend'}
    </Button>
  );
};

export default AttendingButton;
