import React from 'react';

import { styled } from '@mui/material';
import { Divider, Theme, Typography } from '@mui/material';
import Dialog from 'components/dialog/Dialog';
import useDynamicTheme from 'hooks/useDynamicTheme';
import AdminDownloadButton from 'pages/admin/events/sub-pages/manage/components/AdminDownloadButton';
import ToolsRow from 'pages/admin/events/sub-pages/manage/components/ToolsRow';
import useDeleteEvent from '../hooks/useDeleteEvent';

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
});

const FlexRowItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
interface Props {
  id: string;
  isCreationMode: boolean;
}

const EventTools: React.FC<Props> = ({ id, isCreationMode }) => {
  const theme = useDynamicTheme();
  const deleteEvent = useDeleteEvent(isCreationMode, id);

  return (
    <>
      <Dialog
        isOpen={deleteEvent.hasBookingsErrorOpen}
        title="Can’t Delete Event"
        question="Can’t delete an event if there are bookings in it. Please delete all booked users before deleting the event."
        hideCancel
        buttonLabel="OK"
        confirmOnClick={() => deleteEvent.setHasBookingsErrorOpen(false)}
      />
      <Dialog
        isOpen={deleteEvent.deleteConfirmOpen}
        title="Reminder"
        question={`Are you sure you want to delete ${deleteEvent.eventDetails.data?.displayTitle}? This cannot be undone.`}
        buttonLabel="DELETE"
        confirmOnClick={deleteEvent.confirmDelete}
        cancelOnClick={() => deleteEvent.setDeleteConfirmOpen(false)}
      />
      <Row>
        <Typography variant="h6">Tools</Typography>
        <Divider />
        <FlexRowItem>
          <ToolsRow title="Delete Event" subtitle="Delete this event." />
          <AdminDownloadButton onClick={deleteEvent.tryDeleteEvent} label="Delete" />
        </FlexRowItem>
      </Row>
    </>
  );
};

export default EventTools;
