import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import { getAdminUsersRoles } from 'queries/admin-events/admin-users-roles';
import { useEffect, useState } from 'react';
import { UserAccess } from 'types/types';
import useDeleteUser from '../../../../queries/admin-users/useDeleteUser';
import useSetUserRole from '../../../../queries/admin-users/useSetUserRole';

export interface UserAccessEditable extends UserAccess {
  editing: boolean;
}

export const roleFilters = [
  { roleId: 6, title: 'Student' },
  { roleId: 4, title: 'Buddy' },
  { roleId: 2, title: 'PT' },
];

const useUsersAccess = () => {
  const projectId = useProjectId();
  const usersList = useQuery(getAdminUsersRoles({ projectId }));
  const [users, setUsers] = useState<UserAccessEditable[]>([]);
  const mutateUserRole = useSetUserRole();
  const mutateDeleteUser = useDeleteUser();
  const [sortUp, setSortUp] = useState(true);
  const [searchedUser, setSearchedUser] = useState(0);
  const [idToDelete, setIdToDelete] = useState(0);
  const [filteredRoles, setFilteredRoles] = useState<number[]>([]);

  const toggleUserEditing = (accountId: number) => {
    setUsers((prev) =>
      prev.map((user) => {
        if (user.accountId === accountId) {
          return { ...user, editing: !user.editing };
        }
        return user;
      })
    );
  };

  const setUserRole = (accountId: number, roleId: number) => {
    setUsers((prev) =>
      prev.map((user) => {
        if (user.accountId === accountId) {
          return { ...user, roleId };
        }
        return user;
      })
    );
    mutateUserRole.mutate({ accountId, roleId });
  };

  const deleteUser = (accountId: number) => {
    setUsers((prev) => prev.filter((user) => user.accountId !== accountId));
    mutateDeleteUser.mutate({ accountId });
  };

  const sortUsersByRole = () => {
    setUsers((prev) => {
      const tempUsers = [...prev];
      tempUsers.sort((a, b) => (sortUp ? a.roleId - b.roleId : b.roleId - a.roleId));
      return tempUsers;
    });
    setSortUp((prev) => !prev);
  };

  useEffect(() => {
    if (usersList.data) {
      setUsers(usersList.data.map((user) => ({ ...user, editing: false })));
    }
  }, [usersList.data, setUsers]);

  return {
    projectId,
    users: searchedUser
      ? users.filter((user) => user.accountId === searchedUser)
      : users.filter((user) => (!filteredRoles.length ? true : filteredRoles.includes(user.roleId))),
    sortUp,
    isLoading: usersList.isLoading,
    toggleUserEditing,
    setUserRole,
    deleteUser,
    sortUsersByRole,
    searchedUser,
    setSearchedUser,
    filteredRoles,
    setFilteredRoles,
    idToDelete,
    setIdToDelete,
  };
};

export default useUsersAccess;
