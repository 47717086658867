import { TextField } from '@mui/material';
import React, { useRef } from 'react';

interface Props {
  color: string;
  actualColor: string;
  setColor: (hexColor: string) => void;
  readOnly?: boolean;
  label?: string;
  setAnchorEl?: (el: HTMLDivElement) => void;
}

const ColorHexField: React.FC<Props> = ({ color, actualColor, setColor, readOnly, label, setAnchorEl }) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClick = () => {
    if (!ref.current || !setAnchorEl) return;
    setAnchorEl(ref.current);
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      <div
        ref={ref}
        style={{
          height: '32px',
          aspectRatio: '1/1',
          borderRadius: '100%',
          backgroundColor: actualColor,
        }}
      />
      <TextField
        color="secondary"
        label={label || 'Hex'}
        variant="outlined"
        size="small"
        fullWidth
        onChange={(e) => setColor(e.target.value)}
        value={color}
        InputProps={{
          readOnly,
        }}
        onClick={onClick}
      />
    </div>
  );
};

export default ColorHexField;
