import { useState } from 'react';
import useGetEventDetails from '../../manage/hooks/useGetEventDetails';
import useEventDelete from 'queries/admin-events/useEventDelete';

const useDeleteEvent = (isCreationMode: boolean, id: string) => {
  const [hasBookingsErrorOpen, setHasBookingsErrorOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const eventDetails = isCreationMode ? { data: undefined, isLoading: false, isError: false } : useGetEventDetails(id);

  const deleteEvent = useEventDelete(id);

  const tryDeleteEvent = () => {
    if (eventDetails.data?.eventBookings.length) {
      setHasBookingsErrorOpen(true);
      return;
    }
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = () => {
    deleteEvent.mutate();
  };

  return {
    hasBookingsErrorOpen,
    setHasBookingsErrorOpen,
    deleteConfirmOpen,
    setDeleteConfirmOpen,
    eventDetails,
    tryDeleteEvent,
    confirmDelete,
  };
};

export default useDeleteEvent;
