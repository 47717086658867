import React from 'react';
import { Chip } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';

interface TabChipProps {
  label: string;
  selected: boolean;
  style?: object;
  onClick: () => void;
  disabled?: boolean;
}

/**
 * displays a material ui style chip in secondary colors
 *
 * @param label:string, text to be displayed
 * @param selected:boolean, if the chip is activated
 * @param style:object, custom styling for chip
 * @param onClick:function, function if chip gets clicked
 * @param disabled:boolean
 */
const TabChip: React.FC<TabChipProps> = ({ label, selected, style, onClick, disabled }) => {
  const theme = useDynamicTheme();

  return (
    <Chip
      label={label}
      style={{
        color: theme.palette.secondary.main,
        backgroundColor: `${theme.palette.secondary.main}${
          selected ? theme.palette.overlay.opacity.deactivated : theme.palette.overlay.opacity.activated
        }`,
        border: selected ? `1px solid ${theme.palette.secondary.main}` : '',
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default TabChip;
