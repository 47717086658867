import React, { useState } from 'react';

import EventAdminContent, { EventContentPropsData } from '/components/eventCard/EventAdminContent';

import { CircularProgress, Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import { useQuery } from '@tanstack/react-query';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getUserPreferencesQuery } from 'queries/admin-users/admin-user-food-prefs';
import { getAdminUserTicketsQuery } from 'queries/admin-users/admin-user-tickets';
import { getEventGroupsQuery } from 'queries/event/user-event-groups';
import CategoryChips from '../components/CategoryChips';
import EventsTitle from '../components/EventsTitle';

interface EventsOverviewProps {
  userId: number;
}

// The events overview container with it's logic and render
const EventsOverview: React.FC<EventsOverviewProps> = ({ userId }) => {
  const isMobile = useIsMobile('sm');
  const [collapsed, setCollapsed] = useState(true);

  const projectId = useProjectId();
  const { data: eventGroups } = useQuery(getEventGroupsQuery(projectId));
  const { data: preferences } = useQuery(getUserPreferencesQuery({ projectId, userId }));
  const { data: eventsResponse } = useQuery(getAdminUserTicketsQuery({ projectId, userId }));

  const events = eventsResponse || [];

  const formData = (): EventContentPropsData => {
    const totalBooked = events.length;
    const totalAttended = events.filter((event) => event.isAttending).length;
    const totalUnpaid = events
      .filter((event) => !event.isPaid)
      .map((event) => event.basePriceSek)
      .reduce((a: number, b: number) => a + b, 0);

    return {
      booked: {
        label: isMobile ? `${totalBooked}` : `${totalBooked} Booked`,
        state: 'default',
      },
      attended: {
        label: isMobile ? `${totalAttended}/${totalBooked}` : `${totalAttended}/${totalBooked} Attended`,
        state: totalAttended === totalBooked ? 'success' : 'warning',
      },
      payment: {
        label: isMobile ? totalUnpaid : `${totalUnpaid} Unpaid`,
        state: totalUnpaid > 0 ? 'error' : 'success',
      },
    };
  };

  // Format the events and event groups
  // into a list of strings for the chip to render
  const formatEventGroups = () => {
    if (!eventGroups || !events) return [];

    const categories: any = [];

    eventGroups.forEach((value: any) => {
      if (events.filter((event) => event.eventGroups.includes(value.id)).length > 0) {
        categories.push({
          value: value.id.toString(),
          current: events.filter((event) => event.eventGroups.includes(value.id)).length,
          ticketLimit: value.ticketLimit,
          label: value.name,
        });
      }
    });

    const stringed: string[] = [];

    categories.forEach((category: any) => {
      stringed.push(`${category.label}: ${category.current}${category.ticketLimit ? `/${category.ticketLimit}` : ''}`);
    });

    return stringed;
  };

  if (!userId) {
    return <></>;
  }

  if (events === undefined || eventGroups === undefined || preferences === undefined)
    return <CircularProgress color="secondary" />;

  return (
    <div style={{ paddingTop: '8px' }}>
      <Divider style={{ margin: '8px 0' }} />
      <EventsTitle
        isCollapsed={collapsed}
        isAlcohol={preferences.drinking}
        isMobile={isMobile}
        onClick={() => setCollapsed(!collapsed)}
      />
      <EventAdminContent data={formData()} isMobile={isMobile} />
      {!isMobile ? (
        <div style={{ marginTop: '16px' }}>
          <CategoryChips data={formatEventGroups()} />
        </div>
      ) : (
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
          <Divider style={{ marginTop: '10px', marginBottom: '8px' }} />
          <CategoryChips data={formatEventGroups()} />
        </Collapse>
      )}
      <Divider style={{ margin: '8px 0' }} />
    </div>
  );
};

export default EventsOverview;
