import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Control } from 'react-hook-form';

import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import useDynamicTheme from 'hooks/useDynamicTheme';
import Attendance from '../../components/Attendance';
import DateInterval from '../../components/DateInterval';
import PriceInterval from '../../components/PriceInterval';
import SubpageModal from '/components/subpageModal/SubpageModal';

interface BookingsFilterContainerProps {
  control: Control<any, any>;
  handleReset: () => void;
}

const BookingsFilterContainer: React.FC<BookingsFilterContainerProps> = ({ control, handleReset }) => {
  const theme = useDynamicTheme();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <IconButton style={{ color: theme.palette.secondary.main }} onClick={() => setModalOpen(true)} size="large">
        <FilterListIcon />
      </IconButton>
      <SubpageModal title="Filter" isOpen={modalOpen} reset={handleReset} close={() => setModalOpen(false)}>
        <Typography variant="h6">Date</Typography>
        <DateInterval control={control} />
        <Typography variant="h6">Price</Typography>
        <PriceInterval control={control} />
        <Typography variant="h6">Attendance</Typography>
        <Attendance control={control} />
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          style={{ marginTop: '40px', marginBottom: '40px' }}
          onClick={() => setModalOpen(false)}
        >
          Show Results
        </Button>
      </SubpageModal>
    </>
  );
};

export default BookingsFilterContainer;
