import React, { useState } from 'react';

import { Checkbox, Divider, FormControlLabel, Typography, styled } from '@mui/material';
import Dialog from 'components/dialog/Dialog';
import useCopyClipboard from 'hooks/useCopyClipboard';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import { EventBookings } from 'types/types';
import AdminDownloadButton from '../components/AdminDownloadButton';
import ToolsRow from '../components/ToolsRow';
import useExportEvent from 'queries/admin-events/event-export';

const FlexRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const FlexRowItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const EMAILS_LIMIT = 500;

interface Props {
  eventId: number;
  eventTitle: string;
  bookings: EventBookings[];
  price: Number;
}

const AdminEventTools: React.FC<Props> = ({ eventId, eventTitle, bookings, price }) => {
  const theme = useDynamicTheme();
  const isMobile = useIsMobile();
  const copyClipboard = useCopyClipboard();
  const [tooManyEmailsDialogOpen, setTooManyEmailsDialogOpen] = useState(false);
  const [copyEmailReminderDialogOpen, setCopyEmailReminderDialogOpen] = useState(false);

  const [attendingOnly, setAttendingOnly] = useState(false);
  const [paidOnly, setPaidOnly] = useState(false);
  const [checkedInOnly, setCheckedInOnly] = useState(false);

  const exportEvent = useExportEvent();

  const buildCopyText = () => {
    let selectedBookings = bookings;
    if (attendingOnly) selectedBookings = selectedBookings.filter((booking) => booking.isAttending === true);
    if (paidOnly) selectedBookings = selectedBookings.filter((booking) => booking.isPaid);
    if (checkedInOnly) selectedBookings = selectedBookings.filter((booking) => booking.isCheckedIn);

    const emails = selectedBookings.map((booking) => booking.email);
    return emails.join(';');
  };

  const tryToCopyEmails = () => {
    // if there are more than limit emails, show failed dialog
    if (bookings.length > EMAILS_LIMIT) {
      setTooManyEmailsDialogOpen(true);
      return;
    }
    setCopyEmailReminderDialogOpen(true);
  };

  const copyEmails = () => {
    copyClipboard.copy(buildCopyText());
    setCopyEmailReminderDialogOpen(false);
  };

  const handleDownload = () => {
    exportEvent.mutate({ eventId, fileName: `${eventTitle}.xlsx` });
  };

  return (
    <>
      <Dialog
        isOpen={tooManyEmailsDialogOpen}
        title="Copy Not Possible"
        question={`An e-mail with more than ${EMAILS_LIMIT} recipients cannot be sent by normal e-mail software. Please click the Download button under Download Booking Data and copy the user e-mails from the downloaded file.`}
        hideCancel
        buttonLabel="OK"
        confirmOnClick={() => setTooManyEmailsDialogOpen(false)}
      />
      <Dialog
        isOpen={copyEmailReminderDialogOpen}
        title="Reminder"
        question="Always send e-mails as Bcc to protect users’ privacy."
        buttonLabel="OK"
        confirmOnClick={copyEmails}
        cancelOnClick={() => setCopyEmailReminderDialogOpen(false)}
      />
      <FlexRow>
        <FlexRowItem>
          <ToolsRow title="Copy All E-mails" subtitle="Copy all user e-mails to Clipboard." />
          <FormControlLabel
            style={{ display: 'block' }}
            control={
              <Checkbox
                checked={checkedInOnly}
                name="checkedD"
                color="secondary"
                style={{ padding: '4px 8px' }}
                onClick={() => setCheckedInOnly(!checkedInOnly)}
              />
            }
            label={
              <Typography variant="body2" style={{ display: 'inline' }}>
                Checked-In Only
              </Typography>
            }
          />
          <FormControlLabel
            style={{ display: 'block' }}
            control={
              <Checkbox
                checked={paidOnly}
                name="checkedD"
                color="secondary"
                disabled={price === 0}
                style={{ padding: '4px 8px' }}
                onClick={() => setPaidOnly(!paidOnly)}
              />
            }
            label={
              <Typography
                variant="body2"
                style={{ display: 'inline' }}
                color={`#000000${price === 0 ? theme.palette.other.opacityDisabled : ''}`}
              >
                Paid Only
              </Typography>
            }
          />
          <FormControlLabel
            style={{ display: 'block' }}
            control={
              <Checkbox
                checked={attendingOnly}
                name="checkedD"
                color="secondary"
                style={{ padding: '4px 8px' }}
                onClick={() => setAttendingOnly(!attendingOnly)}
              />
            }
            label={
              <Typography variant="body2" style={{ display: 'inline' }}>
                Attending Only
              </Typography>
            }
          />

          <AdminDownloadButton onClick={tryToCopyEmails} label="Copy" style={{ marginTop: isMobile ? '8px' : '' }} />
        </FlexRowItem>
        <Divider style={{ margin: '8px 0' }} />
        <FlexRowItem>
          <ToolsRow
            title="Download Booking Data"
            subtitle={"Download an Excel Sheet with all the participants' information."}
          />
          <AdminDownloadButton
            onClick={handleDownload}
            loading={exportEvent.isPending}
            style={{ marginTop: isMobile ? '8px' : '' }}
          />
        </FlexRowItem>
      </FlexRow>
    </>
  );
};

export default AdminEventTools;
