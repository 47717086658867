import React from 'react';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useDynamicTheme from 'hooks/useDynamicTheme';

export interface OptionsMenuButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const OptionsMenuButton: React.FC<OptionsMenuButtonProps> = ({ icon, label, onClick, disabled }) => {
  const theme = useDynamicTheme();

  return (
    <MenuItem
      onClick={onClick}
      style={{
        color: !disabled ? theme.palette.secondary.main : '',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      disabled={disabled}
    >
      {icon}
      <Typography variant="caption">{label}</Typography>
    </MenuItem>
  );
};

export default OptionsMenuButton;
