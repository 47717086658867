import React from 'react';
import { LoadingButton } from '@mui/lab';

export interface AdminDownloadButtonProps {
  onClick: () => void;
  label?: string;
  loading?: boolean;
  style?: React.CSSProperties;
}

const AdminDownloadButton: React.FC<AdminDownloadButtonProps> = ({ onClick, label = 'Download', loading, style }) => {
  return (
    <LoadingButton
      onClick={onClick}
      loading={loading}
      variant="contained"
      color="secondary"
      style={{ width: '105px', ...style }}
    >
      {label}
    </LoadingButton>
  );
};

export default AdminDownloadButton;
