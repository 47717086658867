import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import { Divider, Typography } from '@mui/material';
import EventGroupsList from './containers/EventGroupsList';

const GroupsOverview = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <Typography variant={isMobile ? 'h5' : 'h4'} style={isMobile ? { marginTop: '16px', marginBottom: '10px' } : {}}>
        Event Groups
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} style={{ marginTop: 0, marginBottom: '12px' }}>
        Create event groups to limit the amount of bookable tickets and/or to inform users about coherent events. Event
        groups can be shown as “Event Category” on the event booking page.
      </Typography>
      <Divider style={{ marginTop: '16px' }} />
      <EventGroupsList />
    </>
  );
};

export default GroupsOverview;
