import React, { useMemo } from 'react';
import { IconButton, Theme } from '@mui/material';
import { closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const SnackBarCloseIcon = ({ palette }: Theme, type: 'success' | 'warning' | 'error' | 'info', snackbarId: any) => {
  const contrastText = useMemo(() => palette[type].contrastText, []);

  return (
    <IconButton onClick={() => closeSnackbar(snackbarId)} size="small">
      <CloseIcon
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="CloseIcon"
        htmlColor={contrastText}
      />
    </IconButton>
  );
};

export default SnackBarCloseIcon;
