import React from 'react';

import { Close, DeleteOutline, Edit } from '@mui/icons-material';
import { TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CustomTableCell from 'components/customTableCell/CustomTableCell';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { UserAccessEditable } from 'pages/admin/access/hooks/useUsersAccess';
import UserRoleMenu from './UserRoleMenu';

interface Props {
  user: UserAccessEditable;
  roles: {
    roleId: number;
    title: string;
  }[];
  setUserRole: (accountId: number, roleId: number) => void;
  setUserEditing: (accountId: number) => void;
}

const BodyDesktop: React.FC<Props> = ({ user, roles, setUserRole, setUserEditing }) => {
  const theme = useDynamicTheme();

  return (
    <TableRow>
      <CustomTableCell>{user.firstName}</CustomTableCell>
      <CustomTableCell>{user.lastName}</CustomTableCell>
      <CustomTableCell>{user.email}</CustomTableCell>
      <CustomTableCell>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {roles.find((role) => role.roleId === user.roleId)?.title || 'No Role'}
            {user.editing ? (
              <UserRoleMenu
                options={roles.map((role) => ({
                  label: role.title,
                  onclick: () => {
                    setUserRole(user.accountId, role.roleId);
                  },
                }))}
              />
            ) : null}
          </div>
        </div>
      </CustomTableCell>
      <CustomTableCell>
        {!user.editing ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => setUserEditing(user.accountId)} size="small" aria-label="Edit role">
              <Edit fontSize="inherit" color="secondary" />
            </IconButton>
          </div>
        ) : (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <IconButton
              onClick={() => null}
              size="small"
              aria-label="Delete role"
              style={{
                color: `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}`,
              }}
              disabled
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>

            <IconButton
              onClick={() => setUserEditing(user.accountId)}
              size="small"
              aria-label="Close delete option and show edit button"
            >
              <Close fontSize="inherit" color="primary" />
            </IconButton>
          </div>
        )}
      </CustomTableCell>
    </TableRow>
  );
};

export default BodyDesktop;
