import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import getAdminEventsQuery from 'queries/admin-events/admin-events';
import { getUserAddBookingCheckQuery } from 'queries/admin-users/admin-add-booking-check';
import useAddUserBooking from 'queries/admin-users/useAddUserBooking';
import { UserTicket } from 'queries/admin-users/admin-user-tickets';
import { UserEvent } from 'types/types';
import CheckOverview from '../../../components/CheckOverview';
import GeneralSettings from '../../GeneralSettingsContainer';
import ChecklistContainer from './ChecklistContainer';
import SubpageModal from '/components/subpageModal/SubpageModal';

interface Props {
  userId: number;
  isOpen: boolean;
  closeModal: () => void;
  bookedEvents: UserTicket[];
}

const AddBookingModalContainer: React.FC<Props> = ({ userId, isOpen, closeModal, bookedEvents }) => {
  const isMobile = useIsMobile();

  const [isPaid, setIsPaid] = useState(false);
  const [isAttending, setIsAttending] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<UserEvent | null>(null);

  const projectId = useProjectId();
  const { data: events } = useQuery(getAdminEventsQuery({ projectId }));
  const { data: eventCheck } = useQuery(
    getUserAddBookingCheckQuery({ projectId, userId, eventId: selectedEvent?.eventId || 0 })
  );

  const handleCloseModal = () => {
    setIsPaid(false);
    setIsAttending(false);
    setIsExpanded(false);
    setSelectedEvent(null);
    closeModal();
  };

  const addBooking = useAddUserBooking(handleCloseModal);

  const handleEventChange = (eventId: number) => {
    setSelectedEvent(events?.find((event: UserEvent) => event.eventId === eventId) || null);
  };

  const handleSubmit = () => {
    addBooking.mutate({
      projectId,
      userId,
      eventId: selectedEvent?.eventId || 0,
      isPaid,
      isAttending,
    });
  };

  const returnSuccessfullCount = () => {
    if (!eventCheck) return 0;

    return (
      (eventCheck.isFull.isOk ? 1 : 0) +
      (eventCheck.isOverlapping.isOk ? 1 : 0) +
      (eventCheck.isFiltered.isOk ? 1 : 0) +
      (eventCheck.isMaxGroups.isOk ? 1 : 0)
    );
  };

  const checkUserAlreadyBooked = () => {
    if (!bookedEvents) return false;
    return bookedEvents.some((event: UserTicket) => event.eventId === selectedEvent?.eventId);
  };

  if (!userId || bookedEvents === undefined) return <CircularProgress color="secondary" />;

  return (
    <>
      <SubpageModal
        hideHeader={!isMobile}
        title="Add Event to User"
        maxWidth={918}
        isOpen={isOpen}
        reset={() => null}
        close={handleCloseModal}
        hideReset
        variant="thick"
      >
        <div style={{ display: 'flex', marginTop: '20px', marginBottom: '22px' }}>
          <div style={{ flex: 1 }}>
            <Typography variant={isMobile ? 'h6' : 'h5'}>Add an event to user</Typography>
            <Typography variant="body2" color="textSecondary">
              Search for an event and add it to an user.
            </Typography>
          </div>
          {!isMobile && (
            <IconButton onClick={handleCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <GeneralSettings userId={userId} />
        <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
        <Typography variant="h6" style={{ marginBottom: '8px' }}>
          Choose event
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          value={selectedEvent || null}
          options={events || []}
          getOptionLabel={(event) => event.displayTitle}
          onChange={(_, newInputValue) => {
            handleEventChange(newInputValue?.eventId || 0);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search event" fullWidth variant="outlined" color="secondary" />
          )}
        />
        <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
        {selectedEvent && eventCheck && (
          <>
            <Typography variant="h6">Event information</Typography>
            <Typography variant="body2" color="textSecondary">
              Coming soon (TM)
            </Typography>
            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
            <CheckOverview
              isMobile={isMobile}
              success={returnSuccessfullCount() === 4}
              setExpanded={() => setIsExpanded(!isExpanded)}
              isExpanded={isExpanded}
              description={`${returnSuccessfullCount()}/4 successful`}
            />
            <Collapse in={isMobile ? isExpanded : true} timeout="auto">
              {isMobile && <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />}
              <ChecklistContainer checks={eventCheck} />
            </Collapse>
            <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
            <Typography variant="h6">Status</Typography>
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isPaid}
                  name="checkedA"
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsPaid(!isPaid)}
                  color="secondary"
                />
              }
              label={
                <Typography variant="body2" style={{ display: 'inline' }}>
                  Mark as paid
                </Typography>
              }
            />
            <FormControlLabel
              style={{ display: 'block' }}
              control={
                <Checkbox
                  checked={isAttending}
                  name="checkedA"
                  style={{ padding: '4px 8px' }}
                  onClick={() => setIsAttending(!isAttending)}
                  color="secondary"
                />
              }
              label={
                <Typography variant="body2" style={{ display: 'inline' }}>
                  Mark as attending
                </Typography>
              }
            />
            <Button
              fullWidth
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              disabled={checkUserAlreadyBooked() || addBooking.isPending}
              style={{ marginTop: '16px', marginBottom: '25px' }}
            >
              {checkUserAlreadyBooked() ? 'User already booked' : 'Confirm'}
            </Button>
          </>
        )}
      </SubpageModal>
    </>
  );
};

export default AddBookingModalContainer;
