import React from 'react';

import { AdminHeader } from 'components/adminHeader/AdminHeader';
import useIsMobile from 'hooks/useIsMobile';

interface Props {
  isCreationMode?: boolean;
}

const ManageEventHeader: React.FC<Props> = ({ isCreationMode }) => {
  const isMobile = useIsMobile();

  return (
    <AdminHeader
      isMobile={isMobile}
      title={isCreationMode ? 'Create Event' : 'Edit Event'}
      subtitle="Enter the information for the event in the fields below. General and booking settings must be filled out. Settings can be changed later as well."
    />
  );
};

export default ManageEventHeader;
