import useOpenFeedback from './useOpenFeedback';

/**
 * Hook to copy a text to clipboard
 * @returns copy
 */
const useCopyClipboard = () => {
  const feedback = useOpenFeedback();

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
    feedback.openSuccess('Copied to clipboard');
  };

  return {
    copy,
  };
};

export default useCopyClipboard;
