import { LoadingButton } from '@mui/lab';
import React from 'react';

const baseButtonStyle: React.CSSProperties = {
  marginBottom: '16px',
  marginTop: '16px',
};

const fixedButtonStyle: React.CSSProperties = {
  ...baseButtonStyle,
  width: '97px',
};

interface Props {
  click: () => void;
  label: string;
  fixedWidth?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

const ButtonComponent: React.FC<Props> = ({ click, label, fixedWidth, disabled, fullWidth, loading }) => {
  return (
    <LoadingButton
      type="submit"
      variant="contained"
      color="secondary"
      onClick={click}
      style={fixedWidth ? fixedButtonStyle : baseButtonStyle}
      disabled={disabled || false}
      fullWidth={fullWidth || false}
      loading={loading}
    >
      {label}
    </LoadingButton>
  );
};

export default ButtonComponent;
