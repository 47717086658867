import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  text: React.ReactNode;
}

const CheckboxSubtitle: React.FC<Props> = ({ text }) => (
  <Typography
    color="textSecondary"
    variant="caption"
    style={{ flexBasis: '100%', marginLeft: '35px', marginTop: '-10px' }}
  >
    {text}
  </Typography>
);

export default CheckboxSubtitle;
