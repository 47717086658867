import React from 'react';
import { EditEventFormCategory } from '../../../events/sub-pages/edit/types/FormTypes';

interface CustomPagesCategoryRenderProps {
  categories: EditEventFormCategory[];
  activeTab: string;
}

const CustomPagesCategoryRender: React.FC<CustomPagesCategoryRenderProps> = ({ categories, activeTab }) => (
  <>{categories.find((chip) => chip.path === activeTab)?.element}</>
);

export default CustomPagesCategoryRender;
