/* eslint-disable react/no-array-index-key */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CheckboxEvent } from './CheckboxEvent';
import { sortEventsByDate, sortEventsByChecked } from './utils';

interface StyleProps {
  isMobile: boolean;
  listHeight: number;
}

interface CheckBoxEvent {
  id: number;
  name: string;
  date: Date;
}

const useStyles = makeStyles({
  root: {
    height: (props: StyleProps) => `${props.listHeight}px`,
    overflow: 'scroll',
  },
  desktopRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '-8px',
  },
  list: {
    paddingRight: (props) => (props.isMobile ? '8px' : '16px'),
    width: (props) => (props.isMobile ? '' : '45%'),
  },
  desktop: {
    flex: '1',
    width: '50%',
  },
  border: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

interface Props {
  checked?: number[];
  events?: CheckBoxEvent[];
  onClick: (id: number) => void;
  isMobile?: boolean;
  listHeight?: number;
  isEditing?: boolean;
}

/**
 * This component is used to render a list of checkbox events.
 * it sorts list by ones that are checked.
 */
export const CheckboxEventList: React.FC<Props> = ({
  checked = [],
  events = [],
  onClick,
  isMobile = false,
  listHeight = 300,
  isEditing = false,
}) => {
  const classes = useStyles({ isMobile, listHeight });

  // sort by ones that have checked first
  const sortedEvents = sortEventsByChecked(sortEventsByDate(events), checked);

  /**
   * Renders a checkbox event.
   *
   * @param {object} event event to render
   * @returns an event
   */
  const renderCheckboxEvent = (event: CheckBoxEvent) => {
    return (
      <CheckboxEvent
        name={event.name}
        checked={checked.includes(event.id)}
        onChange={() => onClick(event.id)}
        isMobile={isMobile}
        date={event.date}
        disabled={!isEditing}
      />
    );
  };

  /**
   * Renders a checkbox event cell that is used
   * for desktop rendering.
   *
   * @param {*} event event to render
   * @param {*} index index of event
   * @param {*} lineLength index of row
   * @param {*} ITEMS_PER_ROW max items per row
   * @returns a desktop checkbox event cell
   */
  const renderCheckboxEventCell = (
    event: React.JSX.Element,
    index: number,
    lineLength: number,
    ITEMS_PER_ROW: number
  ) => {
    return (
      <div
        key={`event-index-${index}`}
        className={`
          ${classes.list} 
          ${classes.desktop}
          ${lineLength + 1 < ITEMS_PER_ROW && classes.border}
        `}
      >
        {event}
      </div>
    );
  };

  /**
   * renders a checkbox event row for desktop
   * view.
   *
   * @param {*} renderLines length of render lines
   * @param {*} lineToRender row to render
   * @returns
   */
  const renderCheckboxEventRow = (renderLinesLength: number, lineToRender: Array<React.JSX.Element>) => {
    return (
      <div key={renderLinesLength} className={classes.desktopRow}>
        {lineToRender}
        {
          // makes sure that the last element is not spanning over whole width.
          lineToRender.length === 1 && <div className={`${classes.list} ${classes.desktop}`} />
        }
      </div>
    );
  };

  /**
   * Renders events horizontally two by two
   *
   * @param {*} events list of events
   * @returns list of events two by two
   */
  const renderDesktop = () => {
    const renderLines: Array<React.JSX.Element> = [];
    let currentLine: Array<React.JSX.Element> = [];
    const ITEMS_PER_ROW = 2;

    sortedEvents.forEach((event: CheckBoxEvent, index: number) => {
      currentLine.push(renderCheckboxEventCell(renderCheckboxEvent(event), index, currentLine.length, ITEMS_PER_ROW));

      if (currentLine.length === ITEMS_PER_ROW || index + 1 === events.length) {
        renderLines.push(renderCheckboxEventRow(renderLines.length, currentLine));
        currentLine = [];
      }
    });

    return <div>{renderLines}</div>;
  };

  /**
   * Renders events in a single list
   *
   * @param {*} events list of events
   * @returns events in a single list
   */
  const renderMobile = () => {
    return (
      <div className={classes.list}>
        {sortedEvents.map((event: CheckBoxEvent, index: number) => (
          <div key={index}>{renderCheckboxEvent(event)}</div>
        ))}
      </div>
    );
  };

  return <div className={classes.root}>{isMobile ? renderMobile() : renderDesktop()}</div>;
};

export default CheckboxEventList;
