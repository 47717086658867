import React, { useMemo } from 'react';

import { CircularProgress, Table, TableBody } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FabOrButton from 'components/fabOrButton/FabOrButton';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import getAdminEventFilterQuery from 'queries/admin-events/admin-event-filter';
import { useNavigate } from 'react-router-dom';
import FilterDeleteDialog from '../../components/FilterDeleteDialog';
import EventFiltersCard from '../components/EventFiltersCard';
import EventFiltersHead from '../components/EventFiltersHead';
import EventFiltersRow from '../components/EventFiltersRow';
import useDeleteFilter from '../hooks/useDeleteFilter';
import { EventFilterRow } from '../types/types';

const EventFiltersList: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const projectId = useProjectId();
  const eventFilters = useQuery(getAdminEventFilterQuery({ projectId }));
  const deleteFilter = useDeleteFilter({});

  const handleEdit = (id: number) => {
    navigate(`edit/${id}`);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDelete = (id: number, name: string) => {
    deleteFilter.handleDeleteFilter(id, name);
  };

  const mappedFilters: EventFilterRow[] = useMemo((): EventFilterRow[] => {
    if (!eventFilters.data) return [];
    return eventFilters.data.map((filter) => {
      return {
        eventFilter: filter,
        onEdit: () => handleEdit(filter.id),
        onDelete: () => handleDelete(filter.id, filter.name),
      };
    });
  }, [eventFilters.data]);

  if (!eventFilters.data) return <CircularProgress color="secondary" />;

  return (
    <>
      <FilterDeleteDialog
        name={deleteFilter.deleteFilterName}
        confirmOnClick={deleteFilter.handleConfirmDeleteFilter}
        cancelOnClick={deleteFilter.handleCancelDeleteFilter}
        isOpen={deleteFilter.deleteDialogOpen}
        buttonDisable={deleteFilter.isLoading}
      />
      {isMobile ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingTop: '8px' }}>
          {mappedFilters.map((filter) => (
            <EventFiltersCard key={filter.eventFilter.id} {...filter} />
          ))}
        </div>
      ) : (
        <Table>
          <EventFiltersHead />
          <TableBody>
            {mappedFilters.map((filter) => (
              <EventFiltersRow key={filter.eventFilter.id} {...filter} />
            ))}
          </TableBody>
        </Table>
      )}
      <FabOrButton onClick={() => navigate('create')} />
    </>
  );
};

export default EventFiltersList;
