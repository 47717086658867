import { Popover } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const ColorPopup: React.FC<Props> = ({ children, anchorEl, handleClose }) => {
  const open = Boolean(anchorEl);
  const id = open ? 'color-picker' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: -15,
        horizontal: -12,
      }}
      sx={{
        '& .MuiPopover-paper': {
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        },
      }}
    >
      {children}
    </Popover>
  );
};

export default ColorPopup;
