import React, { useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import { Theme } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { roleFilters } from 'pages/admin/access/hooks/useUsersAccess';

/**
 * Styling for menu
 */
const useStyles = makeStyles((t: Theme) => ({
  arrow: {
    width: 20,
    height: 20,
    color: `${t.palette.secondary.main}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItemHover: {
    '&:hover': {
      color: t.palette.secondary.main,
    },
  },
}));

interface Props {
  filteredRoles: number[];
  setFilteredRoles: (roles: number[]) => void;
}

const RoleFilter: React.FC<Props> = ({ filteredRoles, setFilteredRoles }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useDynamicTheme();

  /**
   * Opening the menu
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const changeCheckbox = (roleId: number) => {
    let tempFilter: number[] = [...filteredRoles];
    const exists: number = tempFilter.indexOf(roleId);
    if (exists !== -1) {
      tempFilter = tempFilter.filter((role) => role !== roleId);
    } else {
      tempFilter.push(roleId);
    }
    setFilteredRoles(tempFilter);
  };

  /**
   * Closes the menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FilterListIcon
        aria-controls="userRoleMenu"
        aria-haspopup="true"
        className={classes.arrow}
        onClick={handleClick}
      />
      <Menu id="userRoleMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {roleFilters.map((filter) => (
          <MenuItem
            key={`role-filter-${filter.roleId}`}
            selected={filteredRoles.includes(filter.roleId)}
            onClick={() => changeCheckbox(filter.roleId)}
            sx={{
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.secondary.main}${theme.palette.overlay.opacity.selected}`,
              },
              '&.Mui-selected:hover': {
                backgroundColor: `${theme.palette.secondary.main}${theme.palette.overlay.opacity.hover}`,
              },
            }}
          >
            <FormControlLabel
              color="secondary"
              control={
                <Checkbox
                  checked={filteredRoles.includes(filter.roleId)}
                  style={{ padding: 0, marginRight: '16px' }}
                  color="secondary"
                />
              }
              label={filter.title}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RoleFilter;
