import { useQuery } from '@tanstack/react-query';
import useProjectId from 'hooks/useProjectId';
import { getAdminEventGroupQuery, useSaveGroup } from 'queries/admin-events/admin-event-group';
import getAdminEventsQuery from 'queries/admin-events/admin-events';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Errors {
  name: string;
  ticketLimit: string;
}

const useEditGroup = () => {
  const projectId = useProjectId();
  const { eventGroupId } = useParams<{ eventGroupId?: string }>();
  const adminEvents = useQuery(getAdminEventsQuery({ projectId }));
  const eventGroups = useQuery(getAdminEventGroupQuery({ projectId }));

  const isCreationMode = !eventGroupId;

  const [groupName, setGroupName] = useState('');
  const [editing, setEditing] = useState(false);
  const [eventSearch, setEventSearch] = useState('');
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [isBroadcasted, setIsBroadcasted] = useState(false);
  const [hasTicketLimit, setHasTicketLimit] = useState(false);
  const [ticketLimit, setTicketLimit] = useState('');
  const [errors, setErrors] = useState<Errors>({
    name: '',
    ticketLimit: '',
  });

  const saveGroup = useSaveGroup();

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  const handleEventSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventSearch(e.target.value);
  };

  const handleEventSelect = (id: number) => {
    if (selectedEvents.includes(id)) {
      setSelectedEvents(selectedEvents.filter((event) => event !== id));
    } else {
      setSelectedEvents([...selectedEvents, id]);
    }
  };

  const handleBroadcastChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsBroadcasted(e.target.checked);
  };

  const handleTicketLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasTicketLimit(e.target.checked);
  };

  const handleTicketLimitNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let ticketError = '';
    if (hasTicketLimit && e.target.value.length === 0) {
      ticketError = 'Ticket limit is required';
    }

    if (hasTicketLimit && Number.isNaN(parseInt(e.target.value, 10))) {
      ticketError = 'Ticket limit must be a number';
    }

    if (parseInt(e.target.value, 10) < 0) {
      ticketError = 'Ticket limit must be a positive number';
    }

    if (ticketError.length > 0) {
      setErrors({
        ...errors,
        ticketLimit: ticketError,
      });
    } else {
      setErrors({
        ...errors,
        ticketLimit: '',
      });
    }

    setTicketLimit(e.target.value);
  };

  const handleSubmit = () => {
    if (groupName.length === 0) {
      setErrors({
        ...errors,
        name: 'Event group name is required',
      });
      return;
    }

    if (hasTicketLimit && ticketLimit.length === 0) {
      setErrors({
        ...errors,
        ticketLimit: 'Ticket limit is required',
      });
      return;
    }

    if (hasTicketLimit && Number.isNaN(parseInt(ticketLimit, 10))) {
      setErrors({
        ...errors,
        ticketLimit: 'Ticket limit must be a number',
      });
      return;
    }

    if (hasTicketLimit && parseInt(ticketLimit, 10) < 1) {
      setErrors({
        ...errors,
        ticketLimit: 'Ticket limit must be greater than zero',
      });
      return;
    }

    setErrors({
      name: '',
      ticketLimit: '',
    });

    saveGroup.mutate({
      isCreationMode,
      selectedEvents,
      isBroadcasted,
      hasTicketLimit,
      ticketLimit,
      groupName,
      eventGroupId,
    });
  };

  const handleEditing = () => {
    if (isCreationMode) {
      handleSubmit();
      return;
    }

    if (!editing) {
      setEditing(true);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!adminEvents.data) return;
    if (!eventGroups.data) return;
    if (isCreationMode) return;

    const currentGroup = eventGroups.data.find((group) => group.id === parseInt(eventGroupId as string, 10));

    if (!currentGroup) return;

    // fill form with data
    setGroupName(currentGroup.name);
    setSelectedEvents(currentGroup.eventIDs);
    setIsBroadcasted(currentGroup.isBroadcasted);
    setHasTicketLimit(currentGroup.ticketLimit > 0);
    setTicketLimit(currentGroup.ticketLimit.toString());
  }, [adminEvents.data, eventGroups.data, isCreationMode]);

  return {
    eventsData: adminEvents.data,
    groupsData: eventGroups.data,
    eventGroupId,
    isCreationMode,
    groupName,
    selectedEvents,
    handleGroupNameChange,
    eventSearch,
    handleEventSearchChange,
    handleEventSelect,
    isBroadcasted,
    handleBroadcastChange,
    hasTicketLimit,
    handleTicketLimitChange,
    ticketLimit,
    handleTicketLimitNumberChange,
    errors,
    editing: editing || isCreationMode,
    handleEditing,
  };
};

export default useEditGroup;
