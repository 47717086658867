import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CustomizationGeneral from './sub-pages/pages/CustomizationGeneral';
import CustomizationOverview from './sub-pages/pages/CustomizationOverview';
import CustomizationPages from './sub-pages/pages/CustomizationPages';
import CustomizationTheme from './sub-pages/theme/CustomizationTheme';

const Customization = () => {
  return (
    <>
      <Routes>
        <Route path="/general" element={<CustomizationGeneral />} />
        <Route path="/theme" element={<CustomizationTheme />} />
        <Route path="/pages" element={<CustomizationPages />} />
        <Route path="*" element={<CustomizationOverview />} />
      </Routes>
    </>
  );
};

export default Customization;
