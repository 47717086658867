import React from 'react';

import {
  CircularProgress,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from 'components/searchBar/SearchBar';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import usePagination from 'hooks/usePagination';
import BodyDesktop from 'pages/admin/access/components/BodyDesktop';
import BodyMobile from 'pages/admin/access/components/BodyMobile';
import HeaderDesktop from 'pages/admin/access/components/HeaderDesktop';
import HeaderMobile from 'pages/admin/access/components/HeaderMobile';
import useUsersAccess, { roleFilters } from './hooks/useUsersAccess';

const Access: React.FC = () => {
  const theme = useDynamicTheme();
  const isMobile = useIsMobile();
  const usersAccess = useUsersAccess();
  const paginatedUsers = usePagination(usersAccess.users, 10);

  if (!usersAccess.users) return <CircularProgress color="secondary" />;

  return (
    <>
      <Typography variant={isMobile ? 'h4' : 'h5'} style={{ margin: '16px auto' }}>
        Access Management
      </Typography>
      <Typography variant={isMobile ? 'body1' : 'body2'}>
        Change permissions of users in the project. Higher levels increase access to more personal data of all users.
      </Typography>
      <Divider style={{ margin: '16px auto' }} />
      <SearchBar setSelected={usersAccess.setSearchedUser} projectId={usersAccess.projectId} />
      <TableContainer style={{ marginTop: '20px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {isMobile ? (
                <HeaderMobile
                  roleSortToggle={usersAccess.sortUsersByRole}
                  roleSortUp={usersAccess.sortUp}
                  filteredRoles={usersAccess.filteredRoles}
                  setFilteredRoles={usersAccess.setFilteredRoles}
                />
              ) : (
                <HeaderDesktop
                  roleSortToggle={usersAccess.sortUsersByRole}
                  roleSortUp={usersAccess.sortUp}
                  filteredRoles={usersAccess.filteredRoles}
                  setFilteredRoles={usersAccess.setFilteredRoles}
                />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.currentElements.map((user) =>
              isMobile ? (
                <BodyMobile
                  key={`user--mobile--${user.accountId}--${user.roleId}`}
                  user={user}
                  roles={roleFilters}
                  setUserRole={usersAccess.setUserRole}
                  setUserEditing={usersAccess.toggleUserEditing}
                />
              ) : (
                <BodyDesktop
                  key={`user--desktop--${user.accountId}--${user.roleId}`}
                  user={user}
                  roles={roleFilters}
                  setUserRole={usersAccess.setUserRole}
                  setUserEditing={usersAccess.toggleUserEditing}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: useMediaQuery(theme.breakpoints.up('sm')) ? 'flex-end' : 'center',
        }}
      >
        <Pagination
          count={paginatedUsers.totalPages}
          color="secondary"
          page={paginatedUsers.currentPage}
          onChange={(_: any, num: number) => paginatedUsers.paginate(num)}
          style={{ margin: '16px 0' }}
          size="small"
        />
      </div>
    </>
  );
};

export default Access;
