import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AdminEventCheck, { AdminEventCheckItemProps } from 'components/eventCard/AdminEventCheck';
import EventCard from 'components/eventCard/EventCardBase';
import { buildEventsContentProps } from 'components/eventCard/utils';
import React, { useState } from 'react';
import { EventBookings } from 'types/types';
import AdminEventTools from './AdminEventTools';
import EventAdminContent from '/components/eventCard/EventAdminContent';
import TabChip from '/components/tabChip/TabChip';

const useStyles = makeStyles(() => ({
  chipContainer: {
    maxWidth: '90vw',
    display: 'inline-block',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    position: 'relative',
    overflowX: 'scroll',
    marginTop: '8px',
  },
  divider: {
    height: '8px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  mainName: {
    marginBottom: '16px !important',
    fontWeight: 400,
  },
  tabs: {
    minWidth: '370px',
    overflow: 'hidden',
  },
}));

// list of chips
const tabs = [
  { label: 'General', disabled: false },
  { label: 'Preferences', disabled: false },
  { label: 'Tools', disabled: false },
];

export interface AdminEventCardTrimmedProps {
  title: string;
  subtitle: string;
  eventId: number;
  currentEventBooked: number;
  currentEventAttended: number;
  price: number;
  maxEventAttended: number;
  paidPeople: number;
  isMobile: boolean;
  checks: AdminEventCheckItemProps[];
  bookings?: EventBookings[];
}

const AdminEventCardTrimmed: React.FC<AdminEventCardTrimmedProps> = ({
  title,
  subtitle,
  eventId,
  currentEventBooked,
  currentEventAttended,
  maxEventAttended,
  price,
  paidPeople,
  isMobile,
  checks,
  bookings = [],
}) => {
  const classes = useStyles();
  const [category, setCategory] = useState(0);
  return (
    <EventCard
      title={title}
      subtitle={subtitle}
      isMobile={isMobile}
      elevation={0}
      disableCollapse
      noPadding
      statistics={
        <>
          <Divider style={{ marginBottom: '16px', marginTop: '8px' }} />
          <EventAdminContent
            data={buildEventsContentProps({
              currentEventBooked,
              currentEventAttended,
              maxEventAttended,
              paidPeople,
              price,
              isMobile,
            })}
            isMobile={isMobile}
          />
        </>
      }
    >
      <div className={classes.chipContainer}>
        <div className={classes.tabs}>
          {tabs.map((tab, i) => (
            <TabChip
              key={`tab-label-${tab.label}`}
              label={tab.label}
              selected={i === category}
              style={{ marginRight: '8px' }}
              onClick={() => setCategory(i)}
              disabled={tab.disabled}
            />
          ))}
        </div>
      </div>
      <Divider style={{ margin: isMobile ? '8px auto' : '12px auto' }} />
      {category === 0 ? <AdminEventCheck items={checks} /> : null}
      {category === 1 ? <>Coming soon(TM)</> : null}
      {category === 2 ? (
        <AdminEventTools eventId={eventId} eventTitle={title} bookings={bookings} price={price} />
      ) : null}
      <Divider style={{ marginTop: '16px' }} />
    </EventCard>
  );
};

export default AdminEventCardTrimmed;
