import React from 'react';
import { Typography, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';

interface StyleProps {
  isMobile: boolean;
  disabled: boolean;
}

interface Props {
  name?: string;
  checked?: boolean;
  onChange?: () => void;
  isMobile?: boolean;
  date?: Date;
  disabled?: boolean;
}

const useStyles = makeStyles({
  root: {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: (props: StyleProps) => (props.isMobile ? '86vw' : '45vw'),
    color: (props) => (props.disabled ? 'rgba(0, 0, 0, 0.3)' : undefined),
  },
  checkboxName: {
    alignItems: 'center',
    flex: 1,
    minWidth: 0,
    overflow: 'hidden',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    padding: 0,
  },
  date: {
    flexBasis: 'max-content',
  },
  name: {
    marginLeft: (props) => (props.isMobile ? '8px' : '16px'),
    whiteSpace: 'wrap',
    display: 'inline',
  },
  fcl: {
    marginLeft: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const CheckboxEvent: React.FC<Props> = ({
  name = '',
  checked = false,
  onChange = () => null,
  isMobile = false,
  date = new Date(),
  disabled = false,
}) => {
  const classes = useStyles({ isMobile, disabled });

  // take date and return YY-MM-DD
  const getDate = () => {
    const year = date.getFullYear().toString().slice(-2);
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? `0${month}` : month;
    const day = date.getDate();
    const dayString = day < 10 ? `0${day}` : day;
    return `${year}-${monthString}-${dayString}`;
  };

  return (
    <div className={classes.root}>
      <div className={classes.checkboxName}>
        <FormControlLabel
          className={classes.fcl}
          control={
            <Checkbox
              color="secondary"
              checked={checked}
              onChange={onChange}
              className={classes.checkbox}
              disabled={disabled}
              inputProps={{ 'aria-label': `${name} on date ${getDate()}` }}
            />
          }
          label={
            <Typography variant="body2" className={classes.name}>
              {name}
            </Typography>
          }
        />
      </div>
      <Typography variant="body2" className={classes.date}>
        {getDate()}
      </Typography>
    </div>
  );
};

export default CheckboxEvent;
