import { useMutation } from '@tanstack/react-query';
import saveAs from 'file-saver';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import * as Backend from 'queries/BackendRequest';

interface MutationProps {
  eventId: number;
  fileName: string;
}

const useExportEvent = () => {
  const feedback = useOpenFeedback();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: async ({ eventId }: MutationProps) => {
      const resp = await Backend.requestBlob('GET', `admin/${projectId}/export_single/${eventId}`);
      return resp;
    },
    onSuccess: (response, variables) => {
      const file = new Blob([response.data]);
      saveAs(file, variables.fileName);
      feedback.openSuccess('Download successful');
    },
  });
};

export default useExportEvent;
