import * as Backend from 'queries/BackendRequest';

interface GetUserFoodPrefsParams {
  projectId: string | number;
  userId: string | number;
}

export interface UserPreferences {
  drinking: boolean;
  foodPrefs: string[];
  allergies: string[];
  other: string;
}

const getHasAlcohol = async ({ projectId, userId }: GetUserFoodPrefsParams): Promise<boolean> => {
  const result = await Backend.request<string[]>('get', `/admin/${projectId}/food/${userId}/1`);
  const isDrinking = result.data.data[0];
  return isDrinking === 'Alcohol';
};

const getFoodPreferences = async ({ projectId, userId }: GetUserFoodPrefsParams): Promise<string[]> => {
  const result = await Backend.request<string[]>('get', `/admin/${projectId}/food/${userId}/2`);
  return result.data.data;
};

const getFoodAllergies = async ({ projectId, userId }: GetUserFoodPrefsParams): Promise<string[]> => {
  const result = await Backend.request<string[]>('get', `/admin/${projectId}/food/${userId}/3`);
  return result.data.data;
};

const getFoodOptions = async ({ projectId }: GetUserFoodPrefsParams): Promise<string[]> => {
  const result = await Backend.request('get', `/user/${projectId}/food-prefs/2`);
  return result.data.data;
};

const formatPreferences = (
  isDrinking: boolean,
  foodPreferences: string[],
  foodAllergies: string[],
  foodOptions: string[]
): UserPreferences => {
  const filterer = foodOptions;
  if (filterer.length) filterer.unshift(filterer.pop() as string);

  const preferenceList = filterer.slice(0, 7);

  const allergies: string[] = [];
  const foodprefs: string[] = [];

  foodPreferences.forEach((opt) => {
    if (preferenceList.includes(opt)) foodprefs.push(opt);
    else allergies.push(opt);
  });

  return {
    drinking: isDrinking,
    foodPrefs: foodprefs,
    allergies,
    other: foodAllergies[0],
  };
};

const getUserPreferences = async ({ projectId, userId }: GetUserFoodPrefsParams): Promise<UserPreferences> => {
  const [isDrinking, foodPreferences, foodAllergies, foodOptions] = await Promise.all([
    getHasAlcohol({ projectId, userId }),
    getFoodPreferences({ projectId, userId }),
    getFoodAllergies({ projectId, userId }),
    getFoodOptions({ projectId, userId }),
  ]);

  return formatPreferences(isDrinking, foodPreferences, foodAllergies, foodOptions);
};

export const getUserPreferencesQuery = ({ projectId, userId }: GetUserFoodPrefsParams) => ({
  queryKey: ['getUserPreferencesQuery', projectId, userId],
  queryFn: () => getUserPreferences({ projectId, userId }),
  enabled: !!projectId && !!userId,
});

export default getUserPreferencesQuery;
