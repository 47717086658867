import * as Backend from 'queries/BackendRequest';
import { GetGeneralOptionsResponse, OptionsResponse } from 'types/types';
import { getStudyOptions } from './user-study';

const getGeneralOptions = async () => {
  const response = await Backend.request<GetGeneralOptionsResponse>('GET', '/account/options');
  return response;
};

export const getOptions = async (): Promise<OptionsResponse> => {
  const general = await getGeneralOptions();
  const study = await getStudyOptions();
  return { general: general.data.data, study: study.data.data };
};

const getUserOptionsQuery = () => ({
  queryKey: ['getUserOptionsQuery'],
  queryFn: () => getOptions(),
});

export default getUserOptionsQuery;
