import React from 'react';
import CheckList from '../../../components/CheckList';
import useIsMobile from '/hooks/useIsMobile';
import { CheckEventToUserResponse } from '/queries/admin-users/admin-add-booking-check';

interface ChecklistContainerProps {
  checks?: CheckEventToUserResponse;
}

const ChecklistContainer: React.FC<ChecklistContainerProps> = ({ checks }) => {
  const isMobile = useIsMobile();

  const buildResponse = () => {
    if (checks) {
      return [
        {
          success: checks.isFull.isOk,
          title: 'Capacity',
          description: checks.isFull.isOk ? 'Event has tickets left' : 'Event reached its booking limit',
        },
        {
          success: checks.isOverlapping.isOk,
          title: 'Overlap',
          description: checks.isOverlapping.isOk
            ? 'Event does not overlap with other booked events'
            : `Event overlaps with booked ${checks.isOverlapping.details[0]} by the user`,
        },
        {
          success: checks.isFiltered.isOk,
          title: 'Event Filter',
          description: checks.isFiltered.isOk
            ? 'Event visible on booking page'
            : `${checks.isFiltered.details[0]} prevents visibility for user`,
        },
        {
          success: checks.isMaxGroups.isOk,
          title: 'Event Groups',
          description: checks.isMaxGroups.isOk
            ? 'No event group ticket limit for this event reached'
            : `User has reached ticket limit for ${checks.isMaxGroups.details[0]}`,
        },
      ];
    }

    return [];
  };

  return <CheckList isMobile={isMobile} items={buildResponse()} />;
};

export default ChecklistContainer;
