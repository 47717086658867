import { EventContentData, EventContentPropsData } from 'components/eventCard/EventAdminContent';

type buildEventsContentPropsParameters = {
  currentEventBooked: number;
  maxEventAttended: number;
  currentEventAttended: number;
  paidPeople: number;
  price: number;
  isMobile?: boolean;
};

const displaySuffixIfDesktop = (isMobile: boolean | undefined, suffix: string) => (isMobile ? '' : suffix);

/**
 * build events content props out of the given data
 * @param data - data to build the props from
 * @returns props to be passed to event content
 */
export const buildEventsContentProps = (data: buildEventsContentPropsParameters): EventContentPropsData => {
  const payment: EventContentData =
    data.price > 0
      ? {
          label: `${data.paidPeople}/${data.currentEventBooked} ${displaySuffixIfDesktop(data.isMobile, 'Paid')}`,
          state: data.paidPeople >= data.currentEventBooked ? 'success' : 'error',
        }
      : {
          label: 'Free',
          state: 'default',
        };

  const booked: EventContentData = {
    label:
      data.maxEventAttended > 0
        ? `${data.currentEventBooked}/${data.maxEventAttended} ${displaySuffixIfDesktop(data.isMobile, 'Booked')}`
        : `${data.currentEventBooked} ${displaySuffixIfDesktop(data.isMobile, 'Booked')}`,
    state: 'default',
  };

  const attended: EventContentData = {
    label: `${data.currentEventAttended}/${data.currentEventBooked} ${displaySuffixIfDesktop(
      data.isMobile,
      'Attended'
    )}`,
    state: data.currentEventAttended >= data.currentEventBooked ? 'success' : 'warning',
  };

  return {
    booked,
    attended,
    payment,
  };
};

export default buildEventsContentProps;
