import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FiltersOverview from './sub-pages/overview/FiltersOverview';
import FiltersEdit from './sub-pages/edit/FiltersEdit';

const Filters = () => {
  return (
    <Routes>
      <Route path="create" element={<FiltersEdit />} />
      <Route path="edit/:eventFilterId" element={<FiltersEdit />} />
      <Route path="*" element={<FiltersOverview />} />
    </Routes>
  );
};

export default Filters;
