import React from 'react';
import { Button } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';

export interface PaidButtonProps {
  isPaid: boolean;
  onClick: () => void;
  isFree?: boolean;
  style?: React.CSSProperties;
}

const renderPaidText = (isFree: boolean | undefined, isPaid: boolean) => {
  if (isFree) {
    return 'Free';
  }
  return isPaid ? 'Paid' : 'Pay';
};

const PaidButton: React.FC<PaidButtonProps> = ({ isPaid, onClick, isFree, style }) => {
  const theme = useDynamicTheme();

  return (
    <Button
      onClick={onClick}
      variant={isPaid && !isFree ? 'contained' : 'outlined'}
      color="secondary"
      style={{ width: '105px', backgroundColor: isPaid && !isFree ? theme.palette.success.main : '', ...style }}
      disabled={isFree}
    >
      {renderPaidText(isFree, isPaid)}
    </Button>
  );
};

export default PaidButton;
