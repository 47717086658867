import { styled } from '@mui/material';

export const BookingInputContainer = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  margin: '16px 0px',
}));

export default BookingInputContainer;
