import * as Backend from 'queries/BackendRequest';
import { CreateEvent, PatchEvent } from './types';
import { useNavigate } from 'react-router-dom';
import { EditEventFormValues } from 'pages/admin/events/sub-pages/edit/types/FormTypes';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import { useMutation } from '@tanstack/react-query';

const createAdminEvent = async (pid: string, patchEvent: PatchEvent) => {
  const form: CreateEvent = {
    event: patchEvent,
    eventFilters: [],
  };
  await Backend.request('POST', `/admin/${pid}/events/create`, {}, form);
};

interface MutationProps {
  event: EditEventFormValues;
}

const useCreateEvent = () => {
  const navigate = useNavigate();
  const feedback = useOpenFeedback();
  const projectId = useProjectId();
  const sendData = useMutation({
    mutationFn: async ({ event }: MutationProps) => {
      const form: PatchEvent = {
        displayTitle: event.title,
        description: event.description,
        startDatetime: event.startDatetime,
        endDatetime: event.endDatetime,
        attendanceLimit: event.isNoAttendanceLimit ? 0 : Number(event.attendanceLimit), // 0 means no limit
        basePriceSek: event.isFree ? 0 : Number(event.price), // 0 means free
        location: event.location,
        bookingStartDatetime: event.bookingStartDatetime,
        bookingEndDatetime: event.bookingEndDatetime,
        unbookingEndDatetime: event.unbookingEndDatetime,
        swapEndDatetime: event.swapEndDatetime,
      };
      await createAdminEvent(projectId, form);
    },
    onSuccess: async () => {
      navigate(-1); // Go back to previous page
      feedback.openSuccess('Event created successfully');
    },
    onError: (error: { response: { data: { error: { message: string } } } }) => {
      feedback.openError(`Failed to create event: ${error.response.data.error.message ?? 'Unknown error'}`);
    },
  });

  const createEvent = (event: EditEventFormValues) => {
    sendData.mutate({ event });
  };

  return {
    isLoading: sendData.isPending,
    createEvent,
  };
};

export default useCreateEvent;
