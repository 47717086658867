import useDeleteUserBooking from 'queries/admin-events/useDeleteUserBooking';

interface MutationProps {
  eventId: string | number;
  bookingId: number;
}

const useRemoveBooking = () => {
  const sendData = useDeleteUserBooking();

  const deleteBooking = (eventId: string, bookingId: number) => {
    sendData.mutate({ eventId, bookingId });
  };

  return {
    isLoading: sendData.isPending,
    deleteBooking,
  };
};

export default useRemoveBooking;
