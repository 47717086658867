import React from 'react';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PaymentIcon from '@mui/icons-material/Payment';
import { Typography, styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActivated',
})<{ isActivated: boolean }>(({ theme, isActivated }) => ({
  backgroundColor: isActivated ? `${theme.palette.secondary.main}${theme.palette.overlay.opacity.dragged}` : undefined,
  color: isActivated ? theme.palette.secondary.main : undefined,
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  display: 'flex',
  alignItems: 'center',
}));

const DateTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActivated',
})<{ isActivated: boolean }>(({ theme, isActivated }) => ({
  color: isActivated ? undefined : `${theme.palette.background.text}${theme.palette.other.opacityMedium}`,
  letterSpacing: '1.5px',
  lineHeight: '16px',
  fontSize: '10px',
}));

const AttendedContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isAttended',
})<{ isAttended: boolean }>(({ theme, isAttended }) => ({
  color: isAttended ? theme.palette.success.main : theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
}));

const PaidContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPaid',
})<{ isPaid: boolean }>(({ theme, isPaid }) => ({
  color: isPaid ? theme.palette.success.main : theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
}));

interface BookingBodyMobileProps {
  isActivated: boolean;
  isPaid: boolean;
  isAttended: boolean;
  date: string;
  title: string;
  price: number;
  onClick: () => void;
}

const BookingBodyMobile: React.FC<BookingBodyMobileProps> = ({
  isActivated,
  isPaid,
  isAttended,
  date,
  title,
  price,
  onClick,
}) => {
  return (
    <Root isActivated={isActivated}>
      <Checkbox style={{ padding: 0 }} color="secondary" checked={isActivated} onChange={onClick} />
      <div
        style={{
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: '16px',
          alignItems: 'center',
          paddingTop: '12px',
          paddingBottom: '12px',
        }}
      >
        <DateTypography isActivated={isActivated} variant="body2">
          {date}
        </DateTypography>
        <Typography variant="h6">{title}</Typography>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td style={{ whiteSpace: 'nowrap' }}>
                <AttendedContainer isAttended={isAttended}>
                  <EmojiPeopleIcon style={{ height: '16px' }} />
                  <Typography variant="caption">{isAttended ? 'Attended' : 'Not attended'}</Typography>
                </AttendedContainer>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <PaidContainer isPaid={isPaid}>
                  <PaymentIcon style={{ height: '16px' }} />
                  <Typography variant="caption">{price} SEK</Typography>
                </PaidContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Root>
  );
};

export default BookingBodyMobile;
