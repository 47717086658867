import React, { useState } from 'react';

import { CircularProgress, Divider, Typography, styled } from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import useCopyClipboard from 'hooks/useCopyClipboard';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getUserPreferencesQuery } from 'queries/admin-users/admin-user-food-prefs';
import { getUserGeneralQuery } from 'queries/admin-users/admin-user-general';
import { getUserStudyQuery } from 'queries/admin-users/admin-user-study';
import InfoGeneral from '../components/InfoGeneral';
import formatGeneralInfo from '../utils/generalSettingsDisplay';
import CheckedInContainer from './CheckedInContainer';
import TabChip from '/components/tabChip/TabChip';

const ChipContainer = styled('div')(() => ({
  maxWidth: '90vw',
  display: 'inline-block',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  position: 'relative',
  overflowX: 'scroll',
}));

const TabsContainer = styled('div')(() => ({
  minWidth: '370px',
  overflow: 'hidden',
}));

const CustomDivider = styled(Divider)(() => ({
  height: '8px',
  marginTop: '16px',
  marginBottom: '16px',
}));

// list of chips
const tabs = [
  { label: 'General', disabled: false },
  { label: 'Studies', disabled: false },
  { label: 'Preferences', disabled: false },
  { label: 'Notes', disabled: true },
];

interface GeneralSettingsProps {
  userId: number;
  hideCheckedIn?: boolean;
}

/**
 * General Settings used in Users Overview
 */
const GeneralSettings: React.FC<GeneralSettingsProps> = ({ userId, hideCheckedIn }) => {
  const theme = useDynamicTheme();
  const isMobile = useIsMobile('sm');
  const copyClipboard = useCopyClipboard();
  const [category, setCategory] = useState(0);
  const projectId = useProjectId();
  const queryParam = {
    projectId,
    userId,
  };
  const { data: study } = useQuery(getUserStudyQuery(queryParam));
  const { data: generalInfo } = useQuery(getUserGeneralQuery(queryParam));
  const { data: preferences } = useQuery(getUserPreferencesQuery(queryParam));

  if (!userId) {
    return <></>;
  }

  if (generalInfo === undefined || study === undefined || preferences === undefined) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <>
      <CustomDivider />
      <Typography
        variant="h6"
        style={{
          marginBottom: '16px',
          fontWeight: 400,
        }}
      >
        {`${generalInfo.firstName} ${generalInfo.lastName}`}
      </Typography>
      <ChipContainer>
        <TabsContainer>
          {tabs.map((tab, i) => (
            <TabChip
              key={`tab-label-${tab.label}`}
              label={tab.label}
              selected={i === category}
              style={{ marginRight: '8px' }}
              onClick={() => setCategory(i)}
              disabled={tab.disabled}
            />
          ))}
        </TabsContainer>
      </ChipContainer>
      <Divider style={{ margin: isMobile ? '8px auto' : '12px auto' }} />
      <InfoGeneral
        entries={formatGeneralInfo(category, copyClipboard.copy, theme, generalInfo, study, preferences, isMobile)}
        rowSize={isMobile ? 2 : 3}
      />
      {isMobile ? <Divider style={{ marginBottom: '16px' }} /> : <div style={{ marginBottom: `11px` }} />}
      {!hideCheckedIn && <CheckedInContainer userId={userId} />}
    </>
  );
};

export default GeneralSettings;
