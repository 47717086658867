import { styled } from '@mui/material';

export const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export default InputContainer;
