import React, { useState } from 'react';
import ButtonComponent from 'components/form/Button';
import { useProjectTextContext } from 'context/ProjectTextContext';
import useSetPublicText from 'queries/texts/useSetPublicText';
import TextEditor from 'components/textEditor/TextEditor';

const PagesHome: React.FC = () => {
  const updateText = useSetPublicText('FRONTPAGE_MAIN', 'Home Page Text');
  const { FRONTPAGE_MAIN: initialText } = useProjectTextContext();
  const [text, setText] = useState<string>(initialText ?? '');
  return (
    <>
      <TextEditor initialText={text} setText={setText} />
      <ButtonComponent
        click={() => {
          updateText.mutate(text);
        }}
        label="Save"
        fixedWidth
        loading={updateText.isPending}
      />
    </>
  );
};
export default PagesHome;
