import { CircularProgress, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import ClearableTextField from 'components/clearableTextField/ClearableTextField';
import React, { useState, useMemo } from 'react';

import { UseMutationResult, useQuery } from '@tanstack/react-query';
import Dialog from 'components/dialog/Dialog';
import useDynamicTheme from 'hooks/useDynamicTheme';
import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import { getAdminUserTicketsQuery } from 'queries/admin-users/admin-user-tickets';
import { Control } from 'react-hook-form';
import BookingsOptions from '../../components/BookingsOptions';
import { PayBookingProps } from '../../hooks/useBookingActions';
import BookingsFilterContainer from './BookingsFilterContainer';
import useOpenFeedback from '../../../../../hooks/useOpenFeedback';

interface BookingsHeaderContainerProps {
  userId: number;
  searchValue: string;
  selectedEvents: number[];
  handleAllSelect: () => void;
  setSearchValue: (value: string) => void;
  deleteBookings: UseMutationResult<void, unknown, number[], unknown>;
  payBookings: UseMutationResult<void, unknown, PayBookingProps, unknown>;
  control: Control<any, any>;
  handleReset: () => void;
  openTransfer: () => void;
}

const BookingsHeaderContainer: React.FC<BookingsHeaderContainerProps> = ({
  userId,
  searchValue,
  selectedEvents,
  handleAllSelect,
  setSearchValue,
  deleteBookings,
  payBookings,
  control,
  handleReset,
  openTransfer,
}) => {
  const theme = useDynamicTheme();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const isDesktop = !useIsMobile();
  const projectId = useProjectId();
  const feedback = useOpenFeedback();
  const { data: events } = useQuery(getAdminUserTicketsQuery({ projectId, userId }));

  const handleDeleteBooking = () => {
    if (selectedEvents.length > 0) {
      deleteBookings.mutate(selectedEvents);
      setDeleteOpen(false);
    }
  };

  const openDeleteModal = () => {
    setDeleteOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
  };

  const handlePayBooking = () => {
    if (checkIfMismatchedPaidUnpaid) {
      feedback.openError("You can not select both 'paid' and 'unpaid' events at once");
      return;
    }

    if (selectedEvents.length > 0 && events) {
      const filteredEvents = events.filter((event) => event.basePriceSek !== 0);

      if (filteredEvents.length > 0) {
        payBookings.mutate({ bookingIds: selectedEvents, events: filteredEvents });
      } else {
        feedback.openError('No valid events to process');
      }
    }
  };

  const checkIfAllSelectedPaid = useMemo(() => {
    return (
      selectedEvents.length > 0 &&
      selectedEvents.every((eventId) => {
        const event = events?.find((event) => event.bookingId === eventId);
        return event && (event.basePriceSek === 0 || event.isPaid);
      })
    );
  }, [selectedEvents, events]);

  const checkIfMismatchedPaidUnpaid = useMemo(() => {
    const selectedStatuses = events
      ?.filter((event) => selectedEvents.includes(event.bookingId) && event.basePriceSek !== 0)
      .map((event) => event.isPaid);

    // A Set only contains unique elements. If the size of the Set is greater than 1,
    // it means the selected events include both 'paid' and 'unpaid' statuses.
    return new Set(selectedStatuses).size > 1;
  }, [selectedEvents, events]);

  if (!userId) {
    return <></>;
  }

  if (events === undefined) return <CircularProgress color="secondary" />;

  // TODO: Remove hard coding from icon button, -16px on margin
  return (
    <>
      <Dialog
        title=""
        question="Are you sure you want to delete the user from the event(s)?"
        buttonLabel="DELETE"
        isOpen={deleteOpen}
        confirmOnClick={handleDeleteBooking}
        cancelOnClick={closeDeleteModal}
        buttonDisable={deleteBookings.isPending}
      />
      <div
        style={
          !isDesktop ? { position: 'sticky', top: '45px', backgroundColor: theme.palette.surface.main, zIndex: 10 } : {}
        }
      >
        <Typography variant="h6" style={{ paddingTop: '16px', marginBottom: '4px' }}>
          Bookings
        </Typography>
        {isDesktop ? (
          <Typography variant="body1" style={{ marginBottom: '16px' }}>
            Select events to change their status.
          </Typography>
        ) : (
          <Typography variant="body2" style={{ marginBottom: '8px' }}>
            Tap events to change their status.{' '}
          </Typography>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
          <ClearableTextField
            label="Search Events"
            value={searchValue}
            setValue={setSearchValue}
            style={{ marginRight: isDesktop ? '16px' : undefined }}
          />
          {isDesktop ? (
            <BookingsOptions
              onClickPay={handlePayBooking}
              isSelectedActive={selectedEvents.length > 0}
              onClickDelete={openDeleteModal}
              onClickTransfer={openTransfer}
              clickTransferEnabled={selectedEvents.length === 1}
              areAllSelectedPaid={checkIfAllSelectedPaid}
            />
          ) : (
            <BookingsFilterContainer control={control} handleReset={handleReset} />
          )}
        </div>
        {!isDesktop && (
          <>
            <BookingsOptions
              isMobile
              selectAll={selectedEvents.length === events.length}
              onClickSelectAll={handleAllSelect}
              onClickPay={handlePayBooking}
              isSelectedActive={selectedEvents.length > 0}
              onClickDelete={openDeleteModal}
              onClickTransfer={openTransfer}
              clickTransferEnabled={selectedEvents.length === 1}
              areAllSelectedPaid={checkIfAllSelectedPaid}
            />
            {events.length > 0 && (
              <Divider
                style={{
                  margin: '0 -16px',
                  boxShadow: '0px 8px 5px rgba(0, 0, 0, 0.2)',
                  height: '16px',
                  backgroundColor: theme.palette.surface.main,
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BookingsHeaderContainer;
